:root{
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0e76bc;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,*::before,*::after{
  box-sizing:border-box
}

html{
  font-family:sans-serif;
  line-height:1.15;
  -webkit-text-size-adjust:100%;
  -webkit-tap-highlight-color:rgba(0,0,0,0)
}

article,aside,figcaption,figure,footer,header,hgroup,main,nav,section{
  display:block
}

body{
  margin:0;
  font-family:"proxima-nova",sans-serif;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#212529;
  text-align:left;
  background-color:#fff
}

[tabindex="-1"]:focus:not(:focus-visible){
  outline:0 !important
}

hr{
  box-sizing:content-box;
  height:0;
  overflow:visible
}

h1,h2,h3,h4,h5,h6{
  margin-top:0;
  margin-bottom:.5rem
}

p{
  margin-top:0;
  margin-bottom:1rem
}

abbr[title],abbr[data-original-title]{
  text-decoration:underline;
  -webkit-text-decoration:underline dotted;
  text-decoration:underline dotted;
  cursor:help;
  border-bottom:0;
  -webkit-text-decoration-skip-ink:none;
  text-decoration-skip-ink:none
}

address{
  margin-bottom:1rem;
  font-style:normal;
  line-height:inherit
}

ol,ul,dl{
  margin-top:0;
  margin-bottom:1rem
}

ol ol,ul ul,ol ul,ul ol{
  margin-bottom:0
}

dt{
  font-weight:700
}

dd{
  margin-bottom:.5rem;
  margin-left:0
}

blockquote{
  margin:0 0 1rem
}

b,strong{
  font-weight:bolder
}

small{
  font-size:80%
}

sub,sup{
  position:relative;
  font-size:75%;
  line-height:0;
  vertical-align:baseline
}

sub{
  bottom:-.25em
}

sup{
  top:-.5em
}

a{
  color:#0e76bc;
  text-decoration:none;
  background-color:transparent
}

a:hover{
  color:#8dc63f;
  text-decoration:underline
}

a:not([href]):not([class]){
  color:inherit;
  text-decoration:none
}

a:not([href]):not([class]):hover{
  color:inherit;
  text-decoration:none
}

pre,code,kbd,samp{
  font-family:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size:1em
}

pre{
  margin-top:0;
  margin-bottom:1rem;
  overflow:auto;
  -ms-overflow-style:scrollbar
}

figure{
  margin:0 0 1rem
}

img{
  vertical-align:middle;
  border-style:none
}

svg{
  overflow:hidden;
  vertical-align:middle
}

table{
  border-collapse:collapse
}

caption{
  padding-top:.75rem;
  padding-bottom:.75rem;
  color:#6c757d;
  text-align:left;
  caption-side:bottom
}

th{
  text-align:inherit;
  text-align:-webkit-match-parent
}

label{
  display:inline-block;
  margin-bottom:.5rem
}

button{
  border-radius:0
}

button:focus:not(:focus-visible){
  outline:0
}

input,button,select,optgroup,textarea{
  margin:0;
  font-family:inherit;
  font-size:inherit;
  line-height:inherit
}

button,input{
  overflow:visible
}

button,select{
  text-transform:none
}

[role="button"]{
  cursor:pointer
}

select{
  word-wrap:normal
}

button,[type="button"],[type="reset"],[type="submit"]{
  -webkit-appearance:button
}

button:not(:disabled),[type="button"]:not(:disabled),[type="reset"]:not(:disabled),[type="submit"]:not(:disabled){
  cursor:pointer
}

button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner{
  padding:0;
  border-style:none
}

input[type="radio"],input[type="checkbox"]{
  box-sizing:border-box;
  padding:0
}

textarea{
  overflow:auto;
  resize:vertical
}

fieldset{
  min-width:0;
  padding:0;
  margin:0;
  border:0
}

legend{
  display:block;
  width:100%;
  max-width:100%;
  padding:0;
  margin-bottom:.5rem;
  font-size:1.5rem;
  line-height:inherit;
  color:inherit;
  white-space:normal
}

progress{
  vertical-align:baseline
}

[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button{
  height:auto
}

[type="search"]{
  outline-offset:-2px;
  -webkit-appearance:none
}

[type="search"]::-webkit-search-decoration{
  -webkit-appearance:none
}

::-webkit-file-upload-button{
  font:inherit;
  -webkit-appearance:button
}

output{
  display:inline-block
}

summary{
  display:list-item;
  cursor:pointer
}

template{
  display:none
}

[hidden]{
  display:none !important
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
  margin-bottom:.5rem;
  font-weight:500;
  line-height:1.2
}

h1,.h1{
  font-size:2.5rem
}

h2,.h2{
  font-size:2rem
}

h3,.h3{
  font-size:1.75rem
}

h4,.h4{
  font-size:1.5rem
}

h5,.h5{
  font-size:1.25rem
}

h6,.h6{
  font-size:1rem
}

.lead{
  font-size:1.25rem;
  font-weight:300
}

.display-1{
  font-size:6rem;
  font-weight:300;
  line-height:1.2
}

.display-2{
  font-size:5.5rem;
  font-weight:300;
  line-height:1.2
}

.display-3{
  font-size:4.5rem;
  font-weight:300;
  line-height:1.2
}

.display-4{
  font-size:3.5rem;
  font-weight:300;
  line-height:1.2
}

hr{
  margin-top:1rem;
  margin-bottom:1rem;
  border:0;
  border-top:1px solid rgba(0,0,0,0.1)
}

small,.small{
  font-size:80%;
  font-weight:400
}

mark,.mark{
  padding:.2em;
  background-color:#fcf8e3
}

.list-unstyled{
  padding-left:0;
  list-style:none
}

.list-inline{
  padding-left:0;
  list-style:none
}

.list-inline-item{
  display:inline-block
}

.list-inline-item:not(:last-child){
  margin-right:.5rem
}

.initialism{
  font-size:90%;
  text-transform:uppercase
}

.blockquote{
  margin-bottom:1rem;
  font-size:1.25rem
}

.blockquote-footer{
  display:block;
  font-size:80%;
  color:#6c757d
}

.blockquote-footer::before{
  content:"\2014\00A0"
}

.container,.container-fluid,.container-sm,.container-md,.container-lg,.container-xl,.container-xxl{
  width:100%;
  padding-right:15px;
  padding-left:15px;
  margin-right:auto;
  margin-left:auto
}

@media (min-width: 576px){
  .container,.container-sm{
    max-width:540px
  }
}

@media (min-width: 768px){
  .container,.container-sm,.container-md{
    max-width:720px
  }
}

@media (min-width: 992px){
  .container,.container-sm,.container-md,.container-lg{
    max-width:960px
  }
}

@media (min-width: 1200px){
  .container,.container-sm,.container-md,.container-lg,.container-xl{
    max-width:1140px
  }
}

@media (min-width: 1600px){
  .container,.container-sm,.container-md,.container-lg,.container-xl,.container-xxl{
    max-width:1540px
  }
}

.row{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-right:-15px;
  margin-left:-15px
}

.no-gutters{
  margin-right:0;
  margin-left:0
}

.no-gutters>.col,.no-gutters>[class*="col-"]{
  padding-right:0;
  padding-left:0
}

.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,.col,.col-auto,.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm,.col-sm-auto,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,.col-md,.col-md-auto,.col-lg-1,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg,.col-lg-auto,.col-xl-1,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl,.col-xl-auto,.col-xxl-1,.col-xxl-2,.col-xxl-3,.col-xxl-4,.col-xxl-5,.col-xxl-6,.col-xxl-7,.col-xxl-8,.col-xxl-9,.col-xxl-10,.col-xxl-11,.col-xxl-12,.col-xxl,.col-xxl-auto{
  position:relative;
  width:100%;
  padding-right:15px;
  padding-left:15px
}

.col{
  -webkit-flex-basis:0;
          flex-basis:0;
  -webkit-flex-grow:1;
  flex-grow:1;
  max-width:100%
}

.row-cols-1>*{
  -webkit-flex:0 0 100%;
  flex:0 0 100%;
  max-width:100%
}

.row-cols-2>*{
  -webkit-flex:0 0 50%;
  flex:0 0 50%;
  max-width:50%
}

.row-cols-3>*{
  -webkit-flex:0 0 33.33333%;
  flex:0 0 33.33333%;
  max-width:33.33333%
}

.row-cols-4>*{
  -webkit-flex:0 0 25%;
  flex:0 0 25%;
  max-width:25%
}

.row-cols-5>*{
  -webkit-flex:0 0 20%;
  flex:0 0 20%;
  max-width:20%
}

.row-cols-6>*{
  -webkit-flex:0 0 16.66667%;
  flex:0 0 16.66667%;
  max-width:16.66667%
}

.col-auto{
  -webkit-flex:0 0 auto;
  flex:0 0 auto;
  width:auto;
  max-width:100%
}

.col-1{
  -webkit-flex:0 0 8.33333%;
  flex:0 0 8.33333%;
  max-width:8.33333%
}

.col-2{
  -webkit-flex:0 0 16.66667%;
  flex:0 0 16.66667%;
  max-width:16.66667%
}

.col-3{
  -webkit-flex:0 0 25%;
  flex:0 0 25%;
  max-width:25%
}

.col-4{
  -webkit-flex:0 0 33.33333%;
  flex:0 0 33.33333%;
  max-width:33.33333%
}

.col-5{
  -webkit-flex:0 0 41.66667%;
  flex:0 0 41.66667%;
  max-width:41.66667%
}

.col-6{
  -webkit-flex:0 0 50%;
  flex:0 0 50%;
  max-width:50%
}

.col-7{
  -webkit-flex:0 0 58.33333%;
  flex:0 0 58.33333%;
  max-width:58.33333%
}

.col-8{
  -webkit-flex:0 0 66.66667%;
  flex:0 0 66.66667%;
  max-width:66.66667%
}

.col-9{
  -webkit-flex:0 0 75%;
  flex:0 0 75%;
  max-width:75%
}

.col-10{
  -webkit-flex:0 0 83.33333%;
  flex:0 0 83.33333%;
  max-width:83.33333%
}

.col-11{
  -webkit-flex:0 0 91.66667%;
  flex:0 0 91.66667%;
  max-width:91.66667%
}

.col-12{
  -webkit-flex:0 0 100%;
  flex:0 0 100%;
  max-width:100%
}

.order-first{
  -webkit-order:-1;
  order:-1
}

.order-last{
  -webkit-order:13;
  order:13
}

.order-0{
  -webkit-order:0;
  order:0
}

.order-1{
  -webkit-order:1;
  order:1
}

.order-2{
  -webkit-order:2;
  order:2
}

.order-3{
  -webkit-order:3;
  order:3
}

.order-4{
  -webkit-order:4;
  order:4
}

.order-5{
  -webkit-order:5;
  order:5
}

.order-6{
  -webkit-order:6;
  order:6
}

.order-7{
  -webkit-order:7;
  order:7
}

.order-8{
  -webkit-order:8;
  order:8
}

.order-9{
  -webkit-order:9;
  order:9
}

.order-10{
  -webkit-order:10;
  order:10
}

.order-11{
  -webkit-order:11;
  order:11
}

.order-12{
  -webkit-order:12;
  order:12
}

.offset-1{
  margin-left:8.33333%
}

.offset-2{
  margin-left:16.66667%
}

.offset-3{
  margin-left:25%
}

.offset-4{
  margin-left:33.33333%
}

.offset-5{
  margin-left:41.66667%
}

.offset-6{
  margin-left:50%
}

.offset-7{
  margin-left:58.33333%
}

.offset-8{
  margin-left:66.66667%
}

.offset-9{
  margin-left:75%
}

.offset-10{
  margin-left:83.33333%
}

.offset-11{
  margin-left:91.66667%
}

@media (min-width: 576px){
  .col-sm{
    -webkit-flex-basis:0;
            flex-basis:0;
    -webkit-flex-grow:1;
    flex-grow:1;
    max-width:100%
  }

  .row-cols-sm-1>*{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .row-cols-sm-2>*{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .row-cols-sm-3>*{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .row-cols-sm-4>*{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .row-cols-sm-5>*{
    -webkit-flex:0 0 20%;
    flex:0 0 20%;
    max-width:20%
  }

  .row-cols-sm-6>*{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-sm-auto{
    -webkit-flex:0 0 auto;
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-sm-1{
    -webkit-flex:0 0 8.33333%;
    flex:0 0 8.33333%;
    max-width:8.33333%
  }

  .col-sm-2{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-sm-3{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .col-sm-4{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .col-sm-5{
    -webkit-flex:0 0 41.66667%;
    flex:0 0 41.66667%;
    max-width:41.66667%
  }

  .col-sm-6{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .col-sm-7{
    -webkit-flex:0 0 58.33333%;
    flex:0 0 58.33333%;
    max-width:58.33333%
  }

  .col-sm-8{
    -webkit-flex:0 0 66.66667%;
    flex:0 0 66.66667%;
    max-width:66.66667%
  }

  .col-sm-9{
    -webkit-flex:0 0 75%;
    flex:0 0 75%;
    max-width:75%
  }

  .col-sm-10{
    -webkit-flex:0 0 83.33333%;
    flex:0 0 83.33333%;
    max-width:83.33333%
  }

  .col-sm-11{
    -webkit-flex:0 0 91.66667%;
    flex:0 0 91.66667%;
    max-width:91.66667%
  }

  .col-sm-12{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .order-sm-first{
    -webkit-order:-1;
    order:-1
  }

  .order-sm-last{
    -webkit-order:13;
    order:13
  }

  .order-sm-0{
    -webkit-order:0;
    order:0
  }

  .order-sm-1{
    -webkit-order:1;
    order:1
  }

  .order-sm-2{
    -webkit-order:2;
    order:2
  }

  .order-sm-3{
    -webkit-order:3;
    order:3
  }

  .order-sm-4{
    -webkit-order:4;
    order:4
  }

  .order-sm-5{
    -webkit-order:5;
    order:5
  }

  .order-sm-6{
    -webkit-order:6;
    order:6
  }

  .order-sm-7{
    -webkit-order:7;
    order:7
  }

  .order-sm-8{
    -webkit-order:8;
    order:8
  }

  .order-sm-9{
    -webkit-order:9;
    order:9
  }

  .order-sm-10{
    -webkit-order:10;
    order:10
  }

  .order-sm-11{
    -webkit-order:11;
    order:11
  }

  .order-sm-12{
    -webkit-order:12;
    order:12
  }

  .offset-sm-0{
    margin-left:0
  }

  .offset-sm-1{
    margin-left:8.33333%
  }

  .offset-sm-2{
    margin-left:16.66667%
  }

  .offset-sm-3{
    margin-left:25%
  }

  .offset-sm-4{
    margin-left:33.33333%
  }

  .offset-sm-5{
    margin-left:41.66667%
  }

  .offset-sm-6{
    margin-left:50%
  }

  .offset-sm-7{
    margin-left:58.33333%
  }

  .offset-sm-8{
    margin-left:66.66667%
  }

  .offset-sm-9{
    margin-left:75%
  }

  .offset-sm-10{
    margin-left:83.33333%
  }

  .offset-sm-11{
    margin-left:91.66667%
  }
}

@media (min-width: 768px){
  .col-md{
    -webkit-flex-basis:0;
            flex-basis:0;
    -webkit-flex-grow:1;
    flex-grow:1;
    max-width:100%
  }

  .row-cols-md-1>*{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .row-cols-md-2>*{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .row-cols-md-3>*{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .row-cols-md-4>*{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .row-cols-md-5>*{
    -webkit-flex:0 0 20%;
    flex:0 0 20%;
    max-width:20%
  }

  .row-cols-md-6>*{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-md-auto{
    -webkit-flex:0 0 auto;
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-md-1{
    -webkit-flex:0 0 8.33333%;
    flex:0 0 8.33333%;
    max-width:8.33333%
  }

  .col-md-2{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-md-3{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .col-md-4{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .col-md-5{
    -webkit-flex:0 0 41.66667%;
    flex:0 0 41.66667%;
    max-width:41.66667%
  }

  .col-md-6{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .col-md-7{
    -webkit-flex:0 0 58.33333%;
    flex:0 0 58.33333%;
    max-width:58.33333%
  }

  .col-md-8{
    -webkit-flex:0 0 66.66667%;
    flex:0 0 66.66667%;
    max-width:66.66667%
  }

  .col-md-9{
    -webkit-flex:0 0 75%;
    flex:0 0 75%;
    max-width:75%
  }

  .col-md-10{
    -webkit-flex:0 0 83.33333%;
    flex:0 0 83.33333%;
    max-width:83.33333%
  }

  .col-md-11{
    -webkit-flex:0 0 91.66667%;
    flex:0 0 91.66667%;
    max-width:91.66667%
  }

  .col-md-12{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .order-md-first{
    -webkit-order:-1;
    order:-1
  }

  .order-md-last{
    -webkit-order:13;
    order:13
  }

  .order-md-0{
    -webkit-order:0;
    order:0
  }

  .order-md-1{
    -webkit-order:1;
    order:1
  }

  .order-md-2{
    -webkit-order:2;
    order:2
  }

  .order-md-3{
    -webkit-order:3;
    order:3
  }

  .order-md-4{
    -webkit-order:4;
    order:4
  }

  .order-md-5{
    -webkit-order:5;
    order:5
  }

  .order-md-6{
    -webkit-order:6;
    order:6
  }

  .order-md-7{
    -webkit-order:7;
    order:7
  }

  .order-md-8{
    -webkit-order:8;
    order:8
  }

  .order-md-9{
    -webkit-order:9;
    order:9
  }

  .order-md-10{
    -webkit-order:10;
    order:10
  }

  .order-md-11{
    -webkit-order:11;
    order:11
  }

  .order-md-12{
    -webkit-order:12;
    order:12
  }

  .offset-md-0{
    margin-left:0
  }

  .offset-md-1{
    margin-left:8.33333%
  }

  .offset-md-2{
    margin-left:16.66667%
  }

  .offset-md-3{
    margin-left:25%
  }

  .offset-md-4{
    margin-left:33.33333%
  }

  .offset-md-5{
    margin-left:41.66667%
  }

  .offset-md-6{
    margin-left:50%
  }

  .offset-md-7{
    margin-left:58.33333%
  }

  .offset-md-8{
    margin-left:66.66667%
  }

  .offset-md-9{
    margin-left:75%
  }

  .offset-md-10{
    margin-left:83.33333%
  }

  .offset-md-11{
    margin-left:91.66667%
  }
}

@media (min-width: 992px){
  .col-lg{
    -webkit-flex-basis:0;
            flex-basis:0;
    -webkit-flex-grow:1;
    flex-grow:1;
    max-width:100%
  }

  .row-cols-lg-1>*{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .row-cols-lg-2>*{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .row-cols-lg-3>*{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .row-cols-lg-4>*{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .row-cols-lg-5>*{
    -webkit-flex:0 0 20%;
    flex:0 0 20%;
    max-width:20%
  }

  .row-cols-lg-6>*{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-lg-auto{
    -webkit-flex:0 0 auto;
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-lg-1{
    -webkit-flex:0 0 8.33333%;
    flex:0 0 8.33333%;
    max-width:8.33333%
  }

  .col-lg-2{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-lg-3{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .col-lg-4{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .col-lg-5{
    -webkit-flex:0 0 41.66667%;
    flex:0 0 41.66667%;
    max-width:41.66667%
  }

  .col-lg-6{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .col-lg-7{
    -webkit-flex:0 0 58.33333%;
    flex:0 0 58.33333%;
    max-width:58.33333%
  }

  .col-lg-8{
    -webkit-flex:0 0 66.66667%;
    flex:0 0 66.66667%;
    max-width:66.66667%
  }

  .col-lg-9{
    -webkit-flex:0 0 75%;
    flex:0 0 75%;
    max-width:75%
  }

  .col-lg-10{
    -webkit-flex:0 0 83.33333%;
    flex:0 0 83.33333%;
    max-width:83.33333%
  }

  .col-lg-11{
    -webkit-flex:0 0 91.66667%;
    flex:0 0 91.66667%;
    max-width:91.66667%
  }

  .col-lg-12{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .order-lg-first{
    -webkit-order:-1;
    order:-1
  }

  .order-lg-last{
    -webkit-order:13;
    order:13
  }

  .order-lg-0{
    -webkit-order:0;
    order:0
  }

  .order-lg-1{
    -webkit-order:1;
    order:1
  }

  .order-lg-2{
    -webkit-order:2;
    order:2
  }

  .order-lg-3{
    -webkit-order:3;
    order:3
  }

  .order-lg-4{
    -webkit-order:4;
    order:4
  }

  .order-lg-5{
    -webkit-order:5;
    order:5
  }

  .order-lg-6{
    -webkit-order:6;
    order:6
  }

  .order-lg-7{
    -webkit-order:7;
    order:7
  }

  .order-lg-8{
    -webkit-order:8;
    order:8
  }

  .order-lg-9{
    -webkit-order:9;
    order:9
  }

  .order-lg-10{
    -webkit-order:10;
    order:10
  }

  .order-lg-11{
    -webkit-order:11;
    order:11
  }

  .order-lg-12{
    -webkit-order:12;
    order:12
  }

  .offset-lg-0{
    margin-left:0
  }

  .offset-lg-1{
    margin-left:8.33333%
  }

  .offset-lg-2{
    margin-left:16.66667%
  }

  .offset-lg-3{
    margin-left:25%
  }

  .offset-lg-4{
    margin-left:33.33333%
  }

  .offset-lg-5{
    margin-left:41.66667%
  }

  .offset-lg-6{
    margin-left:50%
  }

  .offset-lg-7{
    margin-left:58.33333%
  }

  .offset-lg-8{
    margin-left:66.66667%
  }

  .offset-lg-9{
    margin-left:75%
  }

  .offset-lg-10{
    margin-left:83.33333%
  }

  .offset-lg-11{
    margin-left:91.66667%
  }
}

@media (min-width: 1200px){
  .col-xl{
    -webkit-flex-basis:0;
            flex-basis:0;
    -webkit-flex-grow:1;
    flex-grow:1;
    max-width:100%
  }

  .row-cols-xl-1>*{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .row-cols-xl-2>*{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .row-cols-xl-3>*{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .row-cols-xl-4>*{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .row-cols-xl-5>*{
    -webkit-flex:0 0 20%;
    flex:0 0 20%;
    max-width:20%
  }

  .row-cols-xl-6>*{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-xl-auto{
    -webkit-flex:0 0 auto;
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-xl-1{
    -webkit-flex:0 0 8.33333%;
    flex:0 0 8.33333%;
    max-width:8.33333%
  }

  .col-xl-2{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-xl-3{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .col-xl-4{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .col-xl-5{
    -webkit-flex:0 0 41.66667%;
    flex:0 0 41.66667%;
    max-width:41.66667%
  }

  .col-xl-6{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .col-xl-7{
    -webkit-flex:0 0 58.33333%;
    flex:0 0 58.33333%;
    max-width:58.33333%
  }

  .col-xl-8{
    -webkit-flex:0 0 66.66667%;
    flex:0 0 66.66667%;
    max-width:66.66667%
  }

  .col-xl-9{
    -webkit-flex:0 0 75%;
    flex:0 0 75%;
    max-width:75%
  }

  .col-xl-10{
    -webkit-flex:0 0 83.33333%;
    flex:0 0 83.33333%;
    max-width:83.33333%
  }

  .col-xl-11{
    -webkit-flex:0 0 91.66667%;
    flex:0 0 91.66667%;
    max-width:91.66667%
  }

  .col-xl-12{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .order-xl-first{
    -webkit-order:-1;
    order:-1
  }

  .order-xl-last{
    -webkit-order:13;
    order:13
  }

  .order-xl-0{
    -webkit-order:0;
    order:0
  }

  .order-xl-1{
    -webkit-order:1;
    order:1
  }

  .order-xl-2{
    -webkit-order:2;
    order:2
  }

  .order-xl-3{
    -webkit-order:3;
    order:3
  }

  .order-xl-4{
    -webkit-order:4;
    order:4
  }

  .order-xl-5{
    -webkit-order:5;
    order:5
  }

  .order-xl-6{
    -webkit-order:6;
    order:6
  }

  .order-xl-7{
    -webkit-order:7;
    order:7
  }

  .order-xl-8{
    -webkit-order:8;
    order:8
  }

  .order-xl-9{
    -webkit-order:9;
    order:9
  }

  .order-xl-10{
    -webkit-order:10;
    order:10
  }

  .order-xl-11{
    -webkit-order:11;
    order:11
  }

  .order-xl-12{
    -webkit-order:12;
    order:12
  }

  .offset-xl-0{
    margin-left:0
  }

  .offset-xl-1{
    margin-left:8.33333%
  }

  .offset-xl-2{
    margin-left:16.66667%
  }

  .offset-xl-3{
    margin-left:25%
  }

  .offset-xl-4{
    margin-left:33.33333%
  }

  .offset-xl-5{
    margin-left:41.66667%
  }

  .offset-xl-6{
    margin-left:50%
  }

  .offset-xl-7{
    margin-left:58.33333%
  }

  .offset-xl-8{
    margin-left:66.66667%
  }

  .offset-xl-9{
    margin-left:75%
  }

  .offset-xl-10{
    margin-left:83.33333%
  }

  .offset-xl-11{
    margin-left:91.66667%
  }
}

@media (min-width: 1600px){
  .col-xxl{
    -webkit-flex-basis:0;
            flex-basis:0;
    -webkit-flex-grow:1;
    flex-grow:1;
    max-width:100%
  }

  .row-cols-xxl-1>*{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .row-cols-xxl-2>*{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .row-cols-xxl-3>*{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .row-cols-xxl-4>*{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .row-cols-xxl-5>*{
    -webkit-flex:0 0 20%;
    flex:0 0 20%;
    max-width:20%
  }

  .row-cols-xxl-6>*{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-xxl-auto{
    -webkit-flex:0 0 auto;
    flex:0 0 auto;
    width:auto;
    max-width:100%
  }

  .col-xxl-1{
    -webkit-flex:0 0 8.33333%;
    flex:0 0 8.33333%;
    max-width:8.33333%
  }

  .col-xxl-2{
    -webkit-flex:0 0 16.66667%;
    flex:0 0 16.66667%;
    max-width:16.66667%
  }

  .col-xxl-3{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }

  .col-xxl-4{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }

  .col-xxl-5{
    -webkit-flex:0 0 41.66667%;
    flex:0 0 41.66667%;
    max-width:41.66667%
  }

  .col-xxl-6{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }

  .col-xxl-7{
    -webkit-flex:0 0 58.33333%;
    flex:0 0 58.33333%;
    max-width:58.33333%
  }

  .col-xxl-8{
    -webkit-flex:0 0 66.66667%;
    flex:0 0 66.66667%;
    max-width:66.66667%
  }

  .col-xxl-9{
    -webkit-flex:0 0 75%;
    flex:0 0 75%;
    max-width:75%
  }

  .col-xxl-10{
    -webkit-flex:0 0 83.33333%;
    flex:0 0 83.33333%;
    max-width:83.33333%
  }

  .col-xxl-11{
    -webkit-flex:0 0 91.66667%;
    flex:0 0 91.66667%;
    max-width:91.66667%
  }

  .col-xxl-12{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }

  .order-xxl-first{
    -webkit-order:-1;
    order:-1
  }

  .order-xxl-last{
    -webkit-order:13;
    order:13
  }

  .order-xxl-0{
    -webkit-order:0;
    order:0
  }

  .order-xxl-1{
    -webkit-order:1;
    order:1
  }

  .order-xxl-2{
    -webkit-order:2;
    order:2
  }

  .order-xxl-3{
    -webkit-order:3;
    order:3
  }

  .order-xxl-4{
    -webkit-order:4;
    order:4
  }

  .order-xxl-5{
    -webkit-order:5;
    order:5
  }

  .order-xxl-6{
    -webkit-order:6;
    order:6
  }

  .order-xxl-7{
    -webkit-order:7;
    order:7
  }

  .order-xxl-8{
    -webkit-order:8;
    order:8
  }

  .order-xxl-9{
    -webkit-order:9;
    order:9
  }

  .order-xxl-10{
    -webkit-order:10;
    order:10
  }

  .order-xxl-11{
    -webkit-order:11;
    order:11
  }

  .order-xxl-12{
    -webkit-order:12;
    order:12
  }

  .offset-xxl-0{
    margin-left:0
  }

  .offset-xxl-1{
    margin-left:8.33333%
  }

  .offset-xxl-2{
    margin-left:16.66667%
  }

  .offset-xxl-3{
    margin-left:25%
  }

  .offset-xxl-4{
    margin-left:33.33333%
  }

  .offset-xxl-5{
    margin-left:41.66667%
  }

  .offset-xxl-6{
    margin-left:50%
  }

  .offset-xxl-7{
    margin-left:58.33333%
  }

  .offset-xxl-8{
    margin-left:66.66667%
  }

  .offset-xxl-9{
    margin-left:75%
  }

  .offset-xxl-10{
    margin-left:83.33333%
  }

  .offset-xxl-11{
    margin-left:91.66667%
  }
}

.form-control{
  display:block;
  width:100%;
  height:calc(1.5em + .75rem + 2px);
  padding:.375rem .75rem;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid #ced4da;
  border-radius:.25rem;
  transition:border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce){
  .form-control{
    transition:none
  }
}

.form-control::-ms-expand{
  background-color:transparent;
  border:0
}

.form-control:-moz-focusring{
  color:transparent;
  text-shadow:0 0 0 #495057
}

.form-control:focus{
  color:#495057;
  background-color:#fff;
  border-color:#57b4f2;
  outline:0;
  box-shadow:0 0 0 .2rem rgba(14,118,188,0.25)
}

.form-control::-webkit-input-placeholder{
  color:#6c757d;
  opacity:1
}

.form-control:-ms-input-placeholder{
  color:#6c757d;
  opacity:1
}

.form-control::placeholder{
  color:#6c757d;
  opacity:1
}

.form-control:disabled,.form-control[readonly]{
  background-color:#e9ecef;
  opacity:1
}

input[type="date"].form-control,input[type="time"].form-control,input[type="datetime-local"].form-control,input[type="month"].form-control{
  -webkit-appearance:none;
  appearance:none
}

select.form-control:focus::-ms-value{
  color:#495057;
  background-color:#fff
}

.form-control-file,.form-control-range{
  display:block;
  width:100%
}

.col-form-label{
  padding-top:calc(.375rem + 1px);
  padding-bottom:calc(.375rem + 1px);
  margin-bottom:0;
  font-size:inherit;
  line-height:1.5
}

.col-form-label-lg{
  padding-top:calc(.5rem + 1px);
  padding-bottom:calc(.5rem + 1px);
  font-size:1.25rem;
  line-height:1.5
}

.col-form-label-sm{
  padding-top:calc(.25rem + 1px);
  padding-bottom:calc(.25rem + 1px);
  font-size:.875rem;
  line-height:1.5
}

.form-control-plaintext{
  display:block;
  width:100%;
  padding:.375rem 0;
  margin-bottom:0;
  font-size:1rem;
  line-height:1.5;
  color:#212529;
  background-color:transparent;
  border:solid transparent;
  border-width:1px 0
}

.form-control-plaintext.form-control-sm,.form-control-plaintext.form-control-lg{
  padding-right:0;
  padding-left:0
}

.form-control-sm{
  height:calc(1.5em + .5rem + 2px);
  padding:.25rem .5rem;
  font-size:.875rem;
  line-height:1.5;
  border-radius:.2rem
}

.form-control-lg{
  height:calc(1.5em + 1rem + 2px);
  padding:.5rem 1rem;
  font-size:1.25rem;
  line-height:1.5;
  border-radius:.3rem
}

select.form-control[size],select.form-control[multiple]{
  height:auto
}

textarea.form-control{
  height:auto
}

.form-group{
  margin-bottom:1rem
}

.form-text{
  display:block;
  margin-top:.25rem
}

.form-row{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-right:-5px;
  margin-left:-5px
}

.form-row>.col,.form-row>[class*="col-"]{
  padding-right:5px;
  padding-left:5px
}

.form-check{
  position:relative;
  display:block;
  padding-left:1.25rem
}

.form-check-input{
  position:absolute;
  margin-top:.3rem;
  margin-left:-1.25rem
}

.form-check-input[disabled] ~ .form-check-label,.form-check-input:disabled ~ .form-check-label{
  color:#6c757d
}

.form-check-label{
  margin-bottom:0
}

.form-check-inline{
  display:-webkit-inline-flex;
  display:inline-flex;
  -webkit-align-items:center;
  align-items:center;
  padding-left:0;
  margin-right:.75rem
}

.form-check-inline .form-check-input{
  position:static;
  margin-top:0;
  margin-right:.3125rem;
  margin-left:0
}

.valid-feedback{
  display:none;
  width:100%;
  margin-top:.25rem;
  font-size:80%;
  color:#28a745
}

.valid-tooltip{
  position:absolute;
  top:100%;
  left:0;
  z-index:5;
  display:none;
  max-width:100%;
  padding:.25rem .5rem;
  margin-top:.1rem;
  font-size:.875rem;
  line-height:1.5;
  color:#fff;
  background-color:rgba(40,167,69,0.9);
  border-radius:.25rem
}

.form-row>.col>.valid-tooltip,.form-row>[class*="col-"]>.valid-tooltip{
  left:5px
}

.was-validated :valid ~ .valid-feedback,.was-validated :valid ~ .valid-tooltip,.is-valid ~ .valid-feedback,.is-valid ~ .valid-tooltip{
  display:block
}

.was-validated .form-control:valid,.form-control.is-valid{
  border-color:#28a745;
  padding-right:calc(1.5em + .75rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat:no-repeat;
  background-position:right calc(.375em + .1875rem) center;
  background-size:calc(.75em + .375rem) calc(.75em + .375rem)
}

.was-validated .form-control:valid:focus,.form-control.is-valid:focus{
  border-color:#28a745;
  box-shadow:0 0 0 .2rem rgba(40,167,69,0.25)
}

.was-validated textarea.form-control:valid,textarea.form-control.is-valid{
  padding-right:calc(1.5em + .75rem);
  background-position:top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.was-validated .custom-select:valid,.custom-select.is-valid{
  border-color:#28a745;
  padding-right:calc(.75em + 2.3125rem);
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat
}

.was-validated .custom-select:valid:focus,.custom-select.is-valid:focus{
  border-color:#28a745;
  box-shadow:0 0 0 .2rem rgba(40,167,69,0.25)
}

.was-validated .form-check-input:valid ~ .form-check-label,.form-check-input.is-valid ~ .form-check-label{
  color:#28a745
}

.was-validated .form-check-input:valid ~ .valid-feedback,.was-validated .form-check-input:valid ~ .valid-tooltip,.form-check-input.is-valid ~ .valid-feedback,.form-check-input.is-valid ~ .valid-tooltip{
  display:block
}

.was-validated .custom-control-input:valid ~ .custom-control-label,.custom-control-input.is-valid ~ .custom-control-label{
  color:#28a745
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,.custom-control-input.is-valid ~ .custom-control-label::before{
  border-color:#28a745
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,.custom-control-input.is-valid:checked ~ .custom-control-label::before{
  border-color:#34ce57;
  background-color:#34ce57
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,.custom-control-input.is-valid:focus ~ .custom-control-label::before{
  box-shadow:0 0 0 .2rem rgba(40,167,69,0.25)
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before{
  border-color:#28a745
}

.was-validated .custom-file-input:valid ~ .custom-file-label,.custom-file-input.is-valid ~ .custom-file-label{
  border-color:#28a745
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,.custom-file-input.is-valid:focus ~ .custom-file-label{
  border-color:#28a745;
  box-shadow:0 0 0 .2rem rgba(40,167,69,0.25)
}

.invalid-feedback{
  display:none;
  width:100%;
  margin-top:.25rem;
  font-size:80%;
  color:#dc3545
}

.invalid-tooltip{
  position:absolute;
  top:100%;
  left:0;
  z-index:5;
  display:none;
  max-width:100%;
  padding:.25rem .5rem;
  margin-top:.1rem;
  font-size:.875rem;
  line-height:1.5;
  color:#fff;
  background-color:rgba(220,53,69,0.9);
  border-radius:.25rem
}

.form-row>.col>.invalid-tooltip,.form-row>[class*="col-"]>.invalid-tooltip{
  left:5px
}

.was-validated :invalid ~ .invalid-feedback,.was-validated :invalid ~ .invalid-tooltip,.is-invalid ~ .invalid-feedback,.is-invalid ~ .invalid-tooltip{
  display:block
}

.was-validated .form-control:invalid,.form-control.is-invalid{
  border-color:#dc3545;
  padding-right:calc(1.5em + .75rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat:no-repeat;
  background-position:right calc(.375em + .1875rem) center;
  background-size:calc(.75em + .375rem) calc(.75em + .375rem)
}

.was-validated .form-control:invalid:focus,.form-control.is-invalid:focus{
  border-color:#dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,0.25)
}

.was-validated textarea.form-control:invalid,textarea.form-control.is-invalid{
  padding-right:calc(1.5em + .75rem);
  background-position:top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.was-validated .custom-select:invalid,.custom-select.is-invalid{
  border-color:#dc3545;
  padding-right:calc(.75em + 2.3125rem);
  background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat
}

.was-validated .custom-select:invalid:focus,.custom-select.is-invalid:focus{
  border-color:#dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,0.25)
}

.was-validated .form-check-input:invalid ~ .form-check-label,.form-check-input.is-invalid ~ .form-check-label{
  color:#dc3545
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,.was-validated .form-check-input:invalid ~ .invalid-tooltip,.form-check-input.is-invalid ~ .invalid-feedback,.form-check-input.is-invalid ~ .invalid-tooltip{
  display:block
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,.custom-control-input.is-invalid ~ .custom-control-label{
  color:#dc3545
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,.custom-control-input.is-invalid ~ .custom-control-label::before{
  border-color:#dc3545
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,.custom-control-input.is-invalid:checked ~ .custom-control-label::before{
  border-color:#e4606d;
  background-color:#e4606d
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,.custom-control-input.is-invalid:focus ~ .custom-control-label::before{
  box-shadow:0 0 0 .2rem rgba(220,53,69,0.25)
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before{
  border-color:#dc3545
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,.custom-file-input.is-invalid ~ .custom-file-label{
  border-color:#dc3545
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,.custom-file-input.is-invalid:focus ~ .custom-file-label{
  border-color:#dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,0.25)
}

.form-inline{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-flow:row wrap;
  flex-flow:row wrap;
  -webkit-align-items:center;
  align-items:center
}

.form-inline .form-check{
  width:100%
}

@media (min-width: 576px){
  .form-inline label{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
    align-items:center;
    -webkit-justify-content:center;
    justify-content:center;
    margin-bottom:0
  }

  .form-inline .form-group{
    display:-webkit-flex;
    display:flex;
    -webkit-flex:0 0 auto;
    flex:0 0 auto;
    -webkit-flex-flow:row wrap;
    flex-flow:row wrap;
    -webkit-align-items:center;
    align-items:center;
    margin-bottom:0
  }

  .form-inline .form-control{
    display:inline-block;
    width:auto;
    vertical-align:middle
  }

  .form-inline .form-control-plaintext{
    display:inline-block
  }

  .form-inline .input-group,.form-inline .custom-select{
    width:auto
  }

  .form-inline .form-check{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
    align-items:center;
    -webkit-justify-content:center;
    justify-content:center;
    width:auto;
    padding-left:0
  }

  .form-inline .form-check-input{
    position:relative;
    -webkit-flex-shrink:0;
            flex-shrink:0;
    margin-top:0;
    margin-right:.25rem;
    margin-left:0
  }

  .form-inline .custom-control{
    -webkit-align-items:center;
    align-items:center;
    -webkit-justify-content:center;
    justify-content:center
  }

  .form-inline .custom-control-label{
    margin-bottom:0
  }
}

.btn{
  display:inline-block;
  font-weight:400;
  color:#212529;
  text-align:center;
  vertical-align:middle;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
  background-color:transparent;
  border:1px solid transparent;
  padding:.375rem .75rem;
  font-size:1rem;
  line-height:1.5;
  border-radius:.25rem;
  transition:color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce){
  .btn{
    transition:none
  }
}

.btn:hover{
  color:#212529;
  text-decoration:none
}

.btn:focus,.btn.focus{
  outline:0;
  box-shadow:0 0 0 .2rem rgba(14,118,188,0.25)
}

.btn.disabled,.btn:disabled{
  opacity:.65
}

.btn:not(:disabled):not(.disabled){
  cursor:pointer
}

a.btn.disabled,fieldset:disabled a.btn{
  pointer-events:none
}

.btn-primary{
  color:#fff;
  background-color:#0e76bc;
  border-color:#0e76bc
}

.btn-primary:hover{
  color:#fff;
  background-color:#0b6098;
  border-color:#0a588d
}

.btn-primary:focus,.btn-primary.focus{
  color:#fff;
  background-color:#0b6098;
  border-color:#0a588d;
  box-shadow:0 0 0 .2rem rgba(50,139,198,0.5)
}

.btn-primary.disabled,.btn-primary:disabled{
  color:#fff;
  background-color:#0e76bc;
  border-color:#0e76bc
}

.btn-primary:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled).active,.show>.btn-primary.dropdown-toggle{
  color:#fff;
  background-color:#0a588d;
  border-color:#0a5181
}

.btn-primary:not(:disabled):not(.disabled):active:focus,.btn-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-primary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(50,139,198,0.5)
}

.btn-secondary{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}

.btn-secondary:hover{
  color:#fff;
  background-color:#5a6268;
  border-color:#545b62
}

.btn-secondary:focus,.btn-secondary.focus{
  color:#fff;
  background-color:#5a6268;
  border-color:#545b62;
  box-shadow:0 0 0 .2rem rgba(130,138,145,0.5)
}

.btn-secondary.disabled,.btn-secondary:disabled{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}

.btn-secondary:not(:disabled):not(.disabled):active,.btn-secondary:not(:disabled):not(.disabled).active,.show>.btn-secondary.dropdown-toggle{
  color:#fff;
  background-color:#545b62;
  border-color:#4e555b
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,.btn-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-secondary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(130,138,145,0.5)
}

.btn-success{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.btn-success:hover{
  color:#fff;
  background-color:#218838;
  border-color:#1e7e34
}

.btn-success:focus,.btn-success.focus{
  color:#fff;
  background-color:#218838;
  border-color:#1e7e34;
  box-shadow:0 0 0 .2rem rgba(72,180,97,0.5)
}

.btn-success.disabled,.btn-success:disabled{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.btn-success:not(:disabled):not(.disabled):active,.btn-success:not(:disabled):not(.disabled).active,.show>.btn-success.dropdown-toggle{
  color:#fff;
  background-color:#1e7e34;
  border-color:#1c7430
}

.btn-success:not(:disabled):not(.disabled):active:focus,.btn-success:not(:disabled):not(.disabled).active:focus,.show>.btn-success.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(72,180,97,0.5)
}

.btn-info{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}

.btn-info:hover{
  color:#fff;
  background-color:#138496;
  border-color:#117a8b
}

.btn-info:focus,.btn-info.focus{
  color:#fff;
  background-color:#138496;
  border-color:#117a8b;
  box-shadow:0 0 0 .2rem rgba(58,176,195,0.5)
}

.btn-info.disabled,.btn-info:disabled{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}

.btn-info:not(:disabled):not(.disabled):active,.btn-info:not(:disabled):not(.disabled).active,.show>.btn-info.dropdown-toggle{
  color:#fff;
  background-color:#117a8b;
  border-color:#10707f
}

.btn-info:not(:disabled):not(.disabled):active:focus,.btn-info:not(:disabled):not(.disabled).active:focus,.show>.btn-info.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(58,176,195,0.5)
}

.btn-warning{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}

.btn-warning:hover{
  color:#212529;
  background-color:#e0a800;
  border-color:#d39e00
}

.btn-warning:focus,.btn-warning.focus{
  color:#212529;
  background-color:#e0a800;
  border-color:#d39e00;
  box-shadow:0 0 0 .2rem rgba(222,170,12,0.5)
}

.btn-warning.disabled,.btn-warning:disabled{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}

.btn-warning:not(:disabled):not(.disabled):active,.btn-warning:not(:disabled):not(.disabled).active,.show>.btn-warning.dropdown-toggle{
  color:#212529;
  background-color:#d39e00;
  border-color:#c69500
}

.btn-warning:not(:disabled):not(.disabled):active:focus,.btn-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-warning.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(222,170,12,0.5)
}

.btn-danger{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}

.btn-danger:hover{
  color:#fff;
  background-color:#c82333;
  border-color:#bd2130
}

.btn-danger:focus,.btn-danger.focus{
  color:#fff;
  background-color:#c82333;
  border-color:#bd2130;
  box-shadow:0 0 0 .2rem rgba(225,83,97,0.5)
}

.btn-danger.disabled,.btn-danger:disabled{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}

.btn-danger:not(:disabled):not(.disabled):active,.btn-danger:not(:disabled):not(.disabled).active,.show>.btn-danger.dropdown-toggle{
  color:#fff;
  background-color:#bd2130;
  border-color:#b21f2d
}

.btn-danger:not(:disabled):not(.disabled):active:focus,.btn-danger:not(:disabled):not(.disabled).active:focus,.show>.btn-danger.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(225,83,97,0.5)
}

.btn-light{
  color:#212529;
  background-color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-light:hover{
  color:#212529;
  background-color:#e2e6ea;
  border-color:#dae0e5
}

.btn-light:focus,.btn-light.focus{
  color:#212529;
  background-color:#e2e6ea;
  border-color:#dae0e5;
  box-shadow:0 0 0 .2rem rgba(216,217,219,0.5)
}

.btn-light.disabled,.btn-light:disabled{
  color:#212529;
  background-color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-light:not(:disabled):not(.disabled):active,.btn-light:not(:disabled):not(.disabled).active,.show>.btn-light.dropdown-toggle{
  color:#212529;
  background-color:#dae0e5;
  border-color:#d3d9df
}

.btn-light:not(:disabled):not(.disabled):active:focus,.btn-light:not(:disabled):not(.disabled).active:focus,.show>.btn-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(216,217,219,0.5)
}

.btn-dark{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}

.btn-dark:hover{
  color:#fff;
  background-color:#23272b;
  border-color:#1d2124
}

.btn-dark:focus,.btn-dark.focus{
  color:#fff;
  background-color:#23272b;
  border-color:#1d2124;
  box-shadow:0 0 0 .2rem rgba(82,88,93,0.5)
}

.btn-dark.disabled,.btn-dark:disabled{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}

.btn-dark:not(:disabled):not(.disabled):active,.btn-dark:not(:disabled):not(.disabled).active,.show>.btn-dark.dropdown-toggle{
  color:#fff;
  background-color:#1d2124;
  border-color:#171a1d
}

.btn-dark:not(:disabled):not(.disabled):active:focus,.btn-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-dark.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(82,88,93,0.5)
}

.btn-outline-primary{
  color:#0e76bc;
  border-color:#0e76bc
}

.btn-outline-primary:hover{
  color:#fff;
  background-color:#0e76bc;
  border-color:#0e76bc
}

.btn-outline-primary:focus,.btn-outline-primary.focus{
  box-shadow:0 0 0 .2rem rgba(14,118,188,0.5)
}

.btn-outline-primary.disabled,.btn-outline-primary:disabled{
  color:#0e76bc;
  background-color:transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active,.show>.btn-outline-primary.dropdown-toggle{
  color:#fff;
  background-color:#0e76bc;
  border-color:#0e76bc
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-primary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(14,118,188,0.5)
}

.btn-outline-secondary{
  color:#6c757d;
  border-color:#6c757d
}

.btn-outline-secondary:hover{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}

.btn-outline-secondary:focus,.btn-outline-secondary.focus{
  box-shadow:0 0 0 .2rem rgba(108,117,125,0.5)
}

.btn-outline-secondary.disabled,.btn-outline-secondary:disabled{
  color:#6c757d;
  background-color:transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,.btn-outline-secondary:not(:disabled):not(.disabled).active,.show>.btn-outline-secondary.dropdown-toggle{
  color:#fff;
  background-color:#6c757d;
  border-color:#6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-secondary.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(108,117,125,0.5)
}

.btn-outline-success{
  color:#28a745;
  border-color:#28a745
}

.btn-outline-success:hover{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.btn-outline-success:focus,.btn-outline-success.focus{
  box-shadow:0 0 0 .2rem rgba(40,167,69,0.5)
}

.btn-outline-success.disabled,.btn-outline-success:disabled{
  color:#28a745;
  background-color:transparent
}

.btn-outline-success:not(:disabled):not(.disabled):active,.btn-outline-success:not(:disabled):not(.disabled).active,.show>.btn-outline-success.dropdown-toggle{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,.btn-outline-success:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-success.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(40,167,69,0.5)
}

.btn-outline-info{
  color:#17a2b8;
  border-color:#17a2b8
}

.btn-outline-info:hover{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}

.btn-outline-info:focus,.btn-outline-info.focus{
  box-shadow:0 0 0 .2rem rgba(23,162,184,0.5)
}

.btn-outline-info.disabled,.btn-outline-info:disabled{
  color:#17a2b8;
  background-color:transparent
}

.btn-outline-info:not(:disabled):not(.disabled):active,.btn-outline-info:not(:disabled):not(.disabled).active,.show>.btn-outline-info.dropdown-toggle{
  color:#fff;
  background-color:#17a2b8;
  border-color:#17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,.btn-outline-info:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-info.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(23,162,184,0.5)
}

.btn-outline-warning{
  color:#ffc107;
  border-color:#ffc107
}

.btn-outline-warning:hover{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}

.btn-outline-warning:focus,.btn-outline-warning.focus{
  box-shadow:0 0 0 .2rem rgba(255,193,7,0.5)
}

.btn-outline-warning.disabled,.btn-outline-warning:disabled{
  color:#ffc107;
  background-color:transparent
}

.btn-outline-warning:not(:disabled):not(.disabled):active,.btn-outline-warning:not(:disabled):not(.disabled).active,.show>.btn-outline-warning.dropdown-toggle{
  color:#212529;
  background-color:#ffc107;
  border-color:#ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,.btn-outline-warning:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-warning.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(255,193,7,0.5)
}

.btn-outline-danger{
  color:#dc3545;
  border-color:#dc3545
}

.btn-outline-danger:hover{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}

.btn-outline-danger:focus,.btn-outline-danger.focus{
  box-shadow:0 0 0 .2rem rgba(220,53,69,0.5)
}

.btn-outline-danger.disabled,.btn-outline-danger:disabled{
  color:#dc3545;
  background-color:transparent
}

.btn-outline-danger:not(:disabled):not(.disabled):active,.btn-outline-danger:not(:disabled):not(.disabled).active,.show>.btn-outline-danger.dropdown-toggle{
  color:#fff;
  background-color:#dc3545;
  border-color:#dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,.btn-outline-danger:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-danger.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(220,53,69,0.5)
}

.btn-outline-light{
  color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-outline-light:hover{
  color:#212529;
  background-color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-outline-light:focus,.btn-outline-light.focus{
  box-shadow:0 0 0 .2rem rgba(248,249,250,0.5)
}

.btn-outline-light.disabled,.btn-outline-light:disabled{
  color:#f8f9fa;
  background-color:transparent
}

.btn-outline-light:not(:disabled):not(.disabled):active,.btn-outline-light:not(:disabled):not(.disabled).active,.show>.btn-outline-light.dropdown-toggle{
  color:#212529;
  background-color:#f8f9fa;
  border-color:#f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,.btn-outline-light:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-light.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(248,249,250,0.5)
}

.btn-outline-dark{
  color:#343a40;
  border-color:#343a40
}

.btn-outline-dark:hover{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}

.btn-outline-dark:focus,.btn-outline-dark.focus{
  box-shadow:0 0 0 .2rem rgba(52,58,64,0.5)
}

.btn-outline-dark.disabled,.btn-outline-dark:disabled{
  color:#343a40;
  background-color:transparent
}

.btn-outline-dark:not(:disabled):not(.disabled):active,.btn-outline-dark:not(:disabled):not(.disabled).active,.show>.btn-outline-dark.dropdown-toggle{
  color:#fff;
  background-color:#343a40;
  border-color:#343a40
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,.btn-outline-dark:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-dark.dropdown-toggle:focus{
  box-shadow:0 0 0 .2rem rgba(52,58,64,0.5)
}

.btn-link{
  font-weight:400;
  color:#0e76bc;
  text-decoration:none
}

.btn-link:hover{
  color:#8dc63f;
  text-decoration:underline
}

.btn-link:focus,.btn-link.focus{
  text-decoration:underline
}

.btn-link:disabled,.btn-link.disabled{
  color:#6c757d;
  pointer-events:none
}

.btn-lg,.btn-group-lg>.btn{
  padding:.5rem 1rem;
  font-size:1.25rem;
  line-height:1.5;
  border-radius:.3rem
}

.btn-sm,.btn-group-sm>.btn{
  padding:.25rem .5rem;
  font-size:.875rem;
  line-height:1.5;
  border-radius:.2rem
}

.btn-block{
  display:block;
  width:100%
}

.btn-block+.btn-block{
  margin-top:.5rem
}

input[type="submit"].btn-block,input[type="reset"].btn-block,input[type="button"].btn-block{
  width:100%
}

.dropup,.dropright,.dropdown,.dropleft{
  position:relative
}

.dropdown-toggle{
  white-space:nowrap
}

.dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:"";
  border-top:.3em solid;
  border-right:.3em solid transparent;
  border-bottom:0;
  border-left:.3em solid transparent
}

.dropdown-toggle:empty::after{
  margin-left:0
}

.dropdown-menu{
  position:absolute;
  top:100%;
  left:0;
  z-index:1000;
  display:none;
  float:left;
  min-width:10rem;
  padding:.5rem 0;
  margin:.125rem 0 0;
  font-size:1rem;
  color:#212529;
  text-align:left;
  list-style:none;
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,0.15);
  border-radius:.25rem
}

.dropdown-menu-left{
  right:auto;
  left:0
}

.dropdown-menu-right{
  right:0;
  left:auto
}

@media (min-width: 576px){
  .dropdown-menu-sm-left{
    right:auto;
    left:0
  }

  .dropdown-menu-sm-right{
    right:0;
    left:auto
  }
}

@media (min-width: 768px){
  .dropdown-menu-md-left{
    right:auto;
    left:0
  }

  .dropdown-menu-md-right{
    right:0;
    left:auto
  }
}

@media (min-width: 992px){
  .dropdown-menu-lg-left{
    right:auto;
    left:0
  }

  .dropdown-menu-lg-right{
    right:0;
    left:auto
  }
}

@media (min-width: 1200px){
  .dropdown-menu-xl-left{
    right:auto;
    left:0
  }

  .dropdown-menu-xl-right{
    right:0;
    left:auto
  }
}

@media (min-width: 1600px){
  .dropdown-menu-xxl-left{
    right:auto;
    left:0
  }

  .dropdown-menu-xxl-right{
    right:0;
    left:auto
  }
}

.dropup .dropdown-menu{
  top:auto;
  bottom:100%;
  margin-top:0;
  margin-bottom:.125rem
}

.dropup .dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:"";
  border-top:0;
  border-right:.3em solid transparent;
  border-bottom:.3em solid;
  border-left:.3em solid transparent
}

.dropup .dropdown-toggle:empty::after{
  margin-left:0
}

.dropright .dropdown-menu{
  top:0;
  right:auto;
  left:100%;
  margin-top:0;
  margin-left:.125rem
}

.dropright .dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:"";
  border-top:.3em solid transparent;
  border-right:0;
  border-bottom:.3em solid transparent;
  border-left:.3em solid
}

.dropright .dropdown-toggle:empty::after{
  margin-left:0
}

.dropright .dropdown-toggle::after{
  vertical-align:0
}

.dropleft .dropdown-menu{
  top:0;
  right:100%;
  left:auto;
  margin-top:0;
  margin-right:.125rem
}

.dropleft .dropdown-toggle::after{
  display:inline-block;
  margin-left:.255em;
  vertical-align:.255em;
  content:""
}

.dropleft .dropdown-toggle::after{
  display:none
}

.dropleft .dropdown-toggle::before{
  display:inline-block;
  margin-right:.255em;
  vertical-align:.255em;
  content:"";
  border-top:.3em solid transparent;
  border-right:.3em solid;
  border-bottom:.3em solid transparent
}

.dropleft .dropdown-toggle:empty::after{
  margin-left:0
}

.dropleft .dropdown-toggle::before{
  vertical-align:0
}

.dropdown-menu[x-placement^="top"],.dropdown-menu[x-placement^="right"],.dropdown-menu[x-placement^="bottom"],.dropdown-menu[x-placement^="left"]{
  right:auto;
  bottom:auto
}

.dropdown-divider{
  height:0;
  margin:.5rem 0;
  overflow:hidden;
  border-top:1px solid #e9ecef
}

.dropdown-item{
  display:block;
  width:100%;
  padding:.25rem 1.5rem;
  clear:both;
  font-weight:400;
  color:#212529;
  text-align:inherit;
  white-space:nowrap;
  background-color:transparent;
  border:0
}

.dropdown-item:hover,.dropdown-item:focus{
  color:#16181b;
  text-decoration:none;
  background-color:#e9ecef
}

.dropdown-item.active,.dropdown-item:active{
  color:#fff;
  text-decoration:none;
  background-color:#0e76bc
}

.dropdown-item.disabled,.dropdown-item:disabled{
  color:#adb5bd;
  pointer-events:none;
  background-color:transparent
}

.dropdown-menu.show{
  display:block
}

.dropdown-header{
  display:block;
  padding:.5rem 1.5rem;
  margin-bottom:0;
  font-size:.875rem;
  color:#6c757d;
  white-space:nowrap
}

.dropdown-item-text{
  display:block;
  padding:.25rem 1.5rem;
  color:#212529
}

.btn-group,.btn-group-vertical{
  position:relative;
  display:-webkit-inline-flex;
  display:inline-flex;
  vertical-align:middle
}

.btn-group>.btn,.btn-group-vertical>.btn{
  position:relative;
  -webkit-flex:1 1 auto;
  flex:1 1 auto
}

.btn-group>.btn:hover,.btn-group-vertical>.btn:hover{
  z-index:1
}

.btn-group>.btn:focus,.btn-group>.btn:active,.btn-group>.btn.active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn.active{
  z-index:1
}

.btn-toolbar{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-justify-content:flex-start;
  justify-content:flex-start
}

.btn-toolbar .input-group{
  width:auto
}

.btn-group>.btn:not(:first-child),.btn-group>.btn-group:not(:first-child){
  margin-left:-1px
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),.btn-group>.btn-group:not(:last-child)>.btn{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

.btn-group>.btn:not(:first-child),.btn-group>.btn-group:not(:first-child)>.btn{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}

.dropdown-toggle-split{
  padding-right:.5625rem;
  padding-left:.5625rem
}

.dropdown-toggle-split::after,.dropup .dropdown-toggle-split::after,.dropright .dropdown-toggle-split::after{
  margin-left:0
}

.dropleft .dropdown-toggle-split::before{
  margin-right:0
}

.btn-sm+.dropdown-toggle-split,.btn-group-sm>.btn+.dropdown-toggle-split{
  padding-right:.375rem;
  padding-left:.375rem
}

.btn-lg+.dropdown-toggle-split,.btn-group-lg>.btn+.dropdown-toggle-split{
  padding-right:.75rem;
  padding-left:.75rem
}

.btn-group-vertical{
  -webkit-flex-direction:column;
  flex-direction:column;
  -webkit-align-items:flex-start;
  align-items:flex-start;
  -webkit-justify-content:center;
  justify-content:center
}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group{
  width:100%
}

.btn-group-vertical>.btn:not(:first-child),.btn-group-vertical>.btn-group:not(:first-child){
  margin-top:-1px
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),.btn-group-vertical>.btn-group:not(:last-child)>.btn{
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}

.btn-group-vertical>.btn:not(:first-child),.btn-group-vertical>.btn-group:not(:first-child)>.btn{
  border-top-left-radius:0;
  border-top-right-radius:0
}

.btn-group-toggle>.btn,.btn-group-toggle>.btn-group>.btn{
  margin-bottom:0
}

.btn-group-toggle>.btn input[type="radio"],.btn-group-toggle>.btn input[type="checkbox"],.btn-group-toggle>.btn-group>.btn input[type="radio"],.btn-group-toggle>.btn-group>.btn input[type="checkbox"]{
  position:absolute;
  clip:rect(0, 0, 0, 0);
  pointer-events:none
}

.input-group{
  position:relative;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-align-items:stretch;
  align-items:stretch;
  width:100%
}

.input-group>.form-control,.input-group>.form-control-plaintext,.input-group>.custom-select,.input-group>.custom-file{
  position:relative;
  -webkit-flex:1 1 auto;
  flex:1 1 auto;
  width:1%;
  min-width:0;
  margin-bottom:0
}

.input-group>.form-control+.form-control,.input-group>.form-control+.custom-select,.input-group>.form-control+.custom-file,.input-group>.form-control-plaintext+.form-control,.input-group>.form-control-plaintext+.custom-select,.input-group>.form-control-plaintext+.custom-file,.input-group>.custom-select+.form-control,.input-group>.custom-select+.custom-select,.input-group>.custom-select+.custom-file,.input-group>.custom-file+.form-control,.input-group>.custom-file+.custom-select,.input-group>.custom-file+.custom-file{
  margin-left:-1px
}

.input-group>.form-control:focus,.input-group>.custom-select:focus,.input-group>.custom-file .custom-file-input:focus ~ .custom-file-label{
  z-index:3
}

.input-group>.custom-file .custom-file-input:focus{
  z-index:4
}

.input-group>.form-control:not(:first-child),.input-group>.custom-select:not(:first-child){
  border-top-left-radius:0;
  border-bottom-left-radius:0
}

.input-group>.custom-file{
  display:-webkit-flex;
  display:flex;
  -webkit-align-items:center;
  align-items:center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,.input-group>.custom-file:not(:first-child) .custom-file-label{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}

.input-group:not(.has-validation)>.form-control:not(:last-child),.input-group:not(.has-validation)>.custom-select:not(:last-child),.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

.input-group.has-validation>.form-control:nth-last-child(n+3),.input-group.has-validation>.custom-select:nth-last-child(n+3),.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label::after{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

.input-group-prepend,.input-group-append{
  display:-webkit-flex;
  display:flex
}

.input-group-prepend .btn,.input-group-append .btn{
  position:relative;
  z-index:2
}

.input-group-prepend .btn:focus,.input-group-append .btn:focus{
  z-index:3
}

.input-group-prepend .btn+.btn,.input-group-prepend .btn+.input-group-text,.input-group-prepend .input-group-text+.input-group-text,.input-group-prepend .input-group-text+.btn,.input-group-append .btn+.btn,.input-group-append .btn+.input-group-text,.input-group-append .input-group-text+.input-group-text,.input-group-append .input-group-text+.btn{
  margin-left:-1px
}

.input-group-prepend{
  margin-right:-1px
}

.input-group-append{
  margin-left:-1px
}

.input-group-text{
  display:-webkit-flex;
  display:flex;
  -webkit-align-items:center;
  align-items:center;
  padding:.375rem .75rem;
  margin-bottom:0;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#495057;
  text-align:center;
  white-space:nowrap;
  background-color:#e9ecef;
  border:1px solid #ced4da;
  border-radius:.25rem
}

.input-group-text input[type="radio"],.input-group-text input[type="checkbox"]{
  margin-top:0
}

.input-group-lg>.form-control:not(textarea),.input-group-lg>.custom-select{
  height:calc(1.5em + 1rem + 2px)
}

.input-group-lg>.form-control,.input-group-lg>.custom-select,.input-group-lg>.input-group-prepend>.input-group-text,.input-group-lg>.input-group-append>.input-group-text,.input-group-lg>.input-group-prepend>.btn,.input-group-lg>.input-group-append>.btn{
  padding:.5rem 1rem;
  font-size:1.25rem;
  line-height:1.5;
  border-radius:.3rem
}

.input-group-sm>.form-control:not(textarea),.input-group-sm>.custom-select{
  height:calc(1.5em + .5rem + 2px)
}

.input-group-sm>.form-control,.input-group-sm>.custom-select,.input-group-sm>.input-group-prepend>.input-group-text,.input-group-sm>.input-group-append>.input-group-text,.input-group-sm>.input-group-prepend>.btn,.input-group-sm>.input-group-append>.btn{
  padding:.25rem .5rem;
  font-size:.875rem;
  line-height:1.5;
  border-radius:.2rem
}

.input-group-lg>.custom-select,.input-group-sm>.custom-select{
  padding-right:1.75rem
}

.input-group>.input-group-prepend>.btn,.input-group>.input-group-prepend>.input-group-text,.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),.input-group>.input-group-append:last-child>.input-group-text:not(:last-child){
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

.input-group>.input-group-append>.btn,.input-group>.input-group-append>.input-group-text,.input-group>.input-group-prepend:not(:first-child)>.btn,.input-group>.input-group-prepend:not(:first-child)>.input-group-text,.input-group>.input-group-prepend:first-child>.btn:not(:first-child),.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child){
  border-top-left-radius:0;
  border-bottom-left-radius:0
}

.alert{
  position:relative;
  padding:.75rem 1.25rem;
  margin-bottom:1rem;
  border:1px solid transparent;
  border-radius:.25rem
}

.alert-heading{
  color:inherit
}

.alert-link{
  font-weight:700
}

.alert-dismissible{
  padding-right:4rem
}

.alert-dismissible .close{
  position:absolute;
  top:0;
  right:0;
  z-index:2;
  padding:.75rem 1.25rem;
  color:inherit
}

.alert-primary{
  color:#073d62;
  background-color:#cfe4f2;
  border-color:#bcd9ec
}

.alert-primary hr{
  border-top-color:#a8cee6
}

.alert-primary .alert-link{
  color:#041f32
}

.alert-secondary{
  color:#383d41;
  background-color:#e2e3e5;
  border-color:#d6d8db
}

.alert-secondary hr{
  border-top-color:#c8cbcf
}

.alert-secondary .alert-link{
  color:#202326
}

.alert-success{
  color:#155724;
  background-color:#d4edda;
  border-color:#c3e6cb
}

.alert-success hr{
  border-top-color:#b1dfbb
}

.alert-success .alert-link{
  color:#0b2e13
}

.alert-info{
  color:#0c5460;
  background-color:#d1ecf1;
  border-color:#bee5eb
}

.alert-info hr{
  border-top-color:#abdde5
}

.alert-info .alert-link{
  color:#062c33
}

.alert-warning{
  color:#856404;
  background-color:#fff3cd;
  border-color:#ffeeba
}

.alert-warning hr{
  border-top-color:#ffe8a1
}

.alert-warning .alert-link{
  color:#533f03
}

.alert-danger{
  color:#721c24;
  background-color:#f8d7da;
  border-color:#f5c6cb
}

.alert-danger hr{
  border-top-color:#f1b0b7
}

.alert-danger .alert-link{
  color:#491217
}

.alert-light{
  color:#818182;
  background-color:#fefefe;
  border-color:#fdfdfe
}

.alert-light hr{
  border-top-color:#ececf6
}

.alert-light .alert-link{
  color:#686868
}

.alert-dark{
  color:#1b1e21;
  background-color:#d6d8d9;
  border-color:#c6c8ca
}

.alert-dark hr{
  border-top-color:#b9bbbe
}

.alert-dark .alert-link{
  color:#040505
}

@-webkit-keyframes spinner-border{
  to{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

@keyframes spinner-border{
  to{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

.spinner-border{
  display:inline-block;
  width:2rem;
  height:2rem;
  vertical-align:text-bottom;
  border:.25em solid currentColor;
  border-right-color:transparent;
  border-radius:50%;
  -webkit-animation:.75s linear infinite spinner-border;
  animation:.75s linear infinite spinner-border
}

.spinner-border-sm{
  width:1rem;
  height:1rem;
  border-width:.2em
}

@-webkit-keyframes spinner-grow{
  0%{
    -webkit-transform:scale(0);
    transform:scale(0)
  }

  50%{
    opacity:1;
    -webkit-transform:none;
    transform:none
  }
}

@keyframes spinner-grow{
  0%{
    -webkit-transform:scale(0);
    transform:scale(0)
  }

  50%{
    opacity:1;
    -webkit-transform:none;
    transform:none
  }
}

.spinner-grow{
  display:inline-block;
  width:2rem;
  height:2rem;
  vertical-align:text-bottom;
  background-color:currentColor;
  border-radius:50%;
  opacity:0;
  -webkit-animation:.75s linear infinite spinner-grow;
  animation:.75s linear infinite spinner-grow
}

.spinner-grow-sm{
  width:1rem;
  height:1rem
}

@media (prefers-reduced-motion: reduce){
  .spinner-border,.spinner-grow{
    -webkit-animation-duration:1.5s;
    animation-duration:1.5s
  }
}

.align-baseline{
  vertical-align:baseline !important
}

.align-top{
  vertical-align:top !important
}

.align-middle{
  vertical-align:middle !important
}

.align-bottom{
  vertical-align:bottom !important
}

.align-text-bottom{
  vertical-align:text-bottom !important
}

.align-text-top{
  vertical-align:text-top !important
}

.bg-primary{
  background-color:#0e76bc !important
}

a.bg-primary:hover,a.bg-primary:focus,button.bg-primary:hover,button.bg-primary:focus{
  background-color:#0a588d !important
}

.bg-secondary{
  background-color:#6c757d !important
}

a.bg-secondary:hover,a.bg-secondary:focus,button.bg-secondary:hover,button.bg-secondary:focus{
  background-color:#545b62 !important
}

.bg-success{
  background-color:#28a745 !important
}

a.bg-success:hover,a.bg-success:focus,button.bg-success:hover,button.bg-success:focus{
  background-color:#1e7e34 !important
}

.bg-info{
  background-color:#17a2b8 !important
}

a.bg-info:hover,a.bg-info:focus,button.bg-info:hover,button.bg-info:focus{
  background-color:#117a8b !important
}

.bg-warning{
  background-color:#ffc107 !important
}

a.bg-warning:hover,a.bg-warning:focus,button.bg-warning:hover,button.bg-warning:focus{
  background-color:#d39e00 !important
}

.bg-danger{
  background-color:#dc3545 !important
}

a.bg-danger:hover,a.bg-danger:focus,button.bg-danger:hover,button.bg-danger:focus{
  background-color:#bd2130 !important
}

.bg-light{
  background-color:#f8f9fa !important
}

a.bg-light:hover,a.bg-light:focus,button.bg-light:hover,button.bg-light:focus{
  background-color:#dae0e5 !important
}

.bg-dark{
  background-color:#343a40 !important
}

a.bg-dark:hover,a.bg-dark:focus,button.bg-dark:hover,button.bg-dark:focus{
  background-color:#1d2124 !important
}

.bg-white{
  background-color:#fff !important
}

.bg-transparent{
  background-color:transparent !important
}

.border{
  border:1px solid #dee2e6 !important
}

.border-top{
  border-top:1px solid #dee2e6 !important
}

.border-right{
  border-right:1px solid #dee2e6 !important
}

.border-bottom{
  border-bottom:1px solid #dee2e6 !important
}

.border-left{
  border-left:1px solid #dee2e6 !important
}

.border-0{
  border:0 !important
}

.border-top-0{
  border-top:0 !important
}

.border-right-0{
  border-right:0 !important
}

.border-bottom-0{
  border-bottom:0 !important
}

.border-left-0{
  border-left:0 !important
}

.border-primary{
  border-color:#0e76bc !important
}

.border-secondary{
  border-color:#6c757d !important
}

.border-success{
  border-color:#28a745 !important
}

.border-info{
  border-color:#17a2b8 !important
}

.border-warning{
  border-color:#ffc107 !important
}

.border-danger{
  border-color:#dc3545 !important
}

.border-light{
  border-color:#f8f9fa !important
}

.border-dark{
  border-color:#343a40 !important
}

.border-white{
  border-color:#fff !important
}

.rounded-sm{
  border-radius:.2rem !important
}

.rounded{
  border-radius:.25rem !important
}

.rounded-top{
  border-top-left-radius:.25rem !important;
  border-top-right-radius:.25rem !important
}

.rounded-right{
  border-top-right-radius:.25rem !important;
  border-bottom-right-radius:.25rem !important
}

.rounded-bottom{
  border-bottom-right-radius:.25rem !important;
  border-bottom-left-radius:.25rem !important
}

.rounded-left{
  border-top-left-radius:.25rem !important;
  border-bottom-left-radius:.25rem !important
}

.rounded-lg{
  border-radius:.3rem !important
}

.rounded-circle{
  border-radius:50% !important
}

.rounded-pill{
  border-radius:50rem !important
}

.rounded-0{
  border-radius:0 !important
}

.clearfix::after{
  display:block;
  clear:both;
  content:""
}

.d-none{
  display:none !important
}

.d-inline{
  display:inline !important
}

.d-inline-block{
  display:inline-block !important
}

.d-block{
  display:block !important
}

.d-table{
  display:table !important
}

.d-table-row{
  display:table-row !important
}

.d-table-cell{
  display:table-cell !important
}

.d-flex{
  display:-webkit-flex !important;
  display:flex !important
}

.d-inline-flex{
  display:-webkit-inline-flex !important;
  display:inline-flex !important
}

@media (min-width: 576px){
  .d-sm-none{
    display:none !important
  }

  .d-sm-inline{
    display:inline !important
  }

  .d-sm-inline-block{
    display:inline-block !important
  }

  .d-sm-block{
    display:block !important
  }

  .d-sm-table{
    display:table !important
  }

  .d-sm-table-row{
    display:table-row !important
  }

  .d-sm-table-cell{
    display:table-cell !important
  }

  .d-sm-flex{
    display:-webkit-flex !important;
    display:flex !important
  }

  .d-sm-inline-flex{
    display:-webkit-inline-flex !important;
    display:inline-flex !important
  }
}

@media (min-width: 768px){
  .d-md-none{
    display:none !important
  }

  .d-md-inline{
    display:inline !important
  }

  .d-md-inline-block{
    display:inline-block !important
  }

  .d-md-block{
    display:block !important
  }

  .d-md-table{
    display:table !important
  }

  .d-md-table-row{
    display:table-row !important
  }

  .d-md-table-cell{
    display:table-cell !important
  }

  .d-md-flex{
    display:-webkit-flex !important;
    display:flex !important
  }

  .d-md-inline-flex{
    display:-webkit-inline-flex !important;
    display:inline-flex !important
  }
}

@media (min-width: 992px){
  .d-lg-none{
    display:none !important
  }

  .d-lg-inline{
    display:inline !important
  }

  .d-lg-inline-block{
    display:inline-block !important
  }

  .d-lg-block{
    display:block !important
  }

  .d-lg-table{
    display:table !important
  }

  .d-lg-table-row{
    display:table-row !important
  }

  .d-lg-table-cell{
    display:table-cell !important
  }

  .d-lg-flex{
    display:-webkit-flex !important;
    display:flex !important
  }

  .d-lg-inline-flex{
    display:-webkit-inline-flex !important;
    display:inline-flex !important
  }
}

@media (min-width: 1200px){
  .d-xl-none{
    display:none !important
  }

  .d-xl-inline{
    display:inline !important
  }

  .d-xl-inline-block{
    display:inline-block !important
  }

  .d-xl-block{
    display:block !important
  }

  .d-xl-table{
    display:table !important
  }

  .d-xl-table-row{
    display:table-row !important
  }

  .d-xl-table-cell{
    display:table-cell !important
  }

  .d-xl-flex{
    display:-webkit-flex !important;
    display:flex !important
  }

  .d-xl-inline-flex{
    display:-webkit-inline-flex !important;
    display:inline-flex !important
  }
}

@media (min-width: 1600px){
  .d-xxl-none{
    display:none !important
  }

  .d-xxl-inline{
    display:inline !important
  }

  .d-xxl-inline-block{
    display:inline-block !important
  }

  .d-xxl-block{
    display:block !important
  }

  .d-xxl-table{
    display:table !important
  }

  .d-xxl-table-row{
    display:table-row !important
  }

  .d-xxl-table-cell{
    display:table-cell !important
  }

  .d-xxl-flex{
    display:-webkit-flex !important;
    display:flex !important
  }

  .d-xxl-inline-flex{
    display:-webkit-inline-flex !important;
    display:inline-flex !important
  }
}

@media print{
  .d-print-none{
    display:none !important
  }

  .d-print-inline{
    display:inline !important
  }

  .d-print-inline-block{
    display:inline-block !important
  }

  .d-print-block{
    display:block !important
  }

  .d-print-table{
    display:table !important
  }

  .d-print-table-row{
    display:table-row !important
  }

  .d-print-table-cell{
    display:table-cell !important
  }

  .d-print-flex{
    display:-webkit-flex !important;
    display:flex !important
  }

  .d-print-inline-flex{
    display:-webkit-inline-flex !important;
    display:inline-flex !important
  }
}

.embed-responsive{
  position:relative;
  display:block;
  width:100%;
  padding:0;
  overflow:hidden
}

.embed-responsive::before{
  display:block;
  content:""
}

.embed-responsive .embed-responsive-item,.embed-responsive iframe,.embed-responsive embed,.embed-responsive object,.embed-responsive video{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
  border:0
}

.embed-responsive-21by9::before{
  padding-top:42.85714%
}

.embed-responsive-16by9::before{
  padding-top:56.25%
}

.embed-responsive-4by3::before{
  padding-top:75%
}

.embed-responsive-1by1::before{
  padding-top:100%
}

.flex-row{
  -webkit-flex-direction:row !important;
  flex-direction:row !important
}

.flex-column{
  -webkit-flex-direction:column !important;
  flex-direction:column !important
}

.flex-row-reverse{
  -webkit-flex-direction:row-reverse !important;
  flex-direction:row-reverse !important
}

.flex-column-reverse{
  -webkit-flex-direction:column-reverse !important;
  flex-direction:column-reverse !important
}

.flex-wrap{
  -webkit-flex-wrap:wrap !important;
  flex-wrap:wrap !important
}

.flex-nowrap{
  -webkit-flex-wrap:nowrap !important;
  flex-wrap:nowrap !important
}

.flex-wrap-reverse{
  -webkit-flex-wrap:wrap-reverse !important;
  flex-wrap:wrap-reverse !important
}

.flex-fill{
  -webkit-flex:1 1 auto !important;
  flex:1 1 auto !important
}

.flex-grow-0{
  -webkit-flex-grow:0 !important;
  flex-grow:0 !important
}

.flex-grow-1{
  -webkit-flex-grow:1 !important;
  flex-grow:1 !important
}

.flex-shrink-0{
  -webkit-flex-shrink:0 !important;
          flex-shrink:0 !important
}

.flex-shrink-1{
  -webkit-flex-shrink:1 !important;
          flex-shrink:1 !important
}

.justify-content-start{
  -webkit-justify-content:flex-start !important;
  justify-content:flex-start !important
}

.justify-content-end{
  -webkit-justify-content:flex-end !important;
  justify-content:flex-end !important
}

.justify-content-center{
  -webkit-justify-content:center !important;
  justify-content:center !important
}

.justify-content-between{
  -webkit-justify-content:space-between !important;
  justify-content:space-between !important
}

.justify-content-around{
  -webkit-justify-content:space-around !important;
  justify-content:space-around !important
}

.align-items-start{
  -webkit-align-items:flex-start !important;
  align-items:flex-start !important
}

.align-items-end{
  -webkit-align-items:flex-end !important;
  align-items:flex-end !important
}

.align-items-center{
  -webkit-align-items:center !important;
  align-items:center !important
}

.align-items-baseline{
  -webkit-align-items:baseline !important;
  align-items:baseline !important
}

.align-items-stretch{
  -webkit-align-items:stretch !important;
  align-items:stretch !important
}

.align-content-start{
  -webkit-align-content:flex-start !important;
          align-content:flex-start !important
}

.align-content-end{
  -webkit-align-content:flex-end !important;
          align-content:flex-end !important
}

.align-content-center{
  -webkit-align-content:center !important;
          align-content:center !important
}

.align-content-between{
  -webkit-align-content:space-between !important;
          align-content:space-between !important
}

.align-content-around{
  -webkit-align-content:space-around !important;
          align-content:space-around !important
}

.align-content-stretch{
  -webkit-align-content:stretch !important;
          align-content:stretch !important
}

.align-self-auto{
  -webkit-align-self:auto !important;
  align-self:auto !important
}

.align-self-start{
  -webkit-align-self:flex-start !important;
  align-self:flex-start !important
}

.align-self-end{
  -webkit-align-self:flex-end !important;
  align-self:flex-end !important
}

.align-self-center{
  -webkit-align-self:center !important;
  align-self:center !important
}

.align-self-baseline{
  -webkit-align-self:baseline !important;
  align-self:baseline !important
}

.align-self-stretch{
  -webkit-align-self:stretch !important;
  align-self:stretch !important
}

@media (min-width: 576px){
  .flex-sm-row{
    -webkit-flex-direction:row !important;
    flex-direction:row !important
  }

  .flex-sm-column{
    -webkit-flex-direction:column !important;
    flex-direction:column !important
  }

  .flex-sm-row-reverse{
    -webkit-flex-direction:row-reverse !important;
    flex-direction:row-reverse !important
  }

  .flex-sm-column-reverse{
    -webkit-flex-direction:column-reverse !important;
    flex-direction:column-reverse !important
  }

  .flex-sm-wrap{
    -webkit-flex-wrap:wrap !important;
    flex-wrap:wrap !important
  }

  .flex-sm-nowrap{
    -webkit-flex-wrap:nowrap !important;
    flex-wrap:nowrap !important
  }

  .flex-sm-wrap-reverse{
    -webkit-flex-wrap:wrap-reverse !important;
    flex-wrap:wrap-reverse !important
  }

  .flex-sm-fill{
    -webkit-flex:1 1 auto !important;
    flex:1 1 auto !important
  }

  .flex-sm-grow-0{
    -webkit-flex-grow:0 !important;
    flex-grow:0 !important
  }

  .flex-sm-grow-1{
    -webkit-flex-grow:1 !important;
    flex-grow:1 !important
  }

  .flex-sm-shrink-0{
    -webkit-flex-shrink:0 !important;
            flex-shrink:0 !important
  }

  .flex-sm-shrink-1{
    -webkit-flex-shrink:1 !important;
            flex-shrink:1 !important
  }

  .justify-content-sm-start{
    -webkit-justify-content:flex-start !important;
    justify-content:flex-start !important
  }

  .justify-content-sm-end{
    -webkit-justify-content:flex-end !important;
    justify-content:flex-end !important
  }

  .justify-content-sm-center{
    -webkit-justify-content:center !important;
    justify-content:center !important
  }

  .justify-content-sm-between{
    -webkit-justify-content:space-between !important;
    justify-content:space-between !important
  }

  .justify-content-sm-around{
    -webkit-justify-content:space-around !important;
    justify-content:space-around !important
  }

  .align-items-sm-start{
    -webkit-align-items:flex-start !important;
    align-items:flex-start !important
  }

  .align-items-sm-end{
    -webkit-align-items:flex-end !important;
    align-items:flex-end !important
  }

  .align-items-sm-center{
    -webkit-align-items:center !important;
    align-items:center !important
  }

  .align-items-sm-baseline{
    -webkit-align-items:baseline !important;
    align-items:baseline !important
  }

  .align-items-sm-stretch{
    -webkit-align-items:stretch !important;
    align-items:stretch !important
  }

  .align-content-sm-start{
    -webkit-align-content:flex-start !important;
            align-content:flex-start !important
  }

  .align-content-sm-end{
    -webkit-align-content:flex-end !important;
            align-content:flex-end !important
  }

  .align-content-sm-center{
    -webkit-align-content:center !important;
            align-content:center !important
  }

  .align-content-sm-between{
    -webkit-align-content:space-between !important;
            align-content:space-between !important
  }

  .align-content-sm-around{
    -webkit-align-content:space-around !important;
            align-content:space-around !important
  }

  .align-content-sm-stretch{
    -webkit-align-content:stretch !important;
            align-content:stretch !important
  }

  .align-self-sm-auto{
    -webkit-align-self:auto !important;
    align-self:auto !important
  }

  .align-self-sm-start{
    -webkit-align-self:flex-start !important;
    align-self:flex-start !important
  }

  .align-self-sm-end{
    -webkit-align-self:flex-end !important;
    align-self:flex-end !important
  }

  .align-self-sm-center{
    -webkit-align-self:center !important;
    align-self:center !important
  }

  .align-self-sm-baseline{
    -webkit-align-self:baseline !important;
    align-self:baseline !important
  }

  .align-self-sm-stretch{
    -webkit-align-self:stretch !important;
    align-self:stretch !important
  }
}

@media (min-width: 768px){
  .flex-md-row{
    -webkit-flex-direction:row !important;
    flex-direction:row !important
  }

  .flex-md-column{
    -webkit-flex-direction:column !important;
    flex-direction:column !important
  }

  .flex-md-row-reverse{
    -webkit-flex-direction:row-reverse !important;
    flex-direction:row-reverse !important
  }

  .flex-md-column-reverse{
    -webkit-flex-direction:column-reverse !important;
    flex-direction:column-reverse !important
  }

  .flex-md-wrap{
    -webkit-flex-wrap:wrap !important;
    flex-wrap:wrap !important
  }

  .flex-md-nowrap{
    -webkit-flex-wrap:nowrap !important;
    flex-wrap:nowrap !important
  }

  .flex-md-wrap-reverse{
    -webkit-flex-wrap:wrap-reverse !important;
    flex-wrap:wrap-reverse !important
  }

  .flex-md-fill{
    -webkit-flex:1 1 auto !important;
    flex:1 1 auto !important
  }

  .flex-md-grow-0{
    -webkit-flex-grow:0 !important;
    flex-grow:0 !important
  }

  .flex-md-grow-1{
    -webkit-flex-grow:1 !important;
    flex-grow:1 !important
  }

  .flex-md-shrink-0{
    -webkit-flex-shrink:0 !important;
            flex-shrink:0 !important
  }

  .flex-md-shrink-1{
    -webkit-flex-shrink:1 !important;
            flex-shrink:1 !important
  }

  .justify-content-md-start{
    -webkit-justify-content:flex-start !important;
    justify-content:flex-start !important
  }

  .justify-content-md-end{
    -webkit-justify-content:flex-end !important;
    justify-content:flex-end !important
  }

  .justify-content-md-center{
    -webkit-justify-content:center !important;
    justify-content:center !important
  }

  .justify-content-md-between{
    -webkit-justify-content:space-between !important;
    justify-content:space-between !important
  }

  .justify-content-md-around{
    -webkit-justify-content:space-around !important;
    justify-content:space-around !important
  }

  .align-items-md-start{
    -webkit-align-items:flex-start !important;
    align-items:flex-start !important
  }

  .align-items-md-end{
    -webkit-align-items:flex-end !important;
    align-items:flex-end !important
  }

  .align-items-md-center{
    -webkit-align-items:center !important;
    align-items:center !important
  }

  .align-items-md-baseline{
    -webkit-align-items:baseline !important;
    align-items:baseline !important
  }

  .align-items-md-stretch{
    -webkit-align-items:stretch !important;
    align-items:stretch !important
  }

  .align-content-md-start{
    -webkit-align-content:flex-start !important;
            align-content:flex-start !important
  }

  .align-content-md-end{
    -webkit-align-content:flex-end !important;
            align-content:flex-end !important
  }

  .align-content-md-center{
    -webkit-align-content:center !important;
            align-content:center !important
  }

  .align-content-md-between{
    -webkit-align-content:space-between !important;
            align-content:space-between !important
  }

  .align-content-md-around{
    -webkit-align-content:space-around !important;
            align-content:space-around !important
  }

  .align-content-md-stretch{
    -webkit-align-content:stretch !important;
            align-content:stretch !important
  }

  .align-self-md-auto{
    -webkit-align-self:auto !important;
    align-self:auto !important
  }

  .align-self-md-start{
    -webkit-align-self:flex-start !important;
    align-self:flex-start !important
  }

  .align-self-md-end{
    -webkit-align-self:flex-end !important;
    align-self:flex-end !important
  }

  .align-self-md-center{
    -webkit-align-self:center !important;
    align-self:center !important
  }

  .align-self-md-baseline{
    -webkit-align-self:baseline !important;
    align-self:baseline !important
  }

  .align-self-md-stretch{
    -webkit-align-self:stretch !important;
    align-self:stretch !important
  }
}

@media (min-width: 992px){
  .flex-lg-row{
    -webkit-flex-direction:row !important;
    flex-direction:row !important
  }

  .flex-lg-column{
    -webkit-flex-direction:column !important;
    flex-direction:column !important
  }

  .flex-lg-row-reverse{
    -webkit-flex-direction:row-reverse !important;
    flex-direction:row-reverse !important
  }

  .flex-lg-column-reverse{
    -webkit-flex-direction:column-reverse !important;
    flex-direction:column-reverse !important
  }

  .flex-lg-wrap{
    -webkit-flex-wrap:wrap !important;
    flex-wrap:wrap !important
  }

  .flex-lg-nowrap{
    -webkit-flex-wrap:nowrap !important;
    flex-wrap:nowrap !important
  }

  .flex-lg-wrap-reverse{
    -webkit-flex-wrap:wrap-reverse !important;
    flex-wrap:wrap-reverse !important
  }

  .flex-lg-fill{
    -webkit-flex:1 1 auto !important;
    flex:1 1 auto !important
  }

  .flex-lg-grow-0{
    -webkit-flex-grow:0 !important;
    flex-grow:0 !important
  }

  .flex-lg-grow-1{
    -webkit-flex-grow:1 !important;
    flex-grow:1 !important
  }

  .flex-lg-shrink-0{
    -webkit-flex-shrink:0 !important;
            flex-shrink:0 !important
  }

  .flex-lg-shrink-1{
    -webkit-flex-shrink:1 !important;
            flex-shrink:1 !important
  }

  .justify-content-lg-start{
    -webkit-justify-content:flex-start !important;
    justify-content:flex-start !important
  }

  .justify-content-lg-end{
    -webkit-justify-content:flex-end !important;
    justify-content:flex-end !important
  }

  .justify-content-lg-center{
    -webkit-justify-content:center !important;
    justify-content:center !important
  }

  .justify-content-lg-between{
    -webkit-justify-content:space-between !important;
    justify-content:space-between !important
  }

  .justify-content-lg-around{
    -webkit-justify-content:space-around !important;
    justify-content:space-around !important
  }

  .align-items-lg-start{
    -webkit-align-items:flex-start !important;
    align-items:flex-start !important
  }

  .align-items-lg-end{
    -webkit-align-items:flex-end !important;
    align-items:flex-end !important
  }

  .align-items-lg-center{
    -webkit-align-items:center !important;
    align-items:center !important
  }

  .align-items-lg-baseline{
    -webkit-align-items:baseline !important;
    align-items:baseline !important
  }

  .align-items-lg-stretch{
    -webkit-align-items:stretch !important;
    align-items:stretch !important
  }

  .align-content-lg-start{
    -webkit-align-content:flex-start !important;
            align-content:flex-start !important
  }

  .align-content-lg-end{
    -webkit-align-content:flex-end !important;
            align-content:flex-end !important
  }

  .align-content-lg-center{
    -webkit-align-content:center !important;
            align-content:center !important
  }

  .align-content-lg-between{
    -webkit-align-content:space-between !important;
            align-content:space-between !important
  }

  .align-content-lg-around{
    -webkit-align-content:space-around !important;
            align-content:space-around !important
  }

  .align-content-lg-stretch{
    -webkit-align-content:stretch !important;
            align-content:stretch !important
  }

  .align-self-lg-auto{
    -webkit-align-self:auto !important;
    align-self:auto !important
  }

  .align-self-lg-start{
    -webkit-align-self:flex-start !important;
    align-self:flex-start !important
  }

  .align-self-lg-end{
    -webkit-align-self:flex-end !important;
    align-self:flex-end !important
  }

  .align-self-lg-center{
    -webkit-align-self:center !important;
    align-self:center !important
  }

  .align-self-lg-baseline{
    -webkit-align-self:baseline !important;
    align-self:baseline !important
  }

  .align-self-lg-stretch{
    -webkit-align-self:stretch !important;
    align-self:stretch !important
  }
}

@media (min-width: 1200px){
  .flex-xl-row{
    -webkit-flex-direction:row !important;
    flex-direction:row !important
  }

  .flex-xl-column{
    -webkit-flex-direction:column !important;
    flex-direction:column !important
  }

  .flex-xl-row-reverse{
    -webkit-flex-direction:row-reverse !important;
    flex-direction:row-reverse !important
  }

  .flex-xl-column-reverse{
    -webkit-flex-direction:column-reverse !important;
    flex-direction:column-reverse !important
  }

  .flex-xl-wrap{
    -webkit-flex-wrap:wrap !important;
    flex-wrap:wrap !important
  }

  .flex-xl-nowrap{
    -webkit-flex-wrap:nowrap !important;
    flex-wrap:nowrap !important
  }

  .flex-xl-wrap-reverse{
    -webkit-flex-wrap:wrap-reverse !important;
    flex-wrap:wrap-reverse !important
  }

  .flex-xl-fill{
    -webkit-flex:1 1 auto !important;
    flex:1 1 auto !important
  }

  .flex-xl-grow-0{
    -webkit-flex-grow:0 !important;
    flex-grow:0 !important
  }

  .flex-xl-grow-1{
    -webkit-flex-grow:1 !important;
    flex-grow:1 !important
  }

  .flex-xl-shrink-0{
    -webkit-flex-shrink:0 !important;
            flex-shrink:0 !important
  }

  .flex-xl-shrink-1{
    -webkit-flex-shrink:1 !important;
            flex-shrink:1 !important
  }

  .justify-content-xl-start{
    -webkit-justify-content:flex-start !important;
    justify-content:flex-start !important
  }

  .justify-content-xl-end{
    -webkit-justify-content:flex-end !important;
    justify-content:flex-end !important
  }

  .justify-content-xl-center{
    -webkit-justify-content:center !important;
    justify-content:center !important
  }

  .justify-content-xl-between{
    -webkit-justify-content:space-between !important;
    justify-content:space-between !important
  }

  .justify-content-xl-around{
    -webkit-justify-content:space-around !important;
    justify-content:space-around !important
  }

  .align-items-xl-start{
    -webkit-align-items:flex-start !important;
    align-items:flex-start !important
  }

  .align-items-xl-end{
    -webkit-align-items:flex-end !important;
    align-items:flex-end !important
  }

  .align-items-xl-center{
    -webkit-align-items:center !important;
    align-items:center !important
  }

  .align-items-xl-baseline{
    -webkit-align-items:baseline !important;
    align-items:baseline !important
  }

  .align-items-xl-stretch{
    -webkit-align-items:stretch !important;
    align-items:stretch !important
  }

  .align-content-xl-start{
    -webkit-align-content:flex-start !important;
            align-content:flex-start !important
  }

  .align-content-xl-end{
    -webkit-align-content:flex-end !important;
            align-content:flex-end !important
  }

  .align-content-xl-center{
    -webkit-align-content:center !important;
            align-content:center !important
  }

  .align-content-xl-between{
    -webkit-align-content:space-between !important;
            align-content:space-between !important
  }

  .align-content-xl-around{
    -webkit-align-content:space-around !important;
            align-content:space-around !important
  }

  .align-content-xl-stretch{
    -webkit-align-content:stretch !important;
            align-content:stretch !important
  }

  .align-self-xl-auto{
    -webkit-align-self:auto !important;
    align-self:auto !important
  }

  .align-self-xl-start{
    -webkit-align-self:flex-start !important;
    align-self:flex-start !important
  }

  .align-self-xl-end{
    -webkit-align-self:flex-end !important;
    align-self:flex-end !important
  }

  .align-self-xl-center{
    -webkit-align-self:center !important;
    align-self:center !important
  }

  .align-self-xl-baseline{
    -webkit-align-self:baseline !important;
    align-self:baseline !important
  }

  .align-self-xl-stretch{
    -webkit-align-self:stretch !important;
    align-self:stretch !important
  }
}

@media (min-width: 1600px){
  .flex-xxl-row{
    -webkit-flex-direction:row !important;
    flex-direction:row !important
  }

  .flex-xxl-column{
    -webkit-flex-direction:column !important;
    flex-direction:column !important
  }

  .flex-xxl-row-reverse{
    -webkit-flex-direction:row-reverse !important;
    flex-direction:row-reverse !important
  }

  .flex-xxl-column-reverse{
    -webkit-flex-direction:column-reverse !important;
    flex-direction:column-reverse !important
  }

  .flex-xxl-wrap{
    -webkit-flex-wrap:wrap !important;
    flex-wrap:wrap !important
  }

  .flex-xxl-nowrap{
    -webkit-flex-wrap:nowrap !important;
    flex-wrap:nowrap !important
  }

  .flex-xxl-wrap-reverse{
    -webkit-flex-wrap:wrap-reverse !important;
    flex-wrap:wrap-reverse !important
  }

  .flex-xxl-fill{
    -webkit-flex:1 1 auto !important;
    flex:1 1 auto !important
  }

  .flex-xxl-grow-0{
    -webkit-flex-grow:0 !important;
    flex-grow:0 !important
  }

  .flex-xxl-grow-1{
    -webkit-flex-grow:1 !important;
    flex-grow:1 !important
  }

  .flex-xxl-shrink-0{
    -webkit-flex-shrink:0 !important;
            flex-shrink:0 !important
  }

  .flex-xxl-shrink-1{
    -webkit-flex-shrink:1 !important;
            flex-shrink:1 !important
  }

  .justify-content-xxl-start{
    -webkit-justify-content:flex-start !important;
    justify-content:flex-start !important
  }

  .justify-content-xxl-end{
    -webkit-justify-content:flex-end !important;
    justify-content:flex-end !important
  }

  .justify-content-xxl-center{
    -webkit-justify-content:center !important;
    justify-content:center !important
  }

  .justify-content-xxl-between{
    -webkit-justify-content:space-between !important;
    justify-content:space-between !important
  }

  .justify-content-xxl-around{
    -webkit-justify-content:space-around !important;
    justify-content:space-around !important
  }

  .align-items-xxl-start{
    -webkit-align-items:flex-start !important;
    align-items:flex-start !important
  }

  .align-items-xxl-end{
    -webkit-align-items:flex-end !important;
    align-items:flex-end !important
  }

  .align-items-xxl-center{
    -webkit-align-items:center !important;
    align-items:center !important
  }

  .align-items-xxl-baseline{
    -webkit-align-items:baseline !important;
    align-items:baseline !important
  }

  .align-items-xxl-stretch{
    -webkit-align-items:stretch !important;
    align-items:stretch !important
  }

  .align-content-xxl-start{
    -webkit-align-content:flex-start !important;
            align-content:flex-start !important
  }

  .align-content-xxl-end{
    -webkit-align-content:flex-end !important;
            align-content:flex-end !important
  }

  .align-content-xxl-center{
    -webkit-align-content:center !important;
            align-content:center !important
  }

  .align-content-xxl-between{
    -webkit-align-content:space-between !important;
            align-content:space-between !important
  }

  .align-content-xxl-around{
    -webkit-align-content:space-around !important;
            align-content:space-around !important
  }

  .align-content-xxl-stretch{
    -webkit-align-content:stretch !important;
            align-content:stretch !important
  }

  .align-self-xxl-auto{
    -webkit-align-self:auto !important;
    align-self:auto !important
  }

  .align-self-xxl-start{
    -webkit-align-self:flex-start !important;
    align-self:flex-start !important
  }

  .align-self-xxl-end{
    -webkit-align-self:flex-end !important;
    align-self:flex-end !important
  }

  .align-self-xxl-center{
    -webkit-align-self:center !important;
    align-self:center !important
  }

  .align-self-xxl-baseline{
    -webkit-align-self:baseline !important;
    align-self:baseline !important
  }

  .align-self-xxl-stretch{
    -webkit-align-self:stretch !important;
    align-self:stretch !important
  }
}

.float-left{
  float:left !important
}

.float-right{
  float:right !important
}

.float-none{
  float:none !important
}

@media (min-width: 576px){
  .float-sm-left{
    float:left !important
  }

  .float-sm-right{
    float:right !important
  }

  .float-sm-none{
    float:none !important
  }
}

@media (min-width: 768px){
  .float-md-left{
    float:left !important
  }

  .float-md-right{
    float:right !important
  }

  .float-md-none{
    float:none !important
  }
}

@media (min-width: 992px){
  .float-lg-left{
    float:left !important
  }

  .float-lg-right{
    float:right !important
  }

  .float-lg-none{
    float:none !important
  }
}

@media (min-width: 1200px){
  .float-xl-left{
    float:left !important
  }

  .float-xl-right{
    float:right !important
  }

  .float-xl-none{
    float:none !important
  }
}

@media (min-width: 1600px){
  .float-xxl-left{
    float:left !important
  }

  .float-xxl-right{
    float:right !important
  }

  .float-xxl-none{
    float:none !important
  }
}

.user-select-all{
  -webkit-user-select:all !important;
  -ms-user-select:all !important;
  user-select:all !important
}

.user-select-auto{
  -webkit-user-select:auto !important;
  -ms-user-select:auto !important;
  user-select:auto !important
}

.user-select-none{
  -webkit-user-select:none !important;
  -ms-user-select:none !important;
  user-select:none !important
}

.overflow-auto{
  overflow:auto !important
}

.overflow-hidden{
  overflow:hidden !important
}

.position-static{
  position:static !important
}

.position-relative{
  position:relative !important
}

.position-absolute{
  position:absolute !important
}

.position-fixed{
  position:fixed !important
}

.position-sticky{
  position:-webkit-sticky !important;
  position:sticky !important
}

.fixed-top{
  position:fixed;
  top:0;
  right:0;
  left:0;
  z-index:1030
}

.fixed-bottom{
  position:fixed;
  right:0;
  bottom:0;
  left:0;
  z-index:1030
}

@supports ((position: -webkit-sticky) or (position: sticky)){
  .sticky-top{
    position:-webkit-sticky;
    position:sticky;
    top:0;
    z-index:1020
  }
}

.sr-only{
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  margin:-1px;
  overflow:hidden;
  clip:rect(0, 0, 0, 0);
  white-space:nowrap;
  border:0
}

.sr-only-focusable:active,.sr-only-focusable:focus{
  position:static;
  width:auto;
  height:auto;
  overflow:visible;
  clip:auto;
  white-space:normal
}

.shadow-sm{
  box-shadow:0 0.125rem 0.25rem rgba(0,0,0,0.075) !important
}

.shadow{
  box-shadow:0 0.5rem 1rem rgba(0,0,0,0.15) !important
}

.shadow-lg{
  box-shadow:0 1rem 3rem rgba(0,0,0,0.175) !important
}

.shadow-none{
  box-shadow:none !important
}

.w-25{
  width:25% !important
}

.w-50{
  width:50% !important
}

.w-75{
  width:75% !important
}

.w-100{
  width:100% !important
}

.w-auto{
  width:auto !important
}

.h-25{
  height:25% !important
}

.h-50{
  height:50% !important
}

.h-75{
  height:75% !important
}

.h-100{
  height:100% !important
}

.h-auto{
  height:auto !important
}

.mw-100{
  max-width:100% !important
}

.mh-100{
  max-height:100% !important
}

.min-vw-100{
  min-width:100vw !important
}

.min-vh-100{
  min-height:100vh !important
}

.vw-100{
  width:100vw !important
}

.vh-100{
  height:100vh !important
}

.m-0{
  margin:0 !important
}

.mt-0,.my-0{
  margin-top:0 !important
}

.mr-0,.mx-0{
  margin-right:0 !important
}

.mb-0,.my-0{
  margin-bottom:0 !important
}

.ml-0,.mx-0{
  margin-left:0 !important
}

.m-1{
  margin:.25rem !important
}

.mt-1,.my-1{
  margin-top:.25rem !important
}

.mr-1,.mx-1{
  margin-right:.25rem !important
}

.mb-1,.my-1{
  margin-bottom:.25rem !important
}

.ml-1,.mx-1{
  margin-left:.25rem !important
}

.m-2{
  margin:.5rem !important
}

.mt-2,.my-2{
  margin-top:.5rem !important
}

.mr-2,.mx-2{
  margin-right:.5rem !important
}

.mb-2,.my-2{
  margin-bottom:.5rem !important
}

.ml-2,.mx-2{
  margin-left:.5rem !important
}

.m-3{
  margin:1rem !important
}

.mt-3,.my-3{
  margin-top:1rem !important
}

.mr-3,.mx-3{
  margin-right:1rem !important
}

.mb-3,.my-3{
  margin-bottom:1rem !important
}

.ml-3,.mx-3{
  margin-left:1rem !important
}

.m-4{
  margin:1.5rem !important
}

.mt-4,.my-4{
  margin-top:1.5rem !important
}

.mr-4,.mx-4{
  margin-right:1.5rem !important
}

.mb-4,.my-4{
  margin-bottom:1.5rem !important
}

.ml-4,.mx-4{
  margin-left:1.5rem !important
}

.m-5{
  margin:3rem !important
}

.mt-5,.my-5{
  margin-top:3rem !important
}

.mr-5,.mx-5{
  margin-right:3rem !important
}

.mb-5,.my-5{
  margin-bottom:3rem !important
}

.ml-5,.mx-5{
  margin-left:3rem !important
}

.p-0{
  padding:0 !important
}

.pt-0,.py-0{
  padding-top:0 !important
}

.pr-0,.px-0{
  padding-right:0 !important
}

.pb-0,.py-0{
  padding-bottom:0 !important
}

.pl-0,.px-0{
  padding-left:0 !important
}

.p-1{
  padding:.25rem !important
}

.pt-1,.py-1{
  padding-top:.25rem !important
}

.pr-1,.px-1{
  padding-right:.25rem !important
}

.pb-1,.py-1{
  padding-bottom:.25rem !important
}

.pl-1,.px-1{
  padding-left:.25rem !important
}

.p-2{
  padding:.5rem !important
}

.pt-2,.py-2{
  padding-top:.5rem !important
}

.pr-2,.px-2{
  padding-right:.5rem !important
}

.pb-2,.py-2{
  padding-bottom:.5rem !important
}

.pl-2,.px-2{
  padding-left:.5rem !important
}

.p-3{
  padding:1rem !important
}

.pt-3,.py-3{
  padding-top:1rem !important
}

.pr-3,.px-3{
  padding-right:1rem !important
}

.pb-3,.py-3{
  padding-bottom:1rem !important
}

.pl-3,.px-3{
  padding-left:1rem !important
}

.p-4{
  padding:1.5rem !important
}

.pt-4,.py-4{
  padding-top:1.5rem !important
}

.pr-4,.px-4{
  padding-right:1.5rem !important
}

.pb-4,.py-4{
  padding-bottom:1.5rem !important
}

.pl-4,.px-4{
  padding-left:1.5rem !important
}

.p-5{
  padding:3rem !important
}

.pt-5,.py-5{
  padding-top:3rem !important
}

.pr-5,.px-5{
  padding-right:3rem !important
}

.pb-5,.py-5{
  padding-bottom:3rem !important
}

.pl-5,.px-5{
  padding-left:3rem !important
}

.m-n1{
  margin:-.25rem !important
}

.mt-n1,.my-n1{
  margin-top:-.25rem !important
}

.mr-n1,.mx-n1{
  margin-right:-.25rem !important
}

.mb-n1,.my-n1{
  margin-bottom:-.25rem !important
}

.ml-n1,.mx-n1{
  margin-left:-.25rem !important
}

.m-n2{
  margin:-.5rem !important
}

.mt-n2,.my-n2{
  margin-top:-.5rem !important
}

.mr-n2,.mx-n2{
  margin-right:-.5rem !important
}

.mb-n2,.my-n2{
  margin-bottom:-.5rem !important
}

.ml-n2,.mx-n2{
  margin-left:-.5rem !important
}

.m-n3{
  margin:-1rem !important
}

.mt-n3,.my-n3{
  margin-top:-1rem !important
}

.mr-n3,.mx-n3{
  margin-right:-1rem !important
}

.mb-n3,.my-n3{
  margin-bottom:-1rem !important
}

.ml-n3,.mx-n3{
  margin-left:-1rem !important
}

.m-n4{
  margin:-1.5rem !important
}

.mt-n4,.my-n4{
  margin-top:-1.5rem !important
}

.mr-n4,.mx-n4{
  margin-right:-1.5rem !important
}

.mb-n4,.my-n4{
  margin-bottom:-1.5rem !important
}

.ml-n4,.mx-n4{
  margin-left:-1.5rem !important
}

.m-n5{
  margin:-3rem !important
}

.mt-n5,.my-n5{
  margin-top:-3rem !important
}

.mr-n5,.mx-n5{
  margin-right:-3rem !important
}

.mb-n5,.my-n5{
  margin-bottom:-3rem !important
}

.ml-n5,.mx-n5{
  margin-left:-3rem !important
}

.m-auto{
  margin:auto !important
}

.mt-auto,.my-auto{
  margin-top:auto !important
}

.mr-auto,.mx-auto{
  margin-right:auto !important
}

.mb-auto,.my-auto{
  margin-bottom:auto !important
}

.ml-auto,.mx-auto{
  margin-left:auto !important
}

@media (min-width: 576px){
  .m-sm-0{
    margin:0 !important
  }

  .mt-sm-0,.my-sm-0{
    margin-top:0 !important
  }

  .mr-sm-0,.mx-sm-0{
    margin-right:0 !important
  }

  .mb-sm-0,.my-sm-0{
    margin-bottom:0 !important
  }

  .ml-sm-0,.mx-sm-0{
    margin-left:0 !important
  }

  .m-sm-1{
    margin:.25rem !important
  }

  .mt-sm-1,.my-sm-1{
    margin-top:.25rem !important
  }

  .mr-sm-1,.mx-sm-1{
    margin-right:.25rem !important
  }

  .mb-sm-1,.my-sm-1{
    margin-bottom:.25rem !important
  }

  .ml-sm-1,.mx-sm-1{
    margin-left:.25rem !important
  }

  .m-sm-2{
    margin:.5rem !important
  }

  .mt-sm-2,.my-sm-2{
    margin-top:.5rem !important
  }

  .mr-sm-2,.mx-sm-2{
    margin-right:.5rem !important
  }

  .mb-sm-2,.my-sm-2{
    margin-bottom:.5rem !important
  }

  .ml-sm-2,.mx-sm-2{
    margin-left:.5rem !important
  }

  .m-sm-3{
    margin:1rem !important
  }

  .mt-sm-3,.my-sm-3{
    margin-top:1rem !important
  }

  .mr-sm-3,.mx-sm-3{
    margin-right:1rem !important
  }

  .mb-sm-3,.my-sm-3{
    margin-bottom:1rem !important
  }

  .ml-sm-3,.mx-sm-3{
    margin-left:1rem !important
  }

  .m-sm-4{
    margin:1.5rem !important
  }

  .mt-sm-4,.my-sm-4{
    margin-top:1.5rem !important
  }

  .mr-sm-4,.mx-sm-4{
    margin-right:1.5rem !important
  }

  .mb-sm-4,.my-sm-4{
    margin-bottom:1.5rem !important
  }

  .ml-sm-4,.mx-sm-4{
    margin-left:1.5rem !important
  }

  .m-sm-5{
    margin:3rem !important
  }

  .mt-sm-5,.my-sm-5{
    margin-top:3rem !important
  }

  .mr-sm-5,.mx-sm-5{
    margin-right:3rem !important
  }

  .mb-sm-5,.my-sm-5{
    margin-bottom:3rem !important
  }

  .ml-sm-5,.mx-sm-5{
    margin-left:3rem !important
  }

  .p-sm-0{
    padding:0 !important
  }

  .pt-sm-0,.py-sm-0{
    padding-top:0 !important
  }

  .pr-sm-0,.px-sm-0{
    padding-right:0 !important
  }

  .pb-sm-0,.py-sm-0{
    padding-bottom:0 !important
  }

  .pl-sm-0,.px-sm-0{
    padding-left:0 !important
  }

  .p-sm-1{
    padding:.25rem !important
  }

  .pt-sm-1,.py-sm-1{
    padding-top:.25rem !important
  }

  .pr-sm-1,.px-sm-1{
    padding-right:.25rem !important
  }

  .pb-sm-1,.py-sm-1{
    padding-bottom:.25rem !important
  }

  .pl-sm-1,.px-sm-1{
    padding-left:.25rem !important
  }

  .p-sm-2{
    padding:.5rem !important
  }

  .pt-sm-2,.py-sm-2{
    padding-top:.5rem !important
  }

  .pr-sm-2,.px-sm-2{
    padding-right:.5rem !important
  }

  .pb-sm-2,.py-sm-2{
    padding-bottom:.5rem !important
  }

  .pl-sm-2,.px-sm-2{
    padding-left:.5rem !important
  }

  .p-sm-3{
    padding:1rem !important
  }

  .pt-sm-3,.py-sm-3{
    padding-top:1rem !important
  }

  .pr-sm-3,.px-sm-3{
    padding-right:1rem !important
  }

  .pb-sm-3,.py-sm-3{
    padding-bottom:1rem !important
  }

  .pl-sm-3,.px-sm-3{
    padding-left:1rem !important
  }

  .p-sm-4{
    padding:1.5rem !important
  }

  .pt-sm-4,.py-sm-4{
    padding-top:1.5rem !important
  }

  .pr-sm-4,.px-sm-4{
    padding-right:1.5rem !important
  }

  .pb-sm-4,.py-sm-4{
    padding-bottom:1.5rem !important
  }

  .pl-sm-4,.px-sm-4{
    padding-left:1.5rem !important
  }

  .p-sm-5{
    padding:3rem !important
  }

  .pt-sm-5,.py-sm-5{
    padding-top:3rem !important
  }

  .pr-sm-5,.px-sm-5{
    padding-right:3rem !important
  }

  .pb-sm-5,.py-sm-5{
    padding-bottom:3rem !important
  }

  .pl-sm-5,.px-sm-5{
    padding-left:3rem !important
  }

  .m-sm-n1{
    margin:-.25rem !important
  }

  .mt-sm-n1,.my-sm-n1{
    margin-top:-.25rem !important
  }

  .mr-sm-n1,.mx-sm-n1{
    margin-right:-.25rem !important
  }

  .mb-sm-n1,.my-sm-n1{
    margin-bottom:-.25rem !important
  }

  .ml-sm-n1,.mx-sm-n1{
    margin-left:-.25rem !important
  }

  .m-sm-n2{
    margin:-.5rem !important
  }

  .mt-sm-n2,.my-sm-n2{
    margin-top:-.5rem !important
  }

  .mr-sm-n2,.mx-sm-n2{
    margin-right:-.5rem !important
  }

  .mb-sm-n2,.my-sm-n2{
    margin-bottom:-.5rem !important
  }

  .ml-sm-n2,.mx-sm-n2{
    margin-left:-.5rem !important
  }

  .m-sm-n3{
    margin:-1rem !important
  }

  .mt-sm-n3,.my-sm-n3{
    margin-top:-1rem !important
  }

  .mr-sm-n3,.mx-sm-n3{
    margin-right:-1rem !important
  }

  .mb-sm-n3,.my-sm-n3{
    margin-bottom:-1rem !important
  }

  .ml-sm-n3,.mx-sm-n3{
    margin-left:-1rem !important
  }

  .m-sm-n4{
    margin:-1.5rem !important
  }

  .mt-sm-n4,.my-sm-n4{
    margin-top:-1.5rem !important
  }

  .mr-sm-n4,.mx-sm-n4{
    margin-right:-1.5rem !important
  }

  .mb-sm-n4,.my-sm-n4{
    margin-bottom:-1.5rem !important
  }

  .ml-sm-n4,.mx-sm-n4{
    margin-left:-1.5rem !important
  }

  .m-sm-n5{
    margin:-3rem !important
  }

  .mt-sm-n5,.my-sm-n5{
    margin-top:-3rem !important
  }

  .mr-sm-n5,.mx-sm-n5{
    margin-right:-3rem !important
  }

  .mb-sm-n5,.my-sm-n5{
    margin-bottom:-3rem !important
  }

  .ml-sm-n5,.mx-sm-n5{
    margin-left:-3rem !important
  }

  .m-sm-auto{
    margin:auto !important
  }

  .mt-sm-auto,.my-sm-auto{
    margin-top:auto !important
  }

  .mr-sm-auto,.mx-sm-auto{
    margin-right:auto !important
  }

  .mb-sm-auto,.my-sm-auto{
    margin-bottom:auto !important
  }

  .ml-sm-auto,.mx-sm-auto{
    margin-left:auto !important
  }
}

@media (min-width: 768px){
  .m-md-0{
    margin:0 !important
  }

  .mt-md-0,.my-md-0{
    margin-top:0 !important
  }

  .mr-md-0,.mx-md-0{
    margin-right:0 !important
  }

  .mb-md-0,.my-md-0{
    margin-bottom:0 !important
  }

  .ml-md-0,.mx-md-0{
    margin-left:0 !important
  }

  .m-md-1{
    margin:.25rem !important
  }

  .mt-md-1,.my-md-1{
    margin-top:.25rem !important
  }

  .mr-md-1,.mx-md-1{
    margin-right:.25rem !important
  }

  .mb-md-1,.my-md-1{
    margin-bottom:.25rem !important
  }

  .ml-md-1,.mx-md-1{
    margin-left:.25rem !important
  }

  .m-md-2{
    margin:.5rem !important
  }

  .mt-md-2,.my-md-2{
    margin-top:.5rem !important
  }

  .mr-md-2,.mx-md-2{
    margin-right:.5rem !important
  }

  .mb-md-2,.my-md-2{
    margin-bottom:.5rem !important
  }

  .ml-md-2,.mx-md-2{
    margin-left:.5rem !important
  }

  .m-md-3{
    margin:1rem !important
  }

  .mt-md-3,.my-md-3{
    margin-top:1rem !important
  }

  .mr-md-3,.mx-md-3{
    margin-right:1rem !important
  }

  .mb-md-3,.my-md-3{
    margin-bottom:1rem !important
  }

  .ml-md-3,.mx-md-3{
    margin-left:1rem !important
  }

  .m-md-4{
    margin:1.5rem !important
  }

  .mt-md-4,.my-md-4{
    margin-top:1.5rem !important
  }

  .mr-md-4,.mx-md-4{
    margin-right:1.5rem !important
  }

  .mb-md-4,.my-md-4{
    margin-bottom:1.5rem !important
  }

  .ml-md-4,.mx-md-4{
    margin-left:1.5rem !important
  }

  .m-md-5{
    margin:3rem !important
  }

  .mt-md-5,.my-md-5{
    margin-top:3rem !important
  }

  .mr-md-5,.mx-md-5{
    margin-right:3rem !important
  }

  .mb-md-5,.my-md-5{
    margin-bottom:3rem !important
  }

  .ml-md-5,.mx-md-5{
    margin-left:3rem !important
  }

  .p-md-0{
    padding:0 !important
  }

  .pt-md-0,.py-md-0{
    padding-top:0 !important
  }

  .pr-md-0,.px-md-0{
    padding-right:0 !important
  }

  .pb-md-0,.py-md-0{
    padding-bottom:0 !important
  }

  .pl-md-0,.px-md-0{
    padding-left:0 !important
  }

  .p-md-1{
    padding:.25rem !important
  }

  .pt-md-1,.py-md-1{
    padding-top:.25rem !important
  }

  .pr-md-1,.px-md-1{
    padding-right:.25rem !important
  }

  .pb-md-1,.py-md-1{
    padding-bottom:.25rem !important
  }

  .pl-md-1,.px-md-1{
    padding-left:.25rem !important
  }

  .p-md-2{
    padding:.5rem !important
  }

  .pt-md-2,.py-md-2{
    padding-top:.5rem !important
  }

  .pr-md-2,.px-md-2{
    padding-right:.5rem !important
  }

  .pb-md-2,.py-md-2{
    padding-bottom:.5rem !important
  }

  .pl-md-2,.px-md-2{
    padding-left:.5rem !important
  }

  .p-md-3{
    padding:1rem !important
  }

  .pt-md-3,.py-md-3{
    padding-top:1rem !important
  }

  .pr-md-3,.px-md-3{
    padding-right:1rem !important
  }

  .pb-md-3,.py-md-3{
    padding-bottom:1rem !important
  }

  .pl-md-3,.px-md-3{
    padding-left:1rem !important
  }

  .p-md-4{
    padding:1.5rem !important
  }

  .pt-md-4,.py-md-4{
    padding-top:1.5rem !important
  }

  .pr-md-4,.px-md-4{
    padding-right:1.5rem !important
  }

  .pb-md-4,.py-md-4{
    padding-bottom:1.5rem !important
  }

  .pl-md-4,.px-md-4{
    padding-left:1.5rem !important
  }

  .p-md-5{
    padding:3rem !important
  }

  .pt-md-5,.py-md-5{
    padding-top:3rem !important
  }

  .pr-md-5,.px-md-5{
    padding-right:3rem !important
  }

  .pb-md-5,.py-md-5{
    padding-bottom:3rem !important
  }

  .pl-md-5,.px-md-5{
    padding-left:3rem !important
  }

  .m-md-n1{
    margin:-.25rem !important
  }

  .mt-md-n1,.my-md-n1{
    margin-top:-.25rem !important
  }

  .mr-md-n1,.mx-md-n1{
    margin-right:-.25rem !important
  }

  .mb-md-n1,.my-md-n1{
    margin-bottom:-.25rem !important
  }

  .ml-md-n1,.mx-md-n1{
    margin-left:-.25rem !important
  }

  .m-md-n2{
    margin:-.5rem !important
  }

  .mt-md-n2,.my-md-n2{
    margin-top:-.5rem !important
  }

  .mr-md-n2,.mx-md-n2{
    margin-right:-.5rem !important
  }

  .mb-md-n2,.my-md-n2{
    margin-bottom:-.5rem !important
  }

  .ml-md-n2,.mx-md-n2{
    margin-left:-.5rem !important
  }

  .m-md-n3{
    margin:-1rem !important
  }

  .mt-md-n3,.my-md-n3{
    margin-top:-1rem !important
  }

  .mr-md-n3,.mx-md-n3{
    margin-right:-1rem !important
  }

  .mb-md-n3,.my-md-n3{
    margin-bottom:-1rem !important
  }

  .ml-md-n3,.mx-md-n3{
    margin-left:-1rem !important
  }

  .m-md-n4{
    margin:-1.5rem !important
  }

  .mt-md-n4,.my-md-n4{
    margin-top:-1.5rem !important
  }

  .mr-md-n4,.mx-md-n4{
    margin-right:-1.5rem !important
  }

  .mb-md-n4,.my-md-n4{
    margin-bottom:-1.5rem !important
  }

  .ml-md-n4,.mx-md-n4{
    margin-left:-1.5rem !important
  }

  .m-md-n5{
    margin:-3rem !important
  }

  .mt-md-n5,.my-md-n5{
    margin-top:-3rem !important
  }

  .mr-md-n5,.mx-md-n5{
    margin-right:-3rem !important
  }

  .mb-md-n5,.my-md-n5{
    margin-bottom:-3rem !important
  }

  .ml-md-n5,.mx-md-n5{
    margin-left:-3rem !important
  }

  .m-md-auto{
    margin:auto !important
  }

  .mt-md-auto,.my-md-auto{
    margin-top:auto !important
  }

  .mr-md-auto,.mx-md-auto{
    margin-right:auto !important
  }

  .mb-md-auto,.my-md-auto{
    margin-bottom:auto !important
  }

  .ml-md-auto,.mx-md-auto{
    margin-left:auto !important
  }
}

@media (min-width: 992px){
  .m-lg-0{
    margin:0 !important
  }

  .mt-lg-0,.my-lg-0{
    margin-top:0 !important
  }

  .mr-lg-0,.mx-lg-0{
    margin-right:0 !important
  }

  .mb-lg-0,.my-lg-0{
    margin-bottom:0 !important
  }

  .ml-lg-0,.mx-lg-0{
    margin-left:0 !important
  }

  .m-lg-1{
    margin:.25rem !important
  }

  .mt-lg-1,.my-lg-1{
    margin-top:.25rem !important
  }

  .mr-lg-1,.mx-lg-1{
    margin-right:.25rem !important
  }

  .mb-lg-1,.my-lg-1{
    margin-bottom:.25rem !important
  }

  .ml-lg-1,.mx-lg-1{
    margin-left:.25rem !important
  }

  .m-lg-2{
    margin:.5rem !important
  }

  .mt-lg-2,.my-lg-2{
    margin-top:.5rem !important
  }

  .mr-lg-2,.mx-lg-2{
    margin-right:.5rem !important
  }

  .mb-lg-2,.my-lg-2{
    margin-bottom:.5rem !important
  }

  .ml-lg-2,.mx-lg-2{
    margin-left:.5rem !important
  }

  .m-lg-3{
    margin:1rem !important
  }

  .mt-lg-3,.my-lg-3{
    margin-top:1rem !important
  }

  .mr-lg-3,.mx-lg-3{
    margin-right:1rem !important
  }

  .mb-lg-3,.my-lg-3{
    margin-bottom:1rem !important
  }

  .ml-lg-3,.mx-lg-3{
    margin-left:1rem !important
  }

  .m-lg-4{
    margin:1.5rem !important
  }

  .mt-lg-4,.my-lg-4{
    margin-top:1.5rem !important
  }

  .mr-lg-4,.mx-lg-4{
    margin-right:1.5rem !important
  }

  .mb-lg-4,.my-lg-4{
    margin-bottom:1.5rem !important
  }

  .ml-lg-4,.mx-lg-4{
    margin-left:1.5rem !important
  }

  .m-lg-5{
    margin:3rem !important
  }

  .mt-lg-5,.my-lg-5{
    margin-top:3rem !important
  }

  .mr-lg-5,.mx-lg-5{
    margin-right:3rem !important
  }

  .mb-lg-5,.my-lg-5{
    margin-bottom:3rem !important
  }

  .ml-lg-5,.mx-lg-5{
    margin-left:3rem !important
  }

  .p-lg-0{
    padding:0 !important
  }

  .pt-lg-0,.py-lg-0{
    padding-top:0 !important
  }

  .pr-lg-0,.px-lg-0{
    padding-right:0 !important
  }

  .pb-lg-0,.py-lg-0{
    padding-bottom:0 !important
  }

  .pl-lg-0,.px-lg-0{
    padding-left:0 !important
  }

  .p-lg-1{
    padding:.25rem !important
  }

  .pt-lg-1,.py-lg-1{
    padding-top:.25rem !important
  }

  .pr-lg-1,.px-lg-1{
    padding-right:.25rem !important
  }

  .pb-lg-1,.py-lg-1{
    padding-bottom:.25rem !important
  }

  .pl-lg-1,.px-lg-1{
    padding-left:.25rem !important
  }

  .p-lg-2{
    padding:.5rem !important
  }

  .pt-lg-2,.py-lg-2{
    padding-top:.5rem !important
  }

  .pr-lg-2,.px-lg-2{
    padding-right:.5rem !important
  }

  .pb-lg-2,.py-lg-2{
    padding-bottom:.5rem !important
  }

  .pl-lg-2,.px-lg-2{
    padding-left:.5rem !important
  }

  .p-lg-3{
    padding:1rem !important
  }

  .pt-lg-3,.py-lg-3{
    padding-top:1rem !important
  }

  .pr-lg-3,.px-lg-3{
    padding-right:1rem !important
  }

  .pb-lg-3,.py-lg-3{
    padding-bottom:1rem !important
  }

  .pl-lg-3,.px-lg-3{
    padding-left:1rem !important
  }

  .p-lg-4{
    padding:1.5rem !important
  }

  .pt-lg-4,.py-lg-4{
    padding-top:1.5rem !important
  }

  .pr-lg-4,.px-lg-4{
    padding-right:1.5rem !important
  }

  .pb-lg-4,.py-lg-4{
    padding-bottom:1.5rem !important
  }

  .pl-lg-4,.px-lg-4{
    padding-left:1.5rem !important
  }

  .p-lg-5{
    padding:3rem !important
  }

  .pt-lg-5,.py-lg-5{
    padding-top:3rem !important
  }

  .pr-lg-5,.px-lg-5{
    padding-right:3rem !important
  }

  .pb-lg-5,.py-lg-5{
    padding-bottom:3rem !important
  }

  .pl-lg-5,.px-lg-5{
    padding-left:3rem !important
  }

  .m-lg-n1{
    margin:-.25rem !important
  }

  .mt-lg-n1,.my-lg-n1{
    margin-top:-.25rem !important
  }

  .mr-lg-n1,.mx-lg-n1{
    margin-right:-.25rem !important
  }

  .mb-lg-n1,.my-lg-n1{
    margin-bottom:-.25rem !important
  }

  .ml-lg-n1,.mx-lg-n1{
    margin-left:-.25rem !important
  }

  .m-lg-n2{
    margin:-.5rem !important
  }

  .mt-lg-n2,.my-lg-n2{
    margin-top:-.5rem !important
  }

  .mr-lg-n2,.mx-lg-n2{
    margin-right:-.5rem !important
  }

  .mb-lg-n2,.my-lg-n2{
    margin-bottom:-.5rem !important
  }

  .ml-lg-n2,.mx-lg-n2{
    margin-left:-.5rem !important
  }

  .m-lg-n3{
    margin:-1rem !important
  }

  .mt-lg-n3,.my-lg-n3{
    margin-top:-1rem !important
  }

  .mr-lg-n3,.mx-lg-n3{
    margin-right:-1rem !important
  }

  .mb-lg-n3,.my-lg-n3{
    margin-bottom:-1rem !important
  }

  .ml-lg-n3,.mx-lg-n3{
    margin-left:-1rem !important
  }

  .m-lg-n4{
    margin:-1.5rem !important
  }

  .mt-lg-n4,.my-lg-n4{
    margin-top:-1.5rem !important
  }

  .mr-lg-n4,.mx-lg-n4{
    margin-right:-1.5rem !important
  }

  .mb-lg-n4,.my-lg-n4{
    margin-bottom:-1.5rem !important
  }

  .ml-lg-n4,.mx-lg-n4{
    margin-left:-1.5rem !important
  }

  .m-lg-n5{
    margin:-3rem !important
  }

  .mt-lg-n5,.my-lg-n5{
    margin-top:-3rem !important
  }

  .mr-lg-n5,.mx-lg-n5{
    margin-right:-3rem !important
  }

  .mb-lg-n5,.my-lg-n5{
    margin-bottom:-3rem !important
  }

  .ml-lg-n5,.mx-lg-n5{
    margin-left:-3rem !important
  }

  .m-lg-auto{
    margin:auto !important
  }

  .mt-lg-auto,.my-lg-auto{
    margin-top:auto !important
  }

  .mr-lg-auto,.mx-lg-auto{
    margin-right:auto !important
  }

  .mb-lg-auto,.my-lg-auto{
    margin-bottom:auto !important
  }

  .ml-lg-auto,.mx-lg-auto{
    margin-left:auto !important
  }
}

@media (min-width: 1200px){
  .m-xl-0{
    margin:0 !important
  }

  .mt-xl-0,.my-xl-0{
    margin-top:0 !important
  }

  .mr-xl-0,.mx-xl-0{
    margin-right:0 !important
  }

  .mb-xl-0,.my-xl-0{
    margin-bottom:0 !important
  }

  .ml-xl-0,.mx-xl-0{
    margin-left:0 !important
  }

  .m-xl-1{
    margin:.25rem !important
  }

  .mt-xl-1,.my-xl-1{
    margin-top:.25rem !important
  }

  .mr-xl-1,.mx-xl-1{
    margin-right:.25rem !important
  }

  .mb-xl-1,.my-xl-1{
    margin-bottom:.25rem !important
  }

  .ml-xl-1,.mx-xl-1{
    margin-left:.25rem !important
  }

  .m-xl-2{
    margin:.5rem !important
  }

  .mt-xl-2,.my-xl-2{
    margin-top:.5rem !important
  }

  .mr-xl-2,.mx-xl-2{
    margin-right:.5rem !important
  }

  .mb-xl-2,.my-xl-2{
    margin-bottom:.5rem !important
  }

  .ml-xl-2,.mx-xl-2{
    margin-left:.5rem !important
  }

  .m-xl-3{
    margin:1rem !important
  }

  .mt-xl-3,.my-xl-3{
    margin-top:1rem !important
  }

  .mr-xl-3,.mx-xl-3{
    margin-right:1rem !important
  }

  .mb-xl-3,.my-xl-3{
    margin-bottom:1rem !important
  }

  .ml-xl-3,.mx-xl-3{
    margin-left:1rem !important
  }

  .m-xl-4{
    margin:1.5rem !important
  }

  .mt-xl-4,.my-xl-4{
    margin-top:1.5rem !important
  }

  .mr-xl-4,.mx-xl-4{
    margin-right:1.5rem !important
  }

  .mb-xl-4,.my-xl-4{
    margin-bottom:1.5rem !important
  }

  .ml-xl-4,.mx-xl-4{
    margin-left:1.5rem !important
  }

  .m-xl-5{
    margin:3rem !important
  }

  .mt-xl-5,.my-xl-5{
    margin-top:3rem !important
  }

  .mr-xl-5,.mx-xl-5{
    margin-right:3rem !important
  }

  .mb-xl-5,.my-xl-5{
    margin-bottom:3rem !important
  }

  .ml-xl-5,.mx-xl-5{
    margin-left:3rem !important
  }

  .p-xl-0{
    padding:0 !important
  }

  .pt-xl-0,.py-xl-0{
    padding-top:0 !important
  }

  .pr-xl-0,.px-xl-0{
    padding-right:0 !important
  }

  .pb-xl-0,.py-xl-0{
    padding-bottom:0 !important
  }

  .pl-xl-0,.px-xl-0{
    padding-left:0 !important
  }

  .p-xl-1{
    padding:.25rem !important
  }

  .pt-xl-1,.py-xl-1{
    padding-top:.25rem !important
  }

  .pr-xl-1,.px-xl-1{
    padding-right:.25rem !important
  }

  .pb-xl-1,.py-xl-1{
    padding-bottom:.25rem !important
  }

  .pl-xl-1,.px-xl-1{
    padding-left:.25rem !important
  }

  .p-xl-2{
    padding:.5rem !important
  }

  .pt-xl-2,.py-xl-2{
    padding-top:.5rem !important
  }

  .pr-xl-2,.px-xl-2{
    padding-right:.5rem !important
  }

  .pb-xl-2,.py-xl-2{
    padding-bottom:.5rem !important
  }

  .pl-xl-2,.px-xl-2{
    padding-left:.5rem !important
  }

  .p-xl-3{
    padding:1rem !important
  }

  .pt-xl-3,.py-xl-3{
    padding-top:1rem !important
  }

  .pr-xl-3,.px-xl-3{
    padding-right:1rem !important
  }

  .pb-xl-3,.py-xl-3{
    padding-bottom:1rem !important
  }

  .pl-xl-3,.px-xl-3{
    padding-left:1rem !important
  }

  .p-xl-4{
    padding:1.5rem !important
  }

  .pt-xl-4,.py-xl-4{
    padding-top:1.5rem !important
  }

  .pr-xl-4,.px-xl-4{
    padding-right:1.5rem !important
  }

  .pb-xl-4,.py-xl-4{
    padding-bottom:1.5rem !important
  }

  .pl-xl-4,.px-xl-4{
    padding-left:1.5rem !important
  }

  .p-xl-5{
    padding:3rem !important
  }

  .pt-xl-5,.py-xl-5{
    padding-top:3rem !important
  }

  .pr-xl-5,.px-xl-5{
    padding-right:3rem !important
  }

  .pb-xl-5,.py-xl-5{
    padding-bottom:3rem !important
  }

  .pl-xl-5,.px-xl-5{
    padding-left:3rem !important
  }

  .m-xl-n1{
    margin:-.25rem !important
  }

  .mt-xl-n1,.my-xl-n1{
    margin-top:-.25rem !important
  }

  .mr-xl-n1,.mx-xl-n1{
    margin-right:-.25rem !important
  }

  .mb-xl-n1,.my-xl-n1{
    margin-bottom:-.25rem !important
  }

  .ml-xl-n1,.mx-xl-n1{
    margin-left:-.25rem !important
  }

  .m-xl-n2{
    margin:-.5rem !important
  }

  .mt-xl-n2,.my-xl-n2{
    margin-top:-.5rem !important
  }

  .mr-xl-n2,.mx-xl-n2{
    margin-right:-.5rem !important
  }

  .mb-xl-n2,.my-xl-n2{
    margin-bottom:-.5rem !important
  }

  .ml-xl-n2,.mx-xl-n2{
    margin-left:-.5rem !important
  }

  .m-xl-n3{
    margin:-1rem !important
  }

  .mt-xl-n3,.my-xl-n3{
    margin-top:-1rem !important
  }

  .mr-xl-n3,.mx-xl-n3{
    margin-right:-1rem !important
  }

  .mb-xl-n3,.my-xl-n3{
    margin-bottom:-1rem !important
  }

  .ml-xl-n3,.mx-xl-n3{
    margin-left:-1rem !important
  }

  .m-xl-n4{
    margin:-1.5rem !important
  }

  .mt-xl-n4,.my-xl-n4{
    margin-top:-1.5rem !important
  }

  .mr-xl-n4,.mx-xl-n4{
    margin-right:-1.5rem !important
  }

  .mb-xl-n4,.my-xl-n4{
    margin-bottom:-1.5rem !important
  }

  .ml-xl-n4,.mx-xl-n4{
    margin-left:-1.5rem !important
  }

  .m-xl-n5{
    margin:-3rem !important
  }

  .mt-xl-n5,.my-xl-n5{
    margin-top:-3rem !important
  }

  .mr-xl-n5,.mx-xl-n5{
    margin-right:-3rem !important
  }

  .mb-xl-n5,.my-xl-n5{
    margin-bottom:-3rem !important
  }

  .ml-xl-n5,.mx-xl-n5{
    margin-left:-3rem !important
  }

  .m-xl-auto{
    margin:auto !important
  }

  .mt-xl-auto,.my-xl-auto{
    margin-top:auto !important
  }

  .mr-xl-auto,.mx-xl-auto{
    margin-right:auto !important
  }

  .mb-xl-auto,.my-xl-auto{
    margin-bottom:auto !important
  }

  .ml-xl-auto,.mx-xl-auto{
    margin-left:auto !important
  }
}

@media (min-width: 1600px){
  .m-xxl-0{
    margin:0 !important
  }

  .mt-xxl-0,.my-xxl-0{
    margin-top:0 !important
  }

  .mr-xxl-0,.mx-xxl-0{
    margin-right:0 !important
  }

  .mb-xxl-0,.my-xxl-0{
    margin-bottom:0 !important
  }

  .ml-xxl-0,.mx-xxl-0{
    margin-left:0 !important
  }

  .m-xxl-1{
    margin:.25rem !important
  }

  .mt-xxl-1,.my-xxl-1{
    margin-top:.25rem !important
  }

  .mr-xxl-1,.mx-xxl-1{
    margin-right:.25rem !important
  }

  .mb-xxl-1,.my-xxl-1{
    margin-bottom:.25rem !important
  }

  .ml-xxl-1,.mx-xxl-1{
    margin-left:.25rem !important
  }

  .m-xxl-2{
    margin:.5rem !important
  }

  .mt-xxl-2,.my-xxl-2{
    margin-top:.5rem !important
  }

  .mr-xxl-2,.mx-xxl-2{
    margin-right:.5rem !important
  }

  .mb-xxl-2,.my-xxl-2{
    margin-bottom:.5rem !important
  }

  .ml-xxl-2,.mx-xxl-2{
    margin-left:.5rem !important
  }

  .m-xxl-3{
    margin:1rem !important
  }

  .mt-xxl-3,.my-xxl-3{
    margin-top:1rem !important
  }

  .mr-xxl-3,.mx-xxl-3{
    margin-right:1rem !important
  }

  .mb-xxl-3,.my-xxl-3{
    margin-bottom:1rem !important
  }

  .ml-xxl-3,.mx-xxl-3{
    margin-left:1rem !important
  }

  .m-xxl-4{
    margin:1.5rem !important
  }

  .mt-xxl-4,.my-xxl-4{
    margin-top:1.5rem !important
  }

  .mr-xxl-4,.mx-xxl-4{
    margin-right:1.5rem !important
  }

  .mb-xxl-4,.my-xxl-4{
    margin-bottom:1.5rem !important
  }

  .ml-xxl-4,.mx-xxl-4{
    margin-left:1.5rem !important
  }

  .m-xxl-5{
    margin:3rem !important
  }

  .mt-xxl-5,.my-xxl-5{
    margin-top:3rem !important
  }

  .mr-xxl-5,.mx-xxl-5{
    margin-right:3rem !important
  }

  .mb-xxl-5,.my-xxl-5{
    margin-bottom:3rem !important
  }

  .ml-xxl-5,.mx-xxl-5{
    margin-left:3rem !important
  }

  .p-xxl-0{
    padding:0 !important
  }

  .pt-xxl-0,.py-xxl-0{
    padding-top:0 !important
  }

  .pr-xxl-0,.px-xxl-0{
    padding-right:0 !important
  }

  .pb-xxl-0,.py-xxl-0{
    padding-bottom:0 !important
  }

  .pl-xxl-0,.px-xxl-0{
    padding-left:0 !important
  }

  .p-xxl-1{
    padding:.25rem !important
  }

  .pt-xxl-1,.py-xxl-1{
    padding-top:.25rem !important
  }

  .pr-xxl-1,.px-xxl-1{
    padding-right:.25rem !important
  }

  .pb-xxl-1,.py-xxl-1{
    padding-bottom:.25rem !important
  }

  .pl-xxl-1,.px-xxl-1{
    padding-left:.25rem !important
  }

  .p-xxl-2{
    padding:.5rem !important
  }

  .pt-xxl-2,.py-xxl-2{
    padding-top:.5rem !important
  }

  .pr-xxl-2,.px-xxl-2{
    padding-right:.5rem !important
  }

  .pb-xxl-2,.py-xxl-2{
    padding-bottom:.5rem !important
  }

  .pl-xxl-2,.px-xxl-2{
    padding-left:.5rem !important
  }

  .p-xxl-3{
    padding:1rem !important
  }

  .pt-xxl-3,.py-xxl-3{
    padding-top:1rem !important
  }

  .pr-xxl-3,.px-xxl-3{
    padding-right:1rem !important
  }

  .pb-xxl-3,.py-xxl-3{
    padding-bottom:1rem !important
  }

  .pl-xxl-3,.px-xxl-3{
    padding-left:1rem !important
  }

  .p-xxl-4{
    padding:1.5rem !important
  }

  .pt-xxl-4,.py-xxl-4{
    padding-top:1.5rem !important
  }

  .pr-xxl-4,.px-xxl-4{
    padding-right:1.5rem !important
  }

  .pb-xxl-4,.py-xxl-4{
    padding-bottom:1.5rem !important
  }

  .pl-xxl-4,.px-xxl-4{
    padding-left:1.5rem !important
  }

  .p-xxl-5{
    padding:3rem !important
  }

  .pt-xxl-5,.py-xxl-5{
    padding-top:3rem !important
  }

  .pr-xxl-5,.px-xxl-5{
    padding-right:3rem !important
  }

  .pb-xxl-5,.py-xxl-5{
    padding-bottom:3rem !important
  }

  .pl-xxl-5,.px-xxl-5{
    padding-left:3rem !important
  }

  .m-xxl-n1{
    margin:-.25rem !important
  }

  .mt-xxl-n1,.my-xxl-n1{
    margin-top:-.25rem !important
  }

  .mr-xxl-n1,.mx-xxl-n1{
    margin-right:-.25rem !important
  }

  .mb-xxl-n1,.my-xxl-n1{
    margin-bottom:-.25rem !important
  }

  .ml-xxl-n1,.mx-xxl-n1{
    margin-left:-.25rem !important
  }

  .m-xxl-n2{
    margin:-.5rem !important
  }

  .mt-xxl-n2,.my-xxl-n2{
    margin-top:-.5rem !important
  }

  .mr-xxl-n2,.mx-xxl-n2{
    margin-right:-.5rem !important
  }

  .mb-xxl-n2,.my-xxl-n2{
    margin-bottom:-.5rem !important
  }

  .ml-xxl-n2,.mx-xxl-n2{
    margin-left:-.5rem !important
  }

  .m-xxl-n3{
    margin:-1rem !important
  }

  .mt-xxl-n3,.my-xxl-n3{
    margin-top:-1rem !important
  }

  .mr-xxl-n3,.mx-xxl-n3{
    margin-right:-1rem !important
  }

  .mb-xxl-n3,.my-xxl-n3{
    margin-bottom:-1rem !important
  }

  .ml-xxl-n3,.mx-xxl-n3{
    margin-left:-1rem !important
  }

  .m-xxl-n4{
    margin:-1.5rem !important
  }

  .mt-xxl-n4,.my-xxl-n4{
    margin-top:-1.5rem !important
  }

  .mr-xxl-n4,.mx-xxl-n4{
    margin-right:-1.5rem !important
  }

  .mb-xxl-n4,.my-xxl-n4{
    margin-bottom:-1.5rem !important
  }

  .ml-xxl-n4,.mx-xxl-n4{
    margin-left:-1.5rem !important
  }

  .m-xxl-n5{
    margin:-3rem !important
  }

  .mt-xxl-n5,.my-xxl-n5{
    margin-top:-3rem !important
  }

  .mr-xxl-n5,.mx-xxl-n5{
    margin-right:-3rem !important
  }

  .mb-xxl-n5,.my-xxl-n5{
    margin-bottom:-3rem !important
  }

  .ml-xxl-n5,.mx-xxl-n5{
    margin-left:-3rem !important
  }

  .m-xxl-auto{
    margin:auto !important
  }

  .mt-xxl-auto,.my-xxl-auto{
    margin-top:auto !important
  }

  .mr-xxl-auto,.mx-xxl-auto{
    margin-right:auto !important
  }

  .mb-xxl-auto,.my-xxl-auto{
    margin-bottom:auto !important
  }

  .ml-xxl-auto,.mx-xxl-auto{
    margin-left:auto !important
  }
}

.stretched-link::after{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:1;
  pointer-events:auto;
  content:"";
  background-color:rgba(0,0,0,0)
}

.text-monospace{
  font-family:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important
}

.text-justify{
  text-align:justify !important
}

.text-wrap{
  white-space:normal !important
}

.text-nowrap{
  white-space:nowrap !important
}

.text-truncate{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

.text-left{
  text-align:left !important
}

.text-right{
  text-align:right !important
}

.text-center{
  text-align:center !important
}

@media (min-width: 576px){
  .text-sm-left{
    text-align:left !important
  }

  .text-sm-right{
    text-align:right !important
  }

  .text-sm-center{
    text-align:center !important
  }
}

@media (min-width: 768px){
  .text-md-left{
    text-align:left !important
  }

  .text-md-right{
    text-align:right !important
  }

  .text-md-center{
    text-align:center !important
  }
}

@media (min-width: 992px){
  .text-lg-left{
    text-align:left !important
  }

  .text-lg-right{
    text-align:right !important
  }

  .text-lg-center{
    text-align:center !important
  }
}

@media (min-width: 1200px){
  .text-xl-left{
    text-align:left !important
  }

  .text-xl-right{
    text-align:right !important
  }

  .text-xl-center{
    text-align:center !important
  }
}

@media (min-width: 1600px){
  .text-xxl-left{
    text-align:left !important
  }

  .text-xxl-right{
    text-align:right !important
  }

  .text-xxl-center{
    text-align:center !important
  }
}

.text-lowercase{
  text-transform:lowercase !important
}

.text-uppercase{
  text-transform:uppercase !important
}

.text-capitalize{
  text-transform:capitalize !important
}

.font-weight-light{
  font-weight:300 !important
}

.font-weight-lighter{
  font-weight:lighter !important
}

.font-weight-normal{
  font-weight:400 !important
}

.font-weight-bold{
  font-weight:700 !important
}

.font-weight-bolder{
  font-weight:bolder !important
}

.font-italic{
  font-style:italic !important
}

.text-white{
  color:#fff !important
}

.text-primary{
  color:#0e76bc !important
}

a.text-primary:hover,a.text-primary:focus{
  color:#094975 !important
}

.text-secondary{
  color:#6c757d !important
}

a.text-secondary:hover,a.text-secondary:focus{
  color:#494f54 !important
}

.text-success{
  color:#28a745 !important
}

a.text-success:hover,a.text-success:focus{
  color:#19692c !important
}

.text-info{
  color:#17a2b8 !important
}

a.text-info:hover,a.text-info:focus{
  color:#0f6674 !important
}

.text-warning{
  color:#ffc107 !important
}

a.text-warning:hover,a.text-warning:focus{
  color:#ba8b00 !important
}

.text-danger{
  color:#dc3545 !important
}

a.text-danger:hover,a.text-danger:focus{
  color:#a71d2a !important
}

.text-light{
  color:#f8f9fa !important
}

a.text-light:hover,a.text-light:focus{
  color:#cbd3da !important
}

.text-dark{
  color:#343a40 !important
}

a.text-dark:hover,a.text-dark:focus{
  color:#121416 !important
}

.text-body{
  color:#212529 !important
}

.text-muted{
  color:#6c757d !important
}

.text-black-50{
  color:rgba(0,0,0,0.5) !important
}

.text-white-50{
  color:rgba(255,255,255,0.5) !important
}

.text-hide{
  font:0/0 a;
  color:transparent;
  text-shadow:none;
  background-color:transparent;
  border:0
}

.text-decoration-none{
  text-decoration:none !important
}

.text-break{
  word-break:break-word !important;
  word-wrap:break-word !important
}

.text-reset{
  color:inherit !important
}

.visible{
  visibility:visible !important
}

.invisible{
  visibility:hidden !important
}

@-webkit-keyframes modal-video{
  from{
    opacity:0
  }

  to{
    opacity:1
  }
}

@keyframes modal-video{
  from{
    opacity:0
  }

  to{
    opacity:1
  }
}

@-webkit-keyframes modal-video-inner{
  from{
    -webkit-transform:translate(0, 100px);
    transform:translate(0, 100px)
  }

  to{
    -webkit-transform:translate(0, 0);
    transform:translate(0, 0)
  }
}

@keyframes modal-video-inner{
  from{
    -webkit-transform:translate(0, 100px);
    transform:translate(0, 100px)
  }

  to{
    -webkit-transform:translate(0, 0);
    transform:translate(0, 0)
  }
}

.modal-video{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);
  z-index:1000000;
  cursor:pointer;
  opacity:1;
  -webkit-animation-timing-function:ease-out;
  animation-timing-function:ease-out;
  -webkit-animation-duration:.3s;
  animation-duration:.3s;
  -webkit-animation-name:modal-video;
  animation-name:modal-video;
  transition:opacity .3s ease-out
}

.modal-video-effect-exit{
  opacity:0
}

.modal-video-effect-exit .modal-video-movie-wrap{
  -webkit-transform:translate(0, 100px);
  transform:translate(0, 100px)
}

.modal-video-body{
  max-width:960px;
  width:100%;
  height:100%;
  margin:0 auto;
  padding:0 10px;
  display:table;
  box-sizing:border-box
}

.modal-video-inner{
  display:table-cell;
  vertical-align:middle;
  width:100%;
  height:100%
}

.modal-video-movie-wrap{
  width:100%;
  height:0;
  position:relative;
  padding-bottom:56.25%;
  background-color:#333;
  -webkit-animation-timing-function:ease-out;
  animation-timing-function:ease-out;
  -webkit-animation-duration:.3s;
  animation-duration:.3s;
  -webkit-animation-name:modal-video-inner;
  animation-name:modal-video-inner;
  -webkit-transform:translate(0, 0);
  transform:translate(0, 0);
  transition:-webkit-transform .3s ease-out;
  transition:transform .3s ease-out;
  transition:transform .3s ease-out, -webkit-transform .3s ease-out
}

.modal-video-movie-wrap iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%
}

.modal-video-close-btn{
  position:absolute;
  z-index:2;
  top:-45px;
  right:0px;
  display:inline-block;
  width:35px;
  height:35px;
  overflow:hidden;
  border:none;
  background:transparent
}

.modal-video-close-btn:before{
  -webkit-transform:rotate(45deg);
  transform:rotate(45deg)
}

.modal-video-close-btn:after{
  -webkit-transform:rotate(-45deg);
  transform:rotate(-45deg)
}

.modal-video-close-btn:before,.modal-video-close-btn:after{
  content:'';
  position:absolute;
  height:2px;
  width:100%;
  top:50%;
  left:0;
  margin-top:-1px;
  background:#fff;
  border-radius:5px;
  margin-top:-6px
}

.container{
  width: 100%
}

@media (min-width: 640px){
  .container{
    max-width: 640px
  }
}

@media (min-width: 768px){
  .container{
    max-width: 768px
  }
}

@media (min-width: 1024px){
  .container{
    max-width: 1024px
  }
}

@media (min-width: 1280px){
  .container{
    max-width: 1280px
  }
}

@media (min-width: 1536px){
  .container{
    max-width: 1536px
  }
}

.sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.bg-gray-600{
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity))
}

.bg-green-50{
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity))
}

.border-collapse{
  border-collapse: collapse
}

.border-gray-300{
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity))
}

.rounded-sm{
  border-radius: 0.125rem
}

.border-none{
  border-style: none
}

.border{
  border-width: 1px
}

.border-l{
  border-left-width: 1px
}

.block{
  display: block
}

.inline-block{
  display: inline-block
}

.inline{
  display: inline
}

.flex{
  display: -webkit-flex;
  display: flex
}

.table{
  display: table
}

.hidden{
  display: none
}

.items-center{
  -webkit-align-items: center;
          align-items: center
}

.justify-center{
  -webkit-justify-content: center;
          justify-content: center
}

.justify-between{
  -webkit-justify-content: space-between;
          justify-content: space-between
}

.font-bold{
  font-weight: 700
}

.text-xs{
  font-size: 0.75rem;
  line-height: 1rem
}

.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-3xl{
  font-size: 1.875rem;
  line-height: 2.25rem
}

.m-0{
  margin: 0px
}

.m-4{
  margin: 1rem
}

.m-8{
  margin: 2rem
}

.my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-4{
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-4{
  margin-left: 1rem;
  margin-right: 1rem
}

.my-6{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.mx-8{
  margin-left: 2rem;
  margin-right: 2rem
}

.my-32{
  margin-top: 8rem;
  margin-bottom: 8rem
}

.mr-0{
  margin-right: 0px
}

.ml-0{
  margin-left: 0px
}

.mr-1{
  margin-right: 0.25rem
}

.ml-1{
  margin-left: 0.25rem
}

.mb-2{
  margin-bottom: 0.5rem
}

.ml-2{
  margin-left: 0.5rem
}

.ml-4{
  margin-left: 1rem
}

.mt-6{
  margin-top: 1.5rem
}

.mt-8{
  margin-top: 2rem
}

.mb-8{
  margin-bottom: 2rem
}

.mr-0\.5{
  margin-right: 0.125rem
}

.ml-0\.5{
  margin-left: 0.125rem
}

.mr-1\.5{
  margin-right: 0.375rem
}

.ml-1\.5{
  margin-left: 0.375rem
}

.max-w-md{
  max-width: 28rem
}

.max-w-full{
  max-width: 100%
}

.p-2{
  padding: 0.5rem
}

.py-0{
  padding-top: 0px;
  padding-bottom: 0px
}

.px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.pr-8{
  padding-right: 2rem
}

.pt-24{
  padding-top: 6rem
}

.static{
  position: static
}

.fixed{
  position: fixed
}

.absolute{
  position: absolute
}

.relative{
  position: relative
}

.top-1{
  top: 0.25rem
}

.right-1{
  right: 0.25rem
}

.resize{
  resize: both
}

*{
  --tw-shadow: 0 0 #0000
}

*{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.text-left{
  text-align: left
}

.text-center{
  text-align: center
}

.text-white{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-gray-700{
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity))
}

.text-red-500{
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity))
}

.text-red-600{
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity))
}

.text-green-600{
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity))
}

.text-green-700{
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity))
}

.italic{
  font-style: italic
}

.uppercase{
  text-transform: uppercase
}

.capitalize{
  text-transform: capitalize
}

.line-through{
  text-decoration: line-through
}

.antialiased{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.visible{
  visibility: visible
}

.w-full{
  width: 100%
}

.transform{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transition{
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

@-webkit-keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@-webkit-keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse{
  50%{
    opacity: .5
  }
}

@keyframes pulse{
  50%{
    opacity: .5
  }
}

@-webkit-keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.filter{
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.blur{
  --tw-blur: blur(8px)
}

html{
  font-size:16px
}

@media (min-width: 992px){
  html{
    font-size:21px
  }
}

body{
  min-height:100vh;
  font-weight:300;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  color:#413e3d
}

hr{
  border-top:2px solid #0e76bc
}

b,strong{
  font-weight:700
}

a{
  color:#0e76bc;
  transition:all 0.3s ease-in-out
}

a:hover,a:focus,a:active{
  color:#8dc63f
}

h1,h2,h3,h4,h5{
  font-weight:300;
  line-height:1.2;
  margin:0.5em 0 0.3em
}

h1,h2,h3{
  font-family:"neo-sans",serif
}

h1 b,h1 strong,h2 b,h2 strong,h3 b,h3 strong{
  color:#0e76bc
}

h4{
  letter-spacing:1px
}

h1{
  font-size:2.25rem
}

h2{
  font-size:1.75rem
}

h3{
  font-size:1.5rem
}

h4{
  font-size:1.25rem
}

h5{
  font-size:.875rem
}

@media (min-width: 992px){
  h1{
    font-size:2.85714rem
  }

  h2{
    font-size:2.28571rem
  }

  h3{
    font-size:1.71429rem
  }

  h4{
    font-size:1.42857rem
  }

  h5{
    font-size:.7619rem
  }
}

.text-tiny{
  font-size:0.75rem
}

.text-big{
  font-size:1.5rem
}

.htmlPanel{
  max-width:960px;
  margin:auto
}

div.imagePanel{
  position:relative
}

div.imagePanel .htmlPanel{
  position:absolute;
  width:100%;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%)
}

div.imagePanel .htmlPanel img{
  max-width:32%
}

@media (min-width: 456px){
  div.imagePanel .htmlPanel img{
    max-width:26%
  }
}

@media (min-width: 576px){
  div.imagePanel .htmlPanel img{
    max-width:18%
  }
}

@media (min-width: 768px){
  div.imagePanel .htmlPanel img{
    max-width:17%
  }
}

@media (min-width: 992px){
  div.imagePanel .htmlPanel img{
    max-width:20%
  }
}

@media (min-width: 1601px){
  div.imagePanel .htmlPanel img{
    max-width:100%
  }
}

.contentPanel{
  background:white;
  padding:1rem 0;
  position:relative;
  word-break:break-word;
  background-size:100%;
  background-repeat:repeat;
  background-position:center
}

.contentPanel>*{
  z-index:1;
  position:relative
}

.contentPanel h2 a.button{
  font-size:2.6rem
}

.contentPanel h3 a.button{
  font-size:2.2rem
}

.contentPanel h4 a.button{
  font-size:1.2rem
}

.contentPanel img{
  max-width:100%;
  height:auto;
  display:inline-block
}

.contentPanel.commonFooter:before,.contentPanel.bottomPanel:before{
  display:none
}

.contentPanel a.button{
  color:white;
  background:#0e76bc;
  padding:0.2rem 1rem;
  font-weight:700
}

.contentPanel a.button:focus,.contentPanel a.button:hover{
  background:#8dc63f;
  text-decoration:none
}

.contentPanel--dark{
  background:linear-gradient(#000, rgba(0,0,0,0.7));
  color:white
}

.contentPanel__bg{
  position:absolute;
  overflow:hidden;
  top:0;
  bottom:0;
  left:0;
  right:0
}

.contentPanel--dark .contentPanel__bg:before{
  background:rgba(255,255,255,0.05)
}

@media (min-width: 1200px){
  .container{
    max-width:1140px
  }
}

.parallax{
  background-position-x:center
}

.error-panel{
  background:white;
  padding:3rem 0
}

.error{
  margin:1rem 0;
  color:red
}

iframe{
  max-width:100%
}

.cmsPage--BlogPost .contentPanel:not(.blogCommonBottom):not(.bottomPanel) .htmlPanel{
  text-align:left
}

.cmsPage--BlogPost .contentPanel:not(.blogCommonBottom):not(.bottomPanel) .htmlPanel .empty-line{
  height:0.5rem
}

.cmsPage--BlogPost .contentPanel:not(.blogCommonBottom):not(.bottomPanel) .htmlPanel h2{
  font-size:2rem
}

@media (min-width: 992px){
  .cmsPage--BlogPost .contentPanel:not(.blogCommonBottom):not(.bottomPanel) .htmlPanel h2{
    font-size:2.6rem
  }
}

.cmsPage--BlogPost .bottomPanel .htmlPanel{
  text-align:center
}

.cmsPage--BlogPost .instagram-media{
  max-width:720px !important
}

.cmsPage--BlogPost .element-invisible{
  display:none
}

.actions{
  margin:1rem 0;
  text-align:center;
  border-radius:0
}

.actions button{
  margin-right:0.7rem;
  border-radius:0px;
  background-color:#0e76bc;
  border-color:#0e76bc;
  color:white;
  font-weight:700
}

.actions button:hover{
  background-color:#8dc63f;
  border-color:#8dc63f
}

.invalid{
  font-size:85%;
  color:red
}

.was-validated .form-control:invalid,.form-control.is-invalid{
  padding-right:0.75rem
}

.editor{
  max-width:1600px
}

.editor .label{
  margin-bottom:0.1rem;
  font-size:0.75rem;
  color:#999
}

.editor .form-group{
  margin-bottom:0.3rem
}

.editor .actions{
  margin:1.5rem 0
}

.editor .actions button,.editor .actions a{
  margin-right:0.9rem
}

.htmlEditor textarea.form-control{
  max-height:none;
  min-height:300px
}

.htmlEditor__panel{
  display:none
}

.htmlEditor__panel.active{
  display:block
}

.htmlEditor__view{
  margin-top:3px;
  text-align:right
}

.htmlEditor__view button{
  font-size:0.75rem
}

.formStep{
  list-style:none;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:space-evenly;
  justify-content:space-evenly
}

.formStep__step{
  list-style:none;
  padding:0.5rem 1rem;
  display:inline-block
}

.formStep__step--active{
  font-weight:bold;
  border-bottom:solid 3px #faa222
}

.navBar{
  position:fixed;
  top:44px;
  width:90%;
  max-width:400px;
  height:auto;
  background-color:rgba(0,0,0,0.8);
  color:white;
  z-index:999;
  opacity:0.3;
  transition:all 0.3s ease-in-out;
  -webkit-transform:translateX(100%);
  transform:translateX(100%);
  right:0
}

@media (min-width: 768px){
  .navBar{
    top:56px
  }
}

@media (min-width: 992px){
  .navBar{
    top:56px
  }
}

.scrolled .navBar,.subpage .navBar{
  top:44px
}

@media (min-width: 992px){
  .scrolled .navBar,.subpage .navBar{
    top:56px
  }
}

.navBar--on{
  -webkit-transform:translateX(0);
  transform:translateX(0);
  opacity:1
}

.navBar_nav{
  margin:0
}

.navBar_nav ul,.navBar_nav li{
  margin:0;
  padding:0;
  display:block;
  list-style:none
}

.navBar_nav li{
  position:relative;
  margin:0.5rem 0
}

.navBar_nav a{
  display:block;
  color:white;
  padding:0.5rem 1rem;
  font-size:1rem;
  line-height:1;
  transition-duration:0.3s;
  text-transform:uppercase;
  background-color:#0e76bc
}

.navBar_nav a:hover,.navBar_nav a:focus{
  text-decoration:none;
  color:white;
  background-color:#8dc63f
}

.navBar__item--open .navBar__subMenu{
  max-height:800px;
  transition:all 0.9s
}

.navBar__item--open .navBar__icon{
  -webkit-transform:rotate(-180deg);
  transform:rotate(-180deg)
}

a.navBar__link{
  font-weight:bold;
  padding-left:1.2rem
}

@media (min-width: 992px){
  a.navBar__link{
    padding-left:2rem
  }
}

.navBar__icon{
  position:absolute;
  top:0;
  right:0;
  transition:-webkit-transform 0.5s ease;
  transition:transform 0.5s ease;
  transition:transform 0.5s ease, -webkit-transform 0.5s ease;
  padding:0.5rem 0.7rem
}

.navBar__subMenu{
  transition:all 0.4s;
  max-height:0;
  overflow:hidden;
  display:list-item
}

.navBar__subItem:nth-last-child(1){
  margin-bottom:0
}

a.navBar__subLink{
  padding-left:2.5rem;
  position:relative
}

@media (min-width: 992px){
  a.navBar__subLink{
    padding-left:3.5rem
  }
}

a.navBar__subLink:before{
  content:'• ';
  position:absolute;
  left:1.5rem
}

@media (min-width: 992px){
  a.navBar__subLink:before{
    left:2rem
  }
}

.navBar__scrollArea{
  overflow-y:auto;
  overflow-x:hidden;
  max-height:calc(100vh - 170px)
}

@media (min-width: 992px){
  .navBar__scrollArea{
    max-height:calc(100vh - 190px)
  }
}

.searchBar{
  margin-top:70px;
  padding:15px
}

.searchBar__input{
  background-color:#999;
  width:100%;
  height:2.2rem;
  line-height:2.2rem;
  font-size:1rem;
  padding:0.2rem 0.5rem;
  border:1px solid #999;
  border-radius:0.25rem
}

.searchBar__input::-webkit-input-placeholder{
  color:#000
}

.searchBar__input:-moz-placeholder{
  color:#000
}

.searchBar__input::-moz-placeholder{
  color:#000
}

.searchBar__input:-ms-input-placeholder{
  color:#000
}

.bottomPanel{
  padding-bottom:7rem;
  padding-top:0.7rem;
  text-align:center;
  background:black
}

.go-top{
  margin:2rem 0
}

.footerLinks li,.footerLinks a{
  font-size:0.7rem;
  color:white
}

.footerLinks li:hover,.footerLinks a:hover,.footerLinks li:focus,.footerLinks a:focus{
  color:white
}

.footerLinks ul,.footerLinks li{
  list-style:none;
  margin:0;
  padding:0;
  display:inline-block
}

.footerLinks li{
  margin:0 .7rem 0 0;
  padding:0 .7rem 0 0;
  list-style:none;
  border-right:1px solid #c1bdbc
}

.footerLinks li:last-of-type{
  border-right:0
}

.contactForm{
  max-width:800px;
  margin:1.5rem auto
}

.socials{
  margin:30px 0 15px 0
}

.socials a{
  width:50px;
  height:50px;
  margin-left:20px;
  margin-right:20px;
  background-size:cover;
  background-repeat:no-repeat;
  transition:.3s;
  display:inline-block
}

.socials a:hover{
  background-position:0 0
}

.socials__facebook{
  background-image:url("/assets/social/facebook.png")
}

.socials__instagram{
  background-image:url("/assets/social/instagram.png")
}

.socials__youtube{
  background-image:url("/assets/social/youtube.png")
}

.socials__pinterest{
  background-image:url("/assets/social/pinterest.png")
}

.bottomMenu{
  color:white;
  position:fixed;
  z-index:100;
  bottom:0px;
  left:0;
  width:100%;
  background-color:white
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3){
  .bottomMenu{
    padding-bottom:20px
  }
}

.bottomMenu ul,.bottomMenu ol{
  list-style-type:none;
  padding-left:0
}

.bottomMenu ul{
  display:-webkit-flex;
  display:flex;
  margin:auto;
  max-width:400px
}

@media (min-width: 992px){
  .bottomMenu ul{
    max-width:650px
  }
}

.bottomMenu li{
  -webkit-flex:1 1 20%;
  flex:1 1 20%;
  text-align:center
}

.bottomMenu__li{
  transition:all 0.5s ease-out
}

.bottomMenu__li:hover,.bottomMenu__li:focus,.bottomMenu__li.selected{
  background-color:rgba(0,0,0,0.1)
}

.bottomMenu__link{
  display:inline-block;
  color:#413e3d;
  text-align:center;
  padding:4px 5px;
  cursor:pointer;
  transition:all 0.3s ease-out;
  opacity:0.7;
  font-family:"neo-sans",serif
}

.bottomMenu__link *{
  position:relative;
  z-index:100
}

.bottomMenu__link .icon{
  width:32px;
  height:32px;
  margin:auto
}

.bottomMenu__link div{
  font-size:12px;
  letter-spacing:0.5px;
  line-height:1;
  margin-top:0.3rem;
  font-weight:700
}

.bottomMenu__link:hover,.bottomMenu__link:focus,.bottomMenu__link.selected{
  text-decoration:none
}

.bottomMenu__link:focus{
  color:#413e3d
}

.bottomMenu__link:hover,.bottomMenu__link.selected{
  color:white;
  opacity:1
}

@media (min-width: 992px){
  .bottomMenu__link{
    padding:15px 5px
  }

  .bottomMenu__link .icon{
    width:40px;
    height:40px
  }

  .bottomMenu__link div{
    font-size:13px;
    letter-spacing:0.5px
  }
}

.icon img{
  width:100%;
  height:100%
}

.icon .normal{
  display:block
}

.icon .selected{
  display:none
}

.bottomMenu__link:hover .icon .normal,.bottomMenu__link.selected .icon .normal{
  display:none
}

.bottomMenu__link:hover .icon .selected,.bottomMenu__link.selected .icon .selected{
  display:block
}

.bottomMenu__bar{
  background-color:#0e76bc;
  position:relative;
  height:20px;
  cursor:ns-resize
}

.bottomMenu__line1,.bottomMenu__line2{
  background-color:rgba(255,255,255,0.9);
  height:3px;
  width:40px;
  position:absolute;
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  position:absolute;
  left:50%
}

.bottomMenu__line1{
  top:5px
}

.bottomMenu__line2{
  top:12px
}

.bottomMenuPadding{
  width:100%;
  height:10px;
  background-color:rgba(0,0,0,0.87);
  z-index:101;
  position:fixed;
  bottom:0
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3){
  .bottomMenuPadding{
    height:20px
  }
}

.cms_editing_item:focus{
  outline:none
}

.cms_editing_item{
  border:orange dashed 2px;
  min-height:15px;
  min-width:15px;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none
}

.cms_editing_text:focus{
  outline:none
}

.cms_editing_text{
  -webkit-box-shadow:inset 0 0 4px orange
}

.hideview{
  display:block
}

.noselect{
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none
}

[data-cms-html]{
  min-height:1.2em
}

img[data-embed-jaba]{
  display:block;
  clear:both;
  margin:5px;
  width:auto !important
}

.partial-editing-top-padding,.partial-editing-bottom-padding{
  padding:20px;
  color:white;
  text-align:center;
  background:#222
}

.partial-editing-top-padding{
  margin-top:100px
}

.partial-editing-bottom-padding{
  margin-bottom:100px
}

.slick-track{
  display:-webkit-flex !important;
  display:flex !important
}

.slick-slide{
  height:inherit !important
}

.slick-slide>div{
  height:100%
}

.pageTiles .slick-prev,.pageTiles .slick-next{
  top:25%
}

@media (min-width: 576px){
  .pageTiles .slick-prev,.pageTiles .slick-next{
    top:20%
  }
}

@media (min-width: 768px){
  .pageTiles .slick-prev,.pageTiles .slick-next{
    top:25%
  }
}

@media (min-width: 992px){
  .pageTiles .slick-prev,.pageTiles .slick-next{
    top:28%
  }
}

@media (min-width: 1600px){
  .pageTiles .slick-prev,.pageTiles .slick-next{
    top:32%
  }
}

.breadcrumb{
  text-align:left;
  padding:0;
  font-size:0.75rem
}

.breadcrumb,.breadcrumb li{
  list-style:none
}

.breadcrumb li{
  position:relative;
  margin-right:1.5rem;
  display:inline-block
}

.breadcrumb li:after{
  content:">";
  position:absolute;
  right:-1rem
}

.breadcrumb li:last-child:after{
  display:none
}

.bannerPanel>*{
  display:none
}

.bannerPanel>*:nth-child(1){
  display:block
}

.bannerPanel .slick-slider .slick-track{
  background-color:#414042
}

.slick-next{
  right:20px;
  z-index:1
}

.slick-prev{
  left:20px;
  z-index:1
}

.banner-item{
  max-width:100vw;
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  position:relative
}

.banner-item.bannerHasTitlePanel::before{
  content:'';
  background:rgba(0,0,0,0.3333);
  width:100%;
  height:100%;
  position:absolute;
  z-index:5
}

.banner_item__text{
  text-align:center;
  padding:0.5rem 0 0.7rem;
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%);
  width:80%;
  max-width:1200px;
  z-index:10;
  font-family:"proxima-nova",sans-serif;
  font-weight:600;
  color:#fff;
  font-size:1.6rem;
  line-height:1.1
}

@media (min-width: 992px){
  .banner_item__text{
    font-size:2.3rem;
    margin-bottom:-0.3rem
  }
}

.banner-item__link{
  font-size:1rem;
  font-weight:400;
  display:inline-block;
  padding:0.4rem 1rem;
  background-color:#0e76bc;
  color:#fff;
  margin-top:1rem
}

.banner-item__link:hover,.banner-item__link:focus{
  color:#fff;
  background-color:#8dc63f;
  text-decoration:none
}

.bannerItem__overlay{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:1
}

.bannerSize,.bannerSize--standard{
  height:350px
}

@media (min-width: 576px){
  .bannerSize,.bannerSize--standard{
    height:350px
  }
}

@media (min-width: 768px){
  .bannerSize,.bannerSize--standard{
    height:400px
  }
}

@media (min-width: 992px){
  .bannerSize,.bannerSize--standard{
    height:450px
  }
}

@media (min-width: 1200px){
  .bannerSize,.bannerSize--standard{
    height:500px
  }
}

@media (min-width: 1601px){
  .bannerSize,.bannerSize--standard{
    height:550px
  }
}

@media (min-width: 1901px){
  .bannerSize,.bannerSize--standard{
    height:600px
  }
}

@media (min-width: 2201px){
  .bannerSize,.bannerSize--standard{
    height:700px
  }
}

.bannerWrapperSize--small .bannerSize,.bannerSize--small{
  height:220px
}

@media (min-width: 576px){
  .bannerWrapperSize--small .bannerSize,.bannerSize--small{
    height:260px
  }
}

@media (min-width: 768px){
  .bannerWrapperSize--small .bannerSize,.bannerSize--small{
    height:330px
  }
}

@media (min-width: 992px){
  .bannerWrapperSize--small .bannerSize,.bannerSize--small{
    height:400px
  }
}

@media (min-width: 1200px){
  .bannerWrapperSize--small .bannerSize,.bannerSize--small{
    height:450px
  }
}

@media (min-width: 1601px){
  .bannerWrapperSize--small .bannerSize,.bannerSize--small{
    height:530px
  }
}

@media (min-width: 1901px){
  .bannerWrapperSize--small .bannerSize,.bannerSize--small{
    height:600px
  }
}

@media (min-width: 2201px){
  .bannerWrapperSize--small .bannerSize,.bannerSize--small{
    height:700px
  }
}

.bannerWrapperSize--thin .bannerSize,.bannerSize--thin{
  height:180px
}

@media (min-width: 576px){
  .bannerWrapperSize--thin .bannerSize,.bannerSize--thin{
    height:190px
  }
}

@media (min-width: 768px){
  .bannerWrapperSize--thin .bannerSize,.bannerSize--thin{
    height:200px
  }
}

@media (min-width: 992px){
  .bannerWrapperSize--thin .bannerSize,.bannerSize--thin{
    height:220px
  }
}

@media (min-width: 1200px){
  .bannerWrapperSize--thin .bannerSize,.bannerSize--thin{
    height:250px
  }
}

@media (min-width: 1601px){
  .bannerWrapperSize--thin .bannerSize,.bannerSize--thin{
    height:270px
  }
}

@media (min-width: 1901px){
  .bannerWrapperSize--thin .bannerSize,.bannerSize--thin{
    height:290px
  }
}

@media (min-width: 2201px){
  .bannerWrapperSize--thin .bannerSize,.bannerSize--thin{
    height:300px
  }
}

.pageTiles{
  margin-left:-10px;
  margin-right:-10px;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:flex-start;
  justify-content:flex-start;
  -webkit-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-top:2rem;
  margin-bottom:2rem
}

.pageTiles.center{
  -webkit-justify-content:center;
  justify-content:center
}

.pageTiles .tile{
  padding:10px;
  -webkit-flex:0 0 100%;
  flex:0 0 100%;
  max-width:100%
}

@media (min-width: 576px){
  .pageTiles .tile{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }
}

@media (min-width: 768px){
  .pageTiles .tile{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }
}

@media (min-width: 992px){
  .pageTiles .tile{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }
}

.pageTiles .tile__content{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  -webkit-justify-content:space-between;
  justify-content:space-between;
  width:100%;
  height:100%;
  cursor:pointer;
  position:relative;
  overflow:hidden
}

.pageTiles .tile__bg{
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover;
  height:0;
  padding-top:56.25%;
  transition:all .2s
}

.pageTiles.useSlider{
  display:block
}

.pageTiles.useSlider .tile{
  max-width:100%
}

.pageTile__content:hover,.pageTile__content:focus{
  text-decoration:none
}

.pageTile__content:hover .pageTile_details,.pageTile__content:focus .pageTile_details{
  background:#fd7e14
}

.pageTile__content:hover .pageTile__title,.pageTile__content:hover .pageTile_desc,.pageTile__content:focus .pageTile__title,.pageTile__content:focus .pageTile_desc{
  color:white
}

.pageTile__content:hover .tile__bg,.pageTile__content:focus .tile__bg{
  -webkit-transform:scale(1.02);
  transform:scale(1.02)
}

.pageTile__title{
  font-family:"neo-sans",serif;
  font-size:1.5rem;
  text-transform:uppercase;
  font-weight:bold;
  line-height:1;
  margin:0.5rem 0;
  color:black
}

.pageTile_desc{
  line-height:1.3;
  font-size:0.9rem;
  color:black
}

.pageTile_details{
  position:relative;
  overflow:hidden;
  -webkit-flex-grow:1;
  flex-grow:1;
  padding:0.5rem 0.8rem;
  text-align:left;
  background-color:red
}

.contentPanel--dark .pageTile_details:before{
  background-image:linear-gradient(to bottom left, rgba(255,255,255,0.3), rgba(255,255,255,0))
}

.contentPanel--dark .pageTile__title,.contentPanel--dark .pageTile_desc{
  color:white
}

.contSlider{
  max-width:100%;
  overflow:hidden;
  background:#999
}

.contSlider .sliderWrapper{
  height:100%
}

.contSlider .sliderWrapper>*{
  display:inline-block
}

.contSlider .sliderWrapper img{
  pointer-events:none
}

.contSlide__img{
  max-height:100%
}

.col-20p{
  -webkit-flex:0 0 20%;
  flex:0 0 20%;
  max-width:20%
}

@media (min-width: 576px){
  .col-sm-20p{
    -webkit-flex:0 0 20%;
    flex:0 0 20%;
    max-width:20%
  }
}

.multiColumnPanel{
  max-width:960px;
  margin:auto
}

.multiColumnPanel .container{
  margin-left:auto;
  margin-right:auto
}

@media (min-width: 1200px){
  .multiColumnPanel .container{
    margin-left:-15px;
    margin-right:-15px
  }
}

.devMediaQueryStatus{
  position:fixed;
  bottom:100px;
  left:0;
  z-index:10000
}

.devMediaQueryStatus__current{
  padding:0.3rem 1rem;
  font-size:0.75rem;
  background-color:black;
  color:white
}

.devMediaQueryStatus__current:before{
  content:'xs'
}

@media (min-width: 576px){
  .devMediaQueryStatus__current:before{
    content:'sm'
  }
}

@media (min-width: 768px){
  .devMediaQueryStatus__current:before{
    content:'md'
  }
}

@media (min-width: 992px){
  .devMediaQueryStatus__current:before{
    content:'lg'
  }
}

@media (min-width: 1200px){
  .devMediaQueryStatus__current:before{
    content:'xl'
  }
}

@media (min-width: 1600px){
  .devMediaQueryStatus__current:before{
    content:'xxl'
  }
}

.sectionLoading{
  min-height:300px;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:center;
  justify-content:center;
  -webkit-align-items:center;
  align-items:center;
  opacity:0;
  transition:all 0.3s;
  transition-delay:0.5s
}

.sectionLoading.loaded{
  opacity:1
}

iframe.youtube,.ytvideo iframe{
  width:100%
}

.ytvideo{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0
}

.ytvideo iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute
}

.ytEmdPopup__poster{
  position:relative;
  background-size:cover;
  background-position:center;
  width:100%;
  cursor:pointer
}

.ytEmdPopup__poster::before{
  content:'';
  width:100%;
  height:0;
  padding-bottom:56.25%;
  display:block
}

.ytEmdPopup__poster::after{
  content:'';
  width:100%;
  height:100%;
  display:block;
  position:absolute;
  z-index:1;
  background:rgba(0,0,0,0.1);
  top:0;
  left:0;
  transition:all 0.5s
}

.ytEmdPopup__poster:hover::after,.ytEmdPopup__poster:focus::after{
  background:rgba(0,0,0,0.4)
}

.ytEmdPopup__poster:hover .ytEmdPopup__playIcon,.ytEmdPopup__poster:focus .ytEmdPopup__playIcon{
  -webkit-transform:translate(-50%, -50%) scale(1.05);
  transform:translate(-50%, -50%) scale(1.05)
}

.ytEmdPopup__playIcon{
  color:white;
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%) scale(1);
  transform:translate(-50%, -50%) scale(1);
  font-size:4rem;
  z-index:2;
  transition:all 0.5s
}

.videoItem{
  text-align:left;
  margin:1.5rem 0
}

.videoItem h4{
  margin-top:1rem
}

.videoHolder{
  max-width:1000px;
  margin:auto
}

.youtubePlayer--full{
  height:350px
}

@media (min-width: 576px){
  .youtubePlayer--full{
    height:350px
  }
}

@media (min-width: 768px){
  .youtubePlayer--full{
    height:400px
  }
}

@media (min-width: 992px){
  .youtubePlayer--full{
    height:450px
  }
}

@media (min-width: 1200px){
  .youtubePlayer--full{
    height:100vh
  }
}

.youtubePlayer--standard{
  height:350px
}

@media (min-width: 576px){
  .youtubePlayer--standard{
    height:350px
  }
}

@media (min-width: 768px){
  .youtubePlayer--standard{
    height:450px
  }
}

@media (min-width: 992px){
  .youtubePlayer--standard{
    height:550px
  }
}

@media (min-width: 1200px){
  .youtubePlayer--standard{
    height:600px
  }
}

@media (min-width: 1601px){
  .youtubePlayer--standard{
    height:650px
  }
}

@media (min-width: 1901px){
  .youtubePlayer--standard{
    height:650px
  }
}

@media (min-width: 2201px){
  .youtubePlayer--standard{
    height:700px
  }
}

.youtubePlayer{
  position:relative;
  display:block;
  width:100%;
  padding:0;
  overflow:hidden;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  -webkit-justify-content:center;
  justify-content:center;
  -webkit-align-items:center;
  align-items:center
}

.youtubePlayer>*{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border:0
}

.youtubePlayer__iframe{
  z-index:1;
  max-width:none;
  transition:opacity 1.5s;
  opacity:0
}

.youtubePlayer__poster{
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover;
  z-index:2;
  transition:opacity 1.5s;
  opacity:1
}

.youtubePlayer__mask{
  z-index:3
}

.youtubePlayer__soundIcon{
  z-index:10;
  position:absolute;
  right:50px;
  bottom:12%;
  top:auto;
  left:auto;
  width:60px;
  height:60px;
  color:white;
  border:white solid 2px;
  padding:10px;
  border-radius:50%;
  transition:all 0.7 ease;
  cursor:pointer
}

.youtubePlayer__soundIcon svg{
  width:100%;
  height:100%
}

.youtubePlayer__soundIcon:hover{
  color:#0e76bc;
  border-color:#0e76bc
}

.youtubePlayer__playIcon{
  z-index:10;
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%);
  width:60px;
  height:60px;
  color:white;
  transition:all 0.7 ease;
  cursor:pointer
}

.youtubePlayer__playIcon svg{
  width:100%;
  height:100%
}

.youtubePlayer__playIcon:hover{
  color:#0e76bc;
  border-color:#0e76bc
}

.youtubePlayer--canPlay.youtubePlayer--loaded .youtubePlayer__iframe{
  opacity:1
}

.youtubePlayer--canPlay.youtubePlayer--loaded .youtubePlayer__poster{
  opacity:0
}

@media (min-width: 1200px){
  .youtubePlayer--full{
    height:calc(100vh - 2rem)
  }
}

.modal-video-close-btn{
  right:0
}

.file-links{
  text-align:left
}

.file-links ul{
  padding:0
}

.file-links li{
  list-style:none
}

.file-links li a{
  display:inline-block;
  margin:0.1rem;
  padding:0.2rem 0.5rem 0.2rem 1.4rem;
  position:relative
}

.file-links li a:before{
  content:url("/assets/icons/file-pdf-regular.svg");
  background-size:1rem 1rem;
  height:1rem;
  width:1rem;
  display:inline-block;
  position:absolute;
  left:0;
  top:0.2rem;
  color:#999
}

.file-links li a:after{
  content:'';
  position:absolute;
  height:2px;
  left:1.4rem;
  bottom:0;
  max-width:0;
  display:block;
  width:calc(100% - 1.4rem);
  transition:all 0.3s;
  background-color:#0e76bc
}

.file-links li a:hover,.file-links li a:focus{
  text-decoration:none
}

.file-links li a:hover:after,.file-links li a:focus:after{
  max-width:100%
}

.pageListItem{
  border-bottom:solid 1px #ddd;
  padding-top:0.25rem;
  padding-bottom:0.25rem
}

.pageListItem__inner{
  margin:0.5rem;
  text-align:left
}

.pageListItem__title{
  font-size:1.8rem;
  font-family:"neo-sans",serif;
  line-height:1
}

.pageListItem__highlight{
  font-size:0.8rem
}

.blogTiles{
  margin-left:-10px;
  margin-right:-10px;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:flex-start;
  justify-content:flex-start;
  -webkit-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-top:2rem;
  margin-bottom:2rem
}

.blogTiles__loadMore{
  margin-bottom:2rem
}

.blogTiles__loadMore .thinBtn{
  width:100%;
  display:block;
  color:white;
  background-color:#413e3d;
  cursor:pointer
}

.blogTiles__loadMore .thinBtn:hover,.blogTiles__loadMore .thinBtn:focus{
  color:#413e3d;
  background-color:white
}

@media (min-width: 1600px){
  .blogTiles--productView .blogTile{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }
}

.blogTile{
  padding:10px;
  -webkit-flex:0 0 100%;
  flex:0 0 100%;
  max-width:100%
}

@media (min-width: 576px){
  .blogTile{
    -webkit-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
  }
}

@media (min-width: 768px){
  .blogTile{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }
}

@media (min-width: 992px){
  .blogTile{
    -webkit-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
  }
}

@media (min-width: 1200px){
  .blogTile{
    -webkit-flex:0 0 33.33333%;
    flex:0 0 33.33333%;
    max-width:33.33333%
  }
}

@media (min-width: 1600px){
  .blogTile{
    -webkit-flex:0 0 25%;
    flex:0 0 25%;
    max-width:25%
  }
}

.blogTile__link{
  display:block;
  width:100%;
  height:100%;
  cursor:pointer;
  position:relative;
  background:#fff;
  color:#413e3d;
  box-shadow:1px 1px 5px 0px #e0e0e0;
  line-height:1.1;
  text-align:left;
  padding:8px
}

.blogTile__link:hover,.blogTile__link:focus{
  color:#413e3d;
  text-decoration:none
}

.blogTile__link:hover .thinBtn,.blogTile__link:focus .thinBtn{
  color:white;
  background-color:#413e3d
}

.blogTile__bg{
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover;
  height:0;
  padding-top:100%;
  width:100%;
  z-index:-1;
  margin-bottom:90px
}

.blogTile__content{
  width:100%;
  position:absolute;
  left:0;
  bottom:0
}

.blogTile__contentInner{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  background:rgba(255,255,255,0.8);
  padding:15px;
  margin:30px 30px 10px 30px
}

.blogTile__title{
  font-family:"neo-sans",serif;
  font-weight:400;
  font-size:1.5rem;
  min-height:3.1rem;
  text-transform:uppercase;
  line-height:1;
  margin:0.5rem 0;
  color:black
}

.blogTile__desc{
  font-size:0.8rem;
  font-style:italic;
  height:1.5rem;
  line-height:1.5rem;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  width:100%;
  display:none
}

.thinBtn{
  background-color:#fff;
  border:1px solid #413e3d;
  border-radius:0;
  font-size:0.8rem;
  letter-spacing:1px;
  transition:all .15s;
  margin:auto;
  width:10em;
  padding:0.5rem 1rem;
  text-align:center;
  margin-top:2rem
}

.blogViewPanel{
  text-align:left
}

.blogViewPanel .container{
  max-width:1200px;
  margin:auto
}

.blogView__titleContainer{
  max-width:960px;
  margin:auto;
  text-align:left
}

.blogView__title{
  margin-top:0.2rem;
  font-weight:bold
}

.blogView__date{
  font-size:0.75rem;
  color:#999;
  display:inline-block;
  background:#eee;
  padding:0.2rem 0.5rem;
  margin-bottom:0.5rem
}

.blogView__content{
  max-width:700px;
  margin:auto
}

.slick-slide.slick-current{
  z-index:2
}

.multiColumnPanel .col-12{
  margin-bottom:15px
}

.multiColumnPanel .col-12:last-of-type{
  margin-bottom:0
}

@media (min-width: 576px){
  .multiColumnPanel .col-12{
    margin-bottom:0
  }
}

body[data-aos-duration='50'] [data-aos],[data-aos][data-aos][data-aos-duration='50']{
  transition-duration:50ms
}

body[data-aos-delay='50'] [data-aos],[data-aos][data-aos][data-aos-delay='50']{
  transition-delay:0
}

body[data-aos-delay='50'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='50'].aos-animate{
  transition-delay:50ms
}

body[data-aos-duration='100'] [data-aos],[data-aos][data-aos][data-aos-duration='100']{
  transition-duration:100ms
}

body[data-aos-delay='100'] [data-aos],[data-aos][data-aos][data-aos-delay='100']{
  transition-delay:0
}

body[data-aos-delay='100'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='100'].aos-animate{
  transition-delay:100ms
}

body[data-aos-duration='150'] [data-aos],[data-aos][data-aos][data-aos-duration='150']{
  transition-duration:150ms
}

body[data-aos-delay='150'] [data-aos],[data-aos][data-aos][data-aos-delay='150']{
  transition-delay:0
}

body[data-aos-delay='150'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='150'].aos-animate{
  transition-delay:150ms
}

body[data-aos-duration='200'] [data-aos],[data-aos][data-aos][data-aos-duration='200']{
  transition-duration:200ms
}

body[data-aos-delay='200'] [data-aos],[data-aos][data-aos][data-aos-delay='200']{
  transition-delay:0
}

body[data-aos-delay='200'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='200'].aos-animate{
  transition-delay:200ms
}

body[data-aos-duration='250'] [data-aos],[data-aos][data-aos][data-aos-duration='250']{
  transition-duration:250ms
}

body[data-aos-delay='250'] [data-aos],[data-aos][data-aos][data-aos-delay='250']{
  transition-delay:0
}

body[data-aos-delay='250'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='250'].aos-animate{
  transition-delay:250ms
}

body[data-aos-duration='300'] [data-aos],[data-aos][data-aos][data-aos-duration='300']{
  transition-duration:300ms
}

body[data-aos-delay='300'] [data-aos],[data-aos][data-aos][data-aos-delay='300']{
  transition-delay:0
}

body[data-aos-delay='300'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='300'].aos-animate{
  transition-delay:300ms
}

body[data-aos-duration='350'] [data-aos],[data-aos][data-aos][data-aos-duration='350']{
  transition-duration:350ms
}

body[data-aos-delay='350'] [data-aos],[data-aos][data-aos][data-aos-delay='350']{
  transition-delay:0
}

body[data-aos-delay='350'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='350'].aos-animate{
  transition-delay:350ms
}

body[data-aos-duration='400'] [data-aos],[data-aos][data-aos][data-aos-duration='400']{
  transition-duration:400ms
}

body[data-aos-delay='400'] [data-aos],[data-aos][data-aos][data-aos-delay='400']{
  transition-delay:0
}

body[data-aos-delay='400'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='400'].aos-animate{
  transition-delay:400ms
}

body[data-aos-duration='450'] [data-aos],[data-aos][data-aos][data-aos-duration='450']{
  transition-duration:450ms
}

body[data-aos-delay='450'] [data-aos],[data-aos][data-aos][data-aos-delay='450']{
  transition-delay:0
}

body[data-aos-delay='450'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='450'].aos-animate{
  transition-delay:450ms
}

body[data-aos-duration='500'] [data-aos],[data-aos][data-aos][data-aos-duration='500']{
  transition-duration:500ms
}

body[data-aos-delay='500'] [data-aos],[data-aos][data-aos][data-aos-delay='500']{
  transition-delay:0
}

body[data-aos-delay='500'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='500'].aos-animate{
  transition-delay:500ms
}

body[data-aos-duration='550'] [data-aos],[data-aos][data-aos][data-aos-duration='550']{
  transition-duration:550ms
}

body[data-aos-delay='550'] [data-aos],[data-aos][data-aos][data-aos-delay='550']{
  transition-delay:0
}

body[data-aos-delay='550'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='550'].aos-animate{
  transition-delay:550ms
}

body[data-aos-duration='600'] [data-aos],[data-aos][data-aos][data-aos-duration='600']{
  transition-duration:600ms
}

body[data-aos-delay='600'] [data-aos],[data-aos][data-aos][data-aos-delay='600']{
  transition-delay:0
}

body[data-aos-delay='600'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='600'].aos-animate{
  transition-delay:600ms
}

body[data-aos-duration='650'] [data-aos],[data-aos][data-aos][data-aos-duration='650']{
  transition-duration:650ms
}

body[data-aos-delay='650'] [data-aos],[data-aos][data-aos][data-aos-delay='650']{
  transition-delay:0
}

body[data-aos-delay='650'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='650'].aos-animate{
  transition-delay:650ms
}

body[data-aos-duration='700'] [data-aos],[data-aos][data-aos][data-aos-duration='700']{
  transition-duration:700ms
}

body[data-aos-delay='700'] [data-aos],[data-aos][data-aos][data-aos-delay='700']{
  transition-delay:0
}

body[data-aos-delay='700'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='700'].aos-animate{
  transition-delay:700ms
}

body[data-aos-duration='750'] [data-aos],[data-aos][data-aos][data-aos-duration='750']{
  transition-duration:750ms
}

body[data-aos-delay='750'] [data-aos],[data-aos][data-aos][data-aos-delay='750']{
  transition-delay:0
}

body[data-aos-delay='750'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='750'].aos-animate{
  transition-delay:750ms
}

body[data-aos-duration='800'] [data-aos],[data-aos][data-aos][data-aos-duration='800']{
  transition-duration:800ms
}

body[data-aos-delay='800'] [data-aos],[data-aos][data-aos][data-aos-delay='800']{
  transition-delay:0
}

body[data-aos-delay='800'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='800'].aos-animate{
  transition-delay:800ms
}

body[data-aos-duration='850'] [data-aos],[data-aos][data-aos][data-aos-duration='850']{
  transition-duration:850ms
}

body[data-aos-delay='850'] [data-aos],[data-aos][data-aos][data-aos-delay='850']{
  transition-delay:0
}

body[data-aos-delay='850'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='850'].aos-animate{
  transition-delay:850ms
}

body[data-aos-duration='900'] [data-aos],[data-aos][data-aos][data-aos-duration='900']{
  transition-duration:900ms
}

body[data-aos-delay='900'] [data-aos],[data-aos][data-aos][data-aos-delay='900']{
  transition-delay:0
}

body[data-aos-delay='900'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='900'].aos-animate{
  transition-delay:900ms
}

body[data-aos-duration='950'] [data-aos],[data-aos][data-aos][data-aos-duration='950']{
  transition-duration:950ms
}

body[data-aos-delay='950'] [data-aos],[data-aos][data-aos][data-aos-delay='950']{
  transition-delay:0
}

body[data-aos-delay='950'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='950'].aos-animate{
  transition-delay:950ms
}

body[data-aos-duration='1000'] [data-aos],[data-aos][data-aos][data-aos-duration='1000']{
  transition-duration:1000ms
}

body[data-aos-delay='1000'] [data-aos],[data-aos][data-aos][data-aos-delay='1000']{
  transition-delay:0
}

body[data-aos-delay='1000'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1000'].aos-animate{
  transition-delay:1000ms
}

body[data-aos-duration='1050'] [data-aos],[data-aos][data-aos][data-aos-duration='1050']{
  transition-duration:1050ms
}

body[data-aos-delay='1050'] [data-aos],[data-aos][data-aos][data-aos-delay='1050']{
  transition-delay:0
}

body[data-aos-delay='1050'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1050'].aos-animate{
  transition-delay:1050ms
}

body[data-aos-duration='1100'] [data-aos],[data-aos][data-aos][data-aos-duration='1100']{
  transition-duration:1100ms
}

body[data-aos-delay='1100'] [data-aos],[data-aos][data-aos][data-aos-delay='1100']{
  transition-delay:0
}

body[data-aos-delay='1100'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1100'].aos-animate{
  transition-delay:1100ms
}

body[data-aos-duration='1150'] [data-aos],[data-aos][data-aos][data-aos-duration='1150']{
  transition-duration:1150ms
}

body[data-aos-delay='1150'] [data-aos],[data-aos][data-aos][data-aos-delay='1150']{
  transition-delay:0
}

body[data-aos-delay='1150'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1150'].aos-animate{
  transition-delay:1150ms
}

body[data-aos-duration='1200'] [data-aos],[data-aos][data-aos][data-aos-duration='1200']{
  transition-duration:1200ms
}

body[data-aos-delay='1200'] [data-aos],[data-aos][data-aos][data-aos-delay='1200']{
  transition-delay:0
}

body[data-aos-delay='1200'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1200'].aos-animate{
  transition-delay:1200ms
}

body[data-aos-duration='1250'] [data-aos],[data-aos][data-aos][data-aos-duration='1250']{
  transition-duration:1250ms
}

body[data-aos-delay='1250'] [data-aos],[data-aos][data-aos][data-aos-delay='1250']{
  transition-delay:0
}

body[data-aos-delay='1250'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1250'].aos-animate{
  transition-delay:1250ms
}

body[data-aos-duration='1300'] [data-aos],[data-aos][data-aos][data-aos-duration='1300']{
  transition-duration:1300ms
}

body[data-aos-delay='1300'] [data-aos],[data-aos][data-aos][data-aos-delay='1300']{
  transition-delay:0
}

body[data-aos-delay='1300'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1300'].aos-animate{
  transition-delay:1300ms
}

body[data-aos-duration='1350'] [data-aos],[data-aos][data-aos][data-aos-duration='1350']{
  transition-duration:1350ms
}

body[data-aos-delay='1350'] [data-aos],[data-aos][data-aos][data-aos-delay='1350']{
  transition-delay:0
}

body[data-aos-delay='1350'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1350'].aos-animate{
  transition-delay:1350ms
}

body[data-aos-duration='1400'] [data-aos],[data-aos][data-aos][data-aos-duration='1400']{
  transition-duration:1400ms
}

body[data-aos-delay='1400'] [data-aos],[data-aos][data-aos][data-aos-delay='1400']{
  transition-delay:0
}

body[data-aos-delay='1400'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1400'].aos-animate{
  transition-delay:1400ms
}

body[data-aos-duration='1450'] [data-aos],[data-aos][data-aos][data-aos-duration='1450']{
  transition-duration:1450ms
}

body[data-aos-delay='1450'] [data-aos],[data-aos][data-aos][data-aos-delay='1450']{
  transition-delay:0
}

body[data-aos-delay='1450'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1450'].aos-animate{
  transition-delay:1450ms
}

body[data-aos-duration='1500'] [data-aos],[data-aos][data-aos][data-aos-duration='1500']{
  transition-duration:1500ms
}

body[data-aos-delay='1500'] [data-aos],[data-aos][data-aos][data-aos-delay='1500']{
  transition-delay:0
}

body[data-aos-delay='1500'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1500'].aos-animate{
  transition-delay:1500ms
}

body[data-aos-duration='1550'] [data-aos],[data-aos][data-aos][data-aos-duration='1550']{
  transition-duration:1550ms
}

body[data-aos-delay='1550'] [data-aos],[data-aos][data-aos][data-aos-delay='1550']{
  transition-delay:0
}

body[data-aos-delay='1550'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1550'].aos-animate{
  transition-delay:1550ms
}

body[data-aos-duration='1600'] [data-aos],[data-aos][data-aos][data-aos-duration='1600']{
  transition-duration:1600ms
}

body[data-aos-delay='1600'] [data-aos],[data-aos][data-aos][data-aos-delay='1600']{
  transition-delay:0
}

body[data-aos-delay='1600'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1600'].aos-animate{
  transition-delay:1600ms
}

body[data-aos-duration='1650'] [data-aos],[data-aos][data-aos][data-aos-duration='1650']{
  transition-duration:1650ms
}

body[data-aos-delay='1650'] [data-aos],[data-aos][data-aos][data-aos-delay='1650']{
  transition-delay:0
}

body[data-aos-delay='1650'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1650'].aos-animate{
  transition-delay:1650ms
}

body[data-aos-duration='1700'] [data-aos],[data-aos][data-aos][data-aos-duration='1700']{
  transition-duration:1700ms
}

body[data-aos-delay='1700'] [data-aos],[data-aos][data-aos][data-aos-delay='1700']{
  transition-delay:0
}

body[data-aos-delay='1700'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1700'].aos-animate{
  transition-delay:1700ms
}

body[data-aos-duration='1750'] [data-aos],[data-aos][data-aos][data-aos-duration='1750']{
  transition-duration:1750ms
}

body[data-aos-delay='1750'] [data-aos],[data-aos][data-aos][data-aos-delay='1750']{
  transition-delay:0
}

body[data-aos-delay='1750'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1750'].aos-animate{
  transition-delay:1750ms
}

body[data-aos-duration='1800'] [data-aos],[data-aos][data-aos][data-aos-duration='1800']{
  transition-duration:1800ms
}

body[data-aos-delay='1800'] [data-aos],[data-aos][data-aos][data-aos-delay='1800']{
  transition-delay:0
}

body[data-aos-delay='1800'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1800'].aos-animate{
  transition-delay:1800ms
}

body[data-aos-duration='1850'] [data-aos],[data-aos][data-aos][data-aos-duration='1850']{
  transition-duration:1850ms
}

body[data-aos-delay='1850'] [data-aos],[data-aos][data-aos][data-aos-delay='1850']{
  transition-delay:0
}

body[data-aos-delay='1850'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1850'].aos-animate{
  transition-delay:1850ms
}

body[data-aos-duration='1900'] [data-aos],[data-aos][data-aos][data-aos-duration='1900']{
  transition-duration:1900ms
}

body[data-aos-delay='1900'] [data-aos],[data-aos][data-aos][data-aos-delay='1900']{
  transition-delay:0
}

body[data-aos-delay='1900'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1900'].aos-animate{
  transition-delay:1900ms
}

body[data-aos-duration='1950'] [data-aos],[data-aos][data-aos][data-aos-duration='1950']{
  transition-duration:1950ms
}

body[data-aos-delay='1950'] [data-aos],[data-aos][data-aos][data-aos-delay='1950']{
  transition-delay:0
}

body[data-aos-delay='1950'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='1950'].aos-animate{
  transition-delay:1950ms
}

body[data-aos-duration='2000'] [data-aos],[data-aos][data-aos][data-aos-duration='2000']{
  transition-duration:2000ms
}

body[data-aos-delay='2000'] [data-aos],[data-aos][data-aos][data-aos-delay='2000']{
  transition-delay:0
}

body[data-aos-delay='2000'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2000'].aos-animate{
  transition-delay:2000ms
}

body[data-aos-duration='2050'] [data-aos],[data-aos][data-aos][data-aos-duration='2050']{
  transition-duration:2050ms
}

body[data-aos-delay='2050'] [data-aos],[data-aos][data-aos][data-aos-delay='2050']{
  transition-delay:0
}

body[data-aos-delay='2050'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2050'].aos-animate{
  transition-delay:2050ms
}

body[data-aos-duration='2100'] [data-aos],[data-aos][data-aos][data-aos-duration='2100']{
  transition-duration:2100ms
}

body[data-aos-delay='2100'] [data-aos],[data-aos][data-aos][data-aos-delay='2100']{
  transition-delay:0
}

body[data-aos-delay='2100'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2100'].aos-animate{
  transition-delay:2100ms
}

body[data-aos-duration='2150'] [data-aos],[data-aos][data-aos][data-aos-duration='2150']{
  transition-duration:2150ms
}

body[data-aos-delay='2150'] [data-aos],[data-aos][data-aos][data-aos-delay='2150']{
  transition-delay:0
}

body[data-aos-delay='2150'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2150'].aos-animate{
  transition-delay:2150ms
}

body[data-aos-duration='2200'] [data-aos],[data-aos][data-aos][data-aos-duration='2200']{
  transition-duration:2200ms
}

body[data-aos-delay='2200'] [data-aos],[data-aos][data-aos][data-aos-delay='2200']{
  transition-delay:0
}

body[data-aos-delay='2200'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2200'].aos-animate{
  transition-delay:2200ms
}

body[data-aos-duration='2250'] [data-aos],[data-aos][data-aos][data-aos-duration='2250']{
  transition-duration:2250ms
}

body[data-aos-delay='2250'] [data-aos],[data-aos][data-aos][data-aos-delay='2250']{
  transition-delay:0
}

body[data-aos-delay='2250'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2250'].aos-animate{
  transition-delay:2250ms
}

body[data-aos-duration='2300'] [data-aos],[data-aos][data-aos][data-aos-duration='2300']{
  transition-duration:2300ms
}

body[data-aos-delay='2300'] [data-aos],[data-aos][data-aos][data-aos-delay='2300']{
  transition-delay:0
}

body[data-aos-delay='2300'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2300'].aos-animate{
  transition-delay:2300ms
}

body[data-aos-duration='2350'] [data-aos],[data-aos][data-aos][data-aos-duration='2350']{
  transition-duration:2350ms
}

body[data-aos-delay='2350'] [data-aos],[data-aos][data-aos][data-aos-delay='2350']{
  transition-delay:0
}

body[data-aos-delay='2350'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2350'].aos-animate{
  transition-delay:2350ms
}

body[data-aos-duration='2400'] [data-aos],[data-aos][data-aos][data-aos-duration='2400']{
  transition-duration:2400ms
}

body[data-aos-delay='2400'] [data-aos],[data-aos][data-aos][data-aos-delay='2400']{
  transition-delay:0
}

body[data-aos-delay='2400'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2400'].aos-animate{
  transition-delay:2400ms
}

body[data-aos-duration='2450'] [data-aos],[data-aos][data-aos][data-aos-duration='2450']{
  transition-duration:2450ms
}

body[data-aos-delay='2450'] [data-aos],[data-aos][data-aos][data-aos-delay='2450']{
  transition-delay:0
}

body[data-aos-delay='2450'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2450'].aos-animate{
  transition-delay:2450ms
}

body[data-aos-duration='2500'] [data-aos],[data-aos][data-aos][data-aos-duration='2500']{
  transition-duration:2500ms
}

body[data-aos-delay='2500'] [data-aos],[data-aos][data-aos][data-aos-delay='2500']{
  transition-delay:0
}

body[data-aos-delay='2500'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2500'].aos-animate{
  transition-delay:2500ms
}

body[data-aos-duration='2550'] [data-aos],[data-aos][data-aos][data-aos-duration='2550']{
  transition-duration:2550ms
}

body[data-aos-delay='2550'] [data-aos],[data-aos][data-aos][data-aos-delay='2550']{
  transition-delay:0
}

body[data-aos-delay='2550'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2550'].aos-animate{
  transition-delay:2550ms
}

body[data-aos-duration='2600'] [data-aos],[data-aos][data-aos][data-aos-duration='2600']{
  transition-duration:2600ms
}

body[data-aos-delay='2600'] [data-aos],[data-aos][data-aos][data-aos-delay='2600']{
  transition-delay:0
}

body[data-aos-delay='2600'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2600'].aos-animate{
  transition-delay:2600ms
}

body[data-aos-duration='2650'] [data-aos],[data-aos][data-aos][data-aos-duration='2650']{
  transition-duration:2650ms
}

body[data-aos-delay='2650'] [data-aos],[data-aos][data-aos][data-aos-delay='2650']{
  transition-delay:0
}

body[data-aos-delay='2650'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2650'].aos-animate{
  transition-delay:2650ms
}

body[data-aos-duration='2700'] [data-aos],[data-aos][data-aos][data-aos-duration='2700']{
  transition-duration:2700ms
}

body[data-aos-delay='2700'] [data-aos],[data-aos][data-aos][data-aos-delay='2700']{
  transition-delay:0
}

body[data-aos-delay='2700'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2700'].aos-animate{
  transition-delay:2700ms
}

body[data-aos-duration='2750'] [data-aos],[data-aos][data-aos][data-aos-duration='2750']{
  transition-duration:2750ms
}

body[data-aos-delay='2750'] [data-aos],[data-aos][data-aos][data-aos-delay='2750']{
  transition-delay:0
}

body[data-aos-delay='2750'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2750'].aos-animate{
  transition-delay:2750ms
}

body[data-aos-duration='2800'] [data-aos],[data-aos][data-aos][data-aos-duration='2800']{
  transition-duration:2800ms
}

body[data-aos-delay='2800'] [data-aos],[data-aos][data-aos][data-aos-delay='2800']{
  transition-delay:0
}

body[data-aos-delay='2800'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2800'].aos-animate{
  transition-delay:2800ms
}

body[data-aos-duration='2850'] [data-aos],[data-aos][data-aos][data-aos-duration='2850']{
  transition-duration:2850ms
}

body[data-aos-delay='2850'] [data-aos],[data-aos][data-aos][data-aos-delay='2850']{
  transition-delay:0
}

body[data-aos-delay='2850'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2850'].aos-animate{
  transition-delay:2850ms
}

body[data-aos-duration='2900'] [data-aos],[data-aos][data-aos][data-aos-duration='2900']{
  transition-duration:2900ms
}

body[data-aos-delay='2900'] [data-aos],[data-aos][data-aos][data-aos-delay='2900']{
  transition-delay:0
}

body[data-aos-delay='2900'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2900'].aos-animate{
  transition-delay:2900ms
}

body[data-aos-duration='2950'] [data-aos],[data-aos][data-aos][data-aos-duration='2950']{
  transition-duration:2950ms
}

body[data-aos-delay='2950'] [data-aos],[data-aos][data-aos][data-aos-delay='2950']{
  transition-delay:0
}

body[data-aos-delay='2950'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='2950'].aos-animate{
  transition-delay:2950ms
}

body[data-aos-duration='3000'] [data-aos],[data-aos][data-aos][data-aos-duration='3000']{
  transition-duration:3000ms
}

body[data-aos-delay='3000'] [data-aos],[data-aos][data-aos][data-aos-delay='3000']{
  transition-delay:0
}

body[data-aos-delay='3000'] [data-aos].aos-animate,[data-aos][data-aos][data-aos-delay='3000'].aos-animate{
  transition-delay:3000ms
}

body[data-aos-easing="linear"] [data-aos],[data-aos][data-aos][data-aos-easing="linear"]{
  transition-timing-function:cubic-bezier(0.25, 0.25, 0.75, 0.75)
}

body[data-aos-easing="ease"] [data-aos],[data-aos][data-aos][data-aos-easing="ease"]{
  transition-timing-function:cubic-bezier(0.25, 0.1, 0.25, 1)
}

body[data-aos-easing="ease-in"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in"]{
  transition-timing-function:cubic-bezier(0.42, 0, 1, 1)
}

body[data-aos-easing="ease-out"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-out"]{
  transition-timing-function:cubic-bezier(0, 0, 0.58, 1)
}

body[data-aos-easing="ease-in-out"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-out"]{
  transition-timing-function:cubic-bezier(0.42, 0, 0.58, 1)
}

body[data-aos-easing="ease-in-back"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-back"]{
  transition-timing-function:cubic-bezier(0.6, -0.28, 0.735, 0.045)
}

body[data-aos-easing="ease-out-back"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-out-back"]{
  transition-timing-function:cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

body[data-aos-easing="ease-in-out-back"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-out-back"]{
  transition-timing-function:cubic-bezier(0.68, -0.55, 0.265, 1.55)
}

body[data-aos-easing="ease-in-sine"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-sine"]{
  transition-timing-function:cubic-bezier(0.47, 0, 0.745, 0.715)
}

body[data-aos-easing="ease-out-sine"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-out-sine"]{
  transition-timing-function:cubic-bezier(0.39, 0.575, 0.565, 1)
}

body[data-aos-easing="ease-in-out-sine"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-out-sine"]{
  transition-timing-function:cubic-bezier(0.445, 0.05, 0.55, 0.95)
}

body[data-aos-easing="ease-in-quad"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-quad"]{
  transition-timing-function:cubic-bezier(0.55, 0.085, 0.68, 0.53)
}

body[data-aos-easing="ease-out-quad"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-out-quad"]{
  transition-timing-function:cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

body[data-aos-easing="ease-in-out-quad"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-out-quad"]{
  transition-timing-function:cubic-bezier(0.455, 0.03, 0.515, 0.955)
}

body[data-aos-easing="ease-in-cubic"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-cubic"]{
  transition-timing-function:cubic-bezier(0.55, 0.085, 0.68, 0.53)
}

body[data-aos-easing="ease-out-cubic"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-out-cubic"]{
  transition-timing-function:cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

body[data-aos-easing="ease-in-out-cubic"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"]{
  transition-timing-function:cubic-bezier(0.455, 0.03, 0.515, 0.955)
}

body[data-aos-easing="ease-in-quart"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-quart"]{
  transition-timing-function:cubic-bezier(0.55, 0.085, 0.68, 0.53)
}

body[data-aos-easing="ease-out-quart"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-out-quart"]{
  transition-timing-function:cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

body[data-aos-easing="ease-in-out-quart"] [data-aos],[data-aos][data-aos][data-aos-easing="ease-in-out-quart"]{
  transition-timing-function:cubic-bezier(0.455, 0.03, 0.515, 0.955)
}

[data-aos^='fade'][data-aos^='fade']{
  opacity:0;
  transition-property:opacity, -webkit-transform;
  transition-property:opacity, transform;
  transition-property:opacity, transform, -webkit-transform
}

[data-aos^='fade'][data-aos^='fade'].aos-animate{
  opacity:1;
  -webkit-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0)
}

[data-aos='fade-up']{
  -webkit-transform:translate3d(0, 100px, 0);
  transform:translate3d(0, 100px, 0)
}

[data-aos='fade-down']{
  -webkit-transform:translate3d(0, -100px, 0);
  transform:translate3d(0, -100px, 0)
}

[data-aos='fade-right']{
  -webkit-transform:translate3d(-100px, 0, 0);
  transform:translate3d(-100px, 0, 0)
}

[data-aos='fade-left']{
  -webkit-transform:translate3d(100px, 0, 0);
  transform:translate3d(100px, 0, 0)
}

[data-aos='fade-up-right']{
  -webkit-transform:translate3d(-100px, 100px, 0);
  transform:translate3d(-100px, 100px, 0)
}

[data-aos='fade-up-left']{
  -webkit-transform:translate3d(100px, 100px, 0);
  transform:translate3d(100px, 100px, 0)
}

[data-aos='fade-down-right']{
  -webkit-transform:translate3d(-100px, -100px, 0);
  transform:translate3d(-100px, -100px, 0)
}

[data-aos='fade-down-left']{
  -webkit-transform:translate3d(100px, -100px, 0);
  transform:translate3d(100px, -100px, 0)
}

[data-aos^='zoom'][data-aos^='zoom']{
  opacity:0;
  transition-property:opacity, -webkit-transform;
  transition-property:opacity, transform;
  transition-property:opacity, transform, -webkit-transform
}

[data-aos^='zoom'][data-aos^='zoom'].aos-animate{
  opacity:1;
  -webkit-transform:translate3d(0, 0, 0) scale(1);
  transform:translate3d(0, 0, 0) scale(1)
}

[data-aos='zoom-in']{
  -webkit-transform:scale(0.6);
  transform:scale(0.6)
}

[data-aos='zoom-in-up']{
  -webkit-transform:translate3d(0, 100px, 0) scale(0.6);
  transform:translate3d(0, 100px, 0) scale(0.6)
}

[data-aos='zoom-in-down']{
  -webkit-transform:translate3d(0, -100px, 0) scale(0.6);
  transform:translate3d(0, -100px, 0) scale(0.6)
}

[data-aos='zoom-in-right']{
  -webkit-transform:translate3d(-100px, 0, 0) scale(0.6);
  transform:translate3d(-100px, 0, 0) scale(0.6)
}

[data-aos='zoom-in-left']{
  -webkit-transform:translate3d(100px, 0, 0) scale(0.6);
  transform:translate3d(100px, 0, 0) scale(0.6)
}

[data-aos='zoom-out']{
  -webkit-transform:scale(1.2);
  transform:scale(1.2)
}

[data-aos='zoom-out-up']{
  -webkit-transform:translate3d(0, 100px, 0) scale(1.2);
  transform:translate3d(0, 100px, 0) scale(1.2)
}

[data-aos='zoom-out-down']{
  -webkit-transform:translate3d(0, -100px, 0) scale(1.2);
  transform:translate3d(0, -100px, 0) scale(1.2)
}

[data-aos='zoom-out-right']{
  -webkit-transform:translate3d(-100px, 0, 0) scale(1.2);
  transform:translate3d(-100px, 0, 0) scale(1.2)
}

[data-aos='zoom-out-left']{
  -webkit-transform:translate3d(100px, 0, 0) scale(1.2);
  transform:translate3d(100px, 0, 0) scale(1.2)
}

[data-aos^='slide'][data-aos^='slide']{
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform, -webkit-transform
}

[data-aos^='slide'][data-aos^='slide'].aos-animate{
  -webkit-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0)
}

[data-aos='slide-up']{
  -webkit-transform:translate3d(0, 100%, 0);
  transform:translate3d(0, 100%, 0)
}

[data-aos='slide-down']{
  -webkit-transform:translate3d(0, -100%, 0);
  transform:translate3d(0, -100%, 0)
}

[data-aos='slide-right']{
  -webkit-transform:translate3d(-100%, 0, 0);
  transform:translate3d(-100%, 0, 0)
}

[data-aos='slide-left']{
  -webkit-transform:translate3d(100%, 0, 0);
  transform:translate3d(100%, 0, 0)
}

[data-aos^='flip'][data-aos^='flip']{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform, -webkit-transform
}

[data-aos='flip-left']{
  -webkit-transform:perspective(2500px) rotateY(-100deg);
  transform:perspective(2500px) rotateY(-100deg)
}

[data-aos='flip-left'].aos-animate{
  -webkit-transform:perspective(2500px) rotateY(0);
  transform:perspective(2500px) rotateY(0)
}

[data-aos='flip-right']{
  -webkit-transform:perspective(2500px) rotateY(100deg);
  transform:perspective(2500px) rotateY(100deg)
}

[data-aos='flip-right'].aos-animate{
  -webkit-transform:perspective(2500px) rotateY(0);
  transform:perspective(2500px) rotateY(0)
}

[data-aos='flip-up']{
  -webkit-transform:perspective(2500px) rotateX(-100deg);
  transform:perspective(2500px) rotateX(-100deg)
}

[data-aos='flip-up'].aos-animate{
  -webkit-transform:perspective(2500px) rotateX(0);
  transform:perspective(2500px) rotateX(0)
}

[data-aos='flip-down']{
  -webkit-transform:perspective(2500px) rotateX(100deg);
  transform:perspective(2500px) rotateX(100deg)
}

[data-aos='flip-down'].aos-animate{
  -webkit-transform:perspective(2500px) rotateX(0);
  transform:perspective(2500px) rotateX(0)
}

@media print{
  header,.bannerPanel,#bottom-panel,.printShare{
    display:none !important
  }
}

section.banner-text-bar .htmlPanel p{
  margin-bottom:0px;
  text-align:center
}

svg{
  display:inline-block
}

div.grecaptcha-badge{
  z-index:101
}

div.recapthca-container>div>div>div{
  margin:auto
}

@media (min-width: 640px){
}

@media (min-width: 768px){
  .md\:border-solid{
    border-style: solid
  }

  .md\:flex{
    display: -webkit-flex;
    display: flex
  }

  .md\:mb-0{
    margin-bottom: 0px
  }

  .md\:ml-4{
    margin-left: 1rem
  }
}

@media (min-width: 1024px){
}

@media (min-width: 1280px){
}

@media (min-width: 1536px){
}

